import { reducerFromMap } from '../../utils/actions';
import { profileConstants } from './constants';
import { Action } from '../../models/Action';
import { Profile } from '../../models/Profile';

export interface ProfileState {
  loading: boolean;
  error?: string;
  profile: Profile;
}

export const defaultProfileState = {
  loading: false,
  error: '',
  profile: ({} as Profile),
};

function loadingStart(state: ProfileState = defaultProfileState, action: Action<void>): ProfileState {
  return {
    ...state,
    loading: true,
    error: '',
  };
}

function loadingSuccess(state: ProfileState, action: Action<Profile>): ProfileState {
  return {
    ...state,
    loading: false,
    profile: action.payload,
  };
}

function loadingError(state: ProfileState): ProfileState {
  return {
    ...state,
    loading: false,
    error: 'Something wrong',
  };
}

const reducer = reducerFromMap<ProfileState>(
  defaultProfileState,
  {
    [profileConstants.LOADING_PROFILE_START]: loadingStart,
    [profileConstants.LOADING_PROFILE_SUCCESS]: loadingSuccess,
    [profileConstants.LOADING_PROFILE_ERROR]: loadingError,
  }
);

export const profile = (state: ProfileState, action: Action<any>) => reducer(state, action);
