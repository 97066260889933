export const tableConstants = {
  TABLE_LOADING__START: 'TABLE_LOADING__START',
  TABLE_LOADING__SUCCESS: 'TABLE_LOADING__SUCCESS',
  TABLE_LOADING__ERROR: 'TABLE_LOADING__ERROR',
  TABLE_UPDATE_CAMPAIGN_NAME: 'TABLE_UPDATE_CAMPAIGN_NAME',
  TABLE_ADD_SELECTED_CAMPAIGN: 'TABLE_ADD_SELECTED_CAMPAIGN',
  TABLE_REMOVE_SELECTED_CAMPAIGN: 'TABLE_REMOVE_SELECTED_CAMPAIGN',
  TABLE_CLEAR_SELECTED_CAMPAIGN: 'TABLE_CLEAR_SELECTED_CAMPAIGN',
  TABLE_UPDATE_SORTING_PARAMS: 'TABLE_UPDATE_SORTING_PARAMS',
  TABLE_SELECT_ALL_CAMPAIGNS: 'TABLE_SELECT_ALL_CAMPAIGNS',
  TABLE_CLEAR_CAMPAIGNS_DATA: 'TABLE_CLEAR_CAMPAIGNS_DATA',
  TABLE_SET_FILTERED_CAMPAIGNS: 'TABLE_SET_FILTERED_CAMPAIGNS',
  TABLE_SET_FILTERED_CAMPAIGNS_IDS: 'TABLE_SET_FILTERED_CAMPAIGNS_IDS',
  TABLE_SET_FILTERED_GROUPS_IDS: 'TABLE_SET_FILTERED_GROUPS_IDS',
  TABLE_SET_CAMPAIGNS: 'TABLE_SET_CAMPAIGNS',
  UPDATE_CAMPAIGNS_LIST: 'UPDATE_CAMPAIGNS_LIST',
};
