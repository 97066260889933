import React from 'react';
import { connect } from 'react-redux';
import { EditableText, Icon, Tooltip } from 'factor';
import cloneDeep from 'lodash/cloneDeep';
import camelCase from 'lodash/camelCase';
import kebabCase from 'lodash/kebabCase';
import get from 'lodash/get';
import moment from 'moment';
import { TableComponent as KTable, TableHeaderMapping, TimezonePicker } from 'iqm-framework';
import { DialogAmountContentWrapper } from './table/DialogAmountContentWrapper';
import { DialogDateContentWrapper } from './table/DialogDateContentWrapper';
import {
  SetCampaigns,
  tableActions,
  UpdateCampaignName,
  UpdateCampaignsBudget,
  UpdateCampaignsList
} from '../../../../store/table/actions';
import { API } from '../../../../api';
import { AppState } from '../../../../store';
import { TableLevel, TableLevels } from '../../../../store/filter/reducers';
import { filterActions, UpdateFreeze } from '../../../../store/filter/actions';
import { toastActions } from '../../../../store/toast/actions';
import { campaignGroupsActions } from '../../../../store/campaignGroups/actions';
import { AdvertiserIdParams } from '../../../../models/Common';
import { CampaignGroup } from '../../../../models/CampaignGroup';
import { Option } from '../../../../models/Option';

import { CurrencyFormat, DateFormat, DateTime12HFormat } from '../../../../utils/format';
import { pluralize } from '../../../../utils/pluralize';
import { statisticsActions, SetNewTotal, GetTotal } from '../../../../store/statistics/actions';
import { LambdaResponse, emptyLambdaResponse } from '../../../../models/Response';
import { ToastContent } from '../../../../components/toastContent/ToastContent';
import './styles.scss';

const PARTLY_EDITING_STATUSES = ['expired', 'deleted'];

enum CreativeTypeIconMapper {
  video = 'VideoXS',
  image = 'BannerXS',
  gotv = 'gotvXS',
  html = 'HtmlXS',
  audio = 'VoiceXS',
  tickerBanner = 'scrollXS',
}

interface Props extends SetCampaigns, UpdateCampaignsBudget, UpdateFreeze, SetNewTotal, UpdateCampaignName, UpdateCampaignsList, GetTotal {
  addSelectedCampaign: (data: LambdaResponse[]) => void;
  dataPath: string,
  data: LambdaResponse[],
  headerMapper: {
    [key: string]: any,
  },
  selectGroup: (params: AdvertiserIdParams) => void;
  checkGroup: (data: CampaignGroup) => void;
  changeLevel: (value: Option) => void;
  clearCampaignsData: () => void;
  getStatuses: () => void;
  isGroups: boolean;
  isCampaignsAndNotFromGroup: boolean;
  selectedTableCampaigns: ({ campaignId: string } & any)[];
  filteredCampaigns: LambdaResponse[];
  filteredCampaignsIds: number[];
  skeleton: {
    rows: number;
    columns: number;
  };
  freezeRows: number;
  freezeColumns: number;
  openToast: (message: string | JSX.Element) => void;
  closeToast: () => void;
  status: Option;
  dspId: number | null;
  advertiserId: number | null;
}

type Filter = null | ((data: LambdaResponse[]) => LambdaResponse[]);

export let TableComponentInstance;

class TableComponent extends React.Component<Props> {
  handleTableCampaignSelection = (data) => {
    this.props.addSelectedCampaign(data);
  };

  rowClickHandler = (event, data) => {
    const { tagName } = event.target;
    const typeAttr = event.target.getAttribute('type');
    const { campaignGroupId } = data;

    if (tagName === 'INPUT' && typeAttr === 'checkbox') {
      return;
    }

    this.props.selectGroup({ advertiserId: campaignGroupId });
    this.props.changeLevel(TableLevels[0]);
  };

  handleSetRef = (ref) => {
    TableComponentInstance = ref;
  };

  handleCampaignChange = (data: LambdaResponse[], total: LambdaResponse[]) => {
    const { isGroups, setCampaigns, setNewTotal, updateCampaignsList, filteredCampaignsIds } = this.props;
    if (!isGroups) {
      setCampaigns(data);
      if (!filteredCampaignsIds.length) {
        updateCampaignsList(data);
      }
    }
    setNewTotal(total[0]);
  };

  handleFilterCampaigns = (data: LambdaResponse[]) => {
    const { filteredCampaigns } = this.props;
    const ids = filteredCampaigns.map(c => c.campaignId);
    return data.filter(v => ids.includes(v.campaignId));
  };

  confirmNewValue = async (budgetType, value, id) => {
    const { updateCampaignsBudget, openToast, data, setCampaigns } = this.props;
    let toastMessage = `${this.toastName} has been changed`;

    try {
      const response = await updateCampaignsBudget({ budgetType, value, id });
      if (get(response, 'responseObject.modified_data', null)) {
        const modifiedData = get(response, 'responseObject.modified_data', [])
          .reduce((acc, campaign) => {
            acc[campaign.campaignId] = {};
            for (let key in campaign) {
              acc[campaign.campaignId.toString()][key] = campaign[key].toString();
            }
            return acc;
          }, {});
        const modifiedDataIds: string[] = Object.keys(modifiedData);
        data.forEach(campaignData => {
          if (modifiedDataIds.includes(campaignData.campaignId)) {
            campaignData = Object.assign(campaignData, modifiedData[campaignData.campaignId]);
          }
          return campaignData;
        });

        setCampaigns(data);
      }

      if (!get(response, 'responseObject.status', null)) {
        const errorsMapper = response.responseObject.reason.reduce((acc, i) => {
          const findedIndex = acc.findIndex(a => a.errorMessage === i.errorMessage);
          findedIndex > -1 ? acc[findedIndex].id.push(i.id) : acc.push({
            errorMessage: i.errorMessage,
            id: [i.id],
          })
          return acc;
        }, []);

        const toastMessages = errorsMapper.map(e => {
          const { errorMessage, id } = e;
          return `${pluralize('Campaing', id.length, {
            single: `Campaign with id ${id.join(', ')} is`,
            multiple: `Campaigns with id ${id.join(', ')} are`,
          })} not updated! ${errorMessage ? 'Reason: ' + errorMessage : ''}`;
        });

        openToast(<ToastContent messages={toastMessages} />);
      } else {
        openToast(toastMessage);
        this.props.getTotal()
          .then(res => {
            TableComponentInstance && TableComponentInstance.setState({ totalData: [{ ...res, total: true }] });
          });
      }
    } catch (err) {
      let errorMessage = 'Error updating budget';
      const error = get(err, 'responseObject.errorMsg', null);
      if (error) {
        errorMessage = error;
      }
      openToast(errorMessage);
    }
  };

  setNewEndDate = async (value, campaignData) => {
    const { dspId, advertiserId, openToast, data, setCampaigns } = this.props;
    let toastMessage = `${this.toastName} has been changed`;
    const endTime = campaignData.endTime 
      ? this.getEndDateUnixInCampaignTimezone(campaignData) : 
      null;

    if (!dspId || !advertiserId) {
      return;
    }

    const newDateTime = this.getNewEndDateInOldTime(value, endTime).unix();

    try {
      const response = await API.campaigns.updateEndTime({
        advertiserId,
        dspId,
        campaignIds: String(campaignData.campaignId),
        endDate: newDateTime,
      });
      if (get(response, 'responseObject.modified_data', null)) {
        const modifiedData = get(response, 'responseObject.modified_data', [])
          .reduce((acc, campaign) => {
            acc[campaign.campaignId] = {};
            for (let key in campaign) {
              acc[campaign.campaignId.toString()][key] = campaign[key].toString();
            }
            return acc;
          }, {});
        const modifiedDataIds: string[] = Object.keys(modifiedData);
        data.forEach(campaignData => {
          if (modifiedDataIds.includes(campaignData.campaignId)) {
            campaignData = Object.assign(campaignData, modifiedData[campaignData.campaignId]);
          }
          return campaignData;
        });

        setCampaigns(data);
      }

      if (!get(response, 'responseObject.status', null)) {
        const errorsMapper = response.responseObject.reason.reduce((acc, i) => {
          const findedIndex = acc.findIndex(a => a.errorMessage === i.errorMessage);
          findedIndex > -1 ? acc[findedIndex].id.push(i.id) : acc.push({
            errorMessage: i.errorMessage,
            id: [i.id],
          })
          return acc;
        }, []);

        const toastMessages = errorsMapper.map(e => {
          const { errorMessage, id } = e;
          return `${pluralize('Campaing', id.length, {
            single: `Campaign with id ${id.join(', ')} is`,
            multiple: `Campaigns with id ${id.join(', ')} are`,
          })} not updated! ${errorMessage ? `Reason: ${errorMessage}` : ''}`;
        });

        openToast(<ToastContent messages={toastMessages} />);
      } else {
        openToast(toastMessage);
      }
    } catch (err) {
      let errorMessage = 'Error updating end date';
      const error = get(err, 'responseObject.errorMsg', null);
      if (error) {
        errorMessage = error;
      }
      openToast(errorMessage);
    }
  };

  cellRenderWithEditTooltip = (campaignStatus: string, dataToRender: string) => {
    if (PARTLY_EDITING_STATUSES.includes(campaignStatus)) {
      return dataToRender;
    }
    return (
      <Tooltip className="flex-grow-1" label="Edit">
        <span className="td-content__text">{dataToRender}</span>
      </Tooltip>
    )
  };

  getEndDateUnixInCampaignTimezone = (campaign) => {
    return campaign.campaign_timezone
      ? moment.unix(campaign.endTime).tz(campaign.campaignTimezone)
      : moment.unix(campaign.endTime);
  };

  getNewEndDateInOldTime = (newStr, oldDate) => {
    const newMoment = moment(newStr);
    if (oldDate) {
      return oldDate
        .year(newMoment.year())
        .month(newMoment.month())
        .date(newMoment.date());
    } else {
      return newMoment
        .hour(23)
        .minute(59)
        .second(59);
    }
  };

  toastName = '';

  stopPropagation = (e) => {
    e.stopPropagation();
  }

  editableTextModalProps = {
    onContextMenu: this.stopPropagation,
    onClick: this.stopPropagation,
  }

  campaignsBodyMapper = {
    campaignId: {
      key: (data) => data.campaignId,
      hover: null,
      className: '_id w-40-60 _right',
    },
    campaignName: {
      key: (data) => {
        let classesArray = ['svg-icon__wrapper'];
        classesArray.push(kebabCase(data.status));

        if (this.props.status.value !== 'all') {
          if (+data.percentageOfTotalSpent > 80) {
            (+data.percentageOfTotalSpent > 95) ? classesArray.push('budget-is-expiring-red') : classesArray.push('budget-is-expiring');
          }
        }
        return (
          <React.Fragment>
            {data.creativeType ? (
              <div className={classesArray.join(' ')}>
                <Tooltip label={`${data.creativeType} Campaign (${data.status})`} position="right" auto={false}>
                  <Icon name={CreativeTypeIconMapper[camelCase(data.creativeType)]} />
                </Tooltip>
              </div>
            ) : null}
            <Tooltip className="td-campaign-name" label="Edit">
              <span className="td-campaign-name__text">{data.campaignName}</span>
            </Tooltip>
          </React.Fragment>
        );
      },
      click: (data) => {
        if (data.total) {
          return null;
        }
        return (
          <EditableText
            className="w-250-650"
            value={data.campaignName}
            Popup={{
              title: 'Change Campaign name',
              modalProps: this.editableTextModalProps,
            }}
            confirm={async (value) => {
              this.props.updateCampaignName(value, data.campaignId)
                .then(() => {
                  TableComponentInstance && TableComponentInstance.setState({ data: this.props.data });
                });
            }}
          >
            <div>Change campaign name?</div>
          </EditableText>
        );
      },
      isFocusOnClick: true,
      clickFocusRefPropName: 'inputRef',
      className: '_campaign-name _editable w-250-650',
    },
    budgetDay: {
      key: (data) => {
        const dataToRender = data.budgetDay ? CurrencyFormat.format(data.budgetDay) : '—';
        return this.cellRenderWithEditTooltip(data.status, dataToRender);
      },
      click: (data) => {
        return (data.total || PARTLY_EDITING_STATUSES.includes(data.status))
          ? (data.budgetDay ? CurrencyFormat.format(data.budgetDay) : '—')
          : <EditableText
            className='w-150-200 _right'
            value={data.budgetDay}
            type='amount'
            confirm={(newValue) => {
              this.toastName = 'Daily Budget';
              this.confirmNewValue('dailyBudget', +newValue, data.campaignId);
            }}
            Popup={{
              title: `Change Daily Budget?`,
              modalProps: this.editableTextModalProps,
            }}
          >
            {(old, newValue) => {
              return (
                <DialogAmountContentWrapper value={+newValue} prev={+old} onChange={() => { }} />
              )
            }}
          </EditableText>
      },
      isFocusOnClick: true,
      clickFocusRefPropName: 'inputRef',
      className: 'w-100-200 _editable _right',
    },
    budgetTotal: {
      key: (data) => {
        const dataToRender = data.budgetTotal ? CurrencyFormat.format(data.budgetTotal) : '—';
        return this.cellRenderWithEditTooltip(data.status, dataToRender);
      },
      click: (data) => {
        return (data.total || PARTLY_EDITING_STATUSES.includes(data.status))
          ? (data.budgetTotal ? CurrencyFormat.format(data.budgetTotal) : '—')
          : <EditableText
            className='w-150-200 _right'
            value={data.budgetTotal}
            type='amount'
            confirm={(newValue) => {
              this.toastName = 'Budget';
              this.confirmNewValue('totalBudget', +newValue, data.campaignId);
            }}
            Popup={{
              title: `Change Budget?`,
              modalProps: this.editableTextModalProps,
            }}
          >
            {(old, newValue) => {
              return (
                <DialogAmountContentWrapper value={+newValue} prev={+old} onChange={() => { }} />
              )
            }}
          </EditableText>
      },
      isFocusOnClick: true,
      clickFocusRefPropName: 'inputRef',
      className: 'w-100-200 _editable _right',
    },
    maxBid: {
      key: (data) => {
        const dataToRender = data.maxBid ? CurrencyFormat.format(data.maxBid) : '—';
        return this.cellRenderWithEditTooltip(data.status, dataToRender);
      },
      click: (data) => {
        if (!data.maxBid) {
          return '—';
        }
        return (data.total || PARTLY_EDITING_STATUSES.includes(data.status))
          ? CurrencyFormat.format(data.maxBid)
          : <EditableText
            className='_right'
            value={data.maxBid}
            type='amount'
            confirm={(newValue) => {
              this.toastName = 'Max bid';
              this.confirmNewValue('maxBid', +newValue, data.campaignId);
            }}
            Popup={{
              title: `Change Max bid?`,
              modalProps: this.editableTextModalProps,
            }}
          >
            {(old, newValue) => {
              return (
                <DialogAmountContentWrapper value={+newValue} prev={+old} onChange={() => { }} />
              )
            }}
          </EditableText>
      },
      isFocusOnClick: true,
      clickFocusRefPropName: 'inputRef',
      className: 'w-80-80 _editable _right',
    },
    endDate: {
      key: (data) => {
        const dataToRender = data.end_time
          ? this.getEndDateUnixInCampaignTimezone(data).format(DateFormat)
          : '—';

        return this.cellRenderWithEditTooltip(data.status, dataToRender);
      },
      click: (data) => {
        if (!data.maxBid) {
          return '—';
        }

        const endDate = data.endTime 
          ? this.getEndDateUnixInCampaignTimezone(data)
          : null;

        const timezoneObj = {value: data.campaignTimezone, label: data.campaignTimezone.replace(/_/g, ' ')}

        return (data.total || PARTLY_EDITING_STATUSES.includes(data.status))
          ? CurrencyFormat.format(data.maxBid)
          : (
            <EditableText
              className='_right'
              value={endDate ? endDate.format(DateFormat) : null}
              type='date'
              timezone={data.campaignTimezone}
              confirm={(newValue) => {
                this.toastName = 'End Date';
                this.setNewEndDate(newValue, data);
              }}
              isValid={(value) => {
                const endDate = data.endTime 
                  ? this.getEndDateUnixInCampaignTimezone(data)
                  : null;
                const newValueInUnix = this.getNewEndDateInOldTime(value, endDate).unix();

                if (moment().add(15, 'm').unix() > newValueInUnix) {
                  this.props.openToast('Error updating End Date: New End Date has to be in future');
                  return false;
                }
                this.props.closeToast();
                return true;
              }}
              Popup={{
                title: 'Change End Date?',
                actionsHelp: (
                  <div className="d-flex duplicate-dialog__timezone">
                    <TimezonePicker selectedTimezone={timezoneObj} onTimezoneChange={null}/>
                  </div>
                )
              }}
            >
              {(old, newValue) => {
                const prevDateStr = endDate ? endDate.format(DateTime12HFormat) : '';
                const newDateStr = this.getNewEndDateInOldTime(newValue, endDate).format(DateTime12HFormat);
                return (
                  <DialogDateContentWrapper
                    value={newDateStr}
                    prev={prevDateStr}
                    campaignName={data.campaignName}
                    campaignTimezone={data.campaignTimezone} 
                    onChange={() => { }} 
                  />
                )
              }}
            </EditableText>
          )
      },
      className: '_end-date w-100-100 _editable',
      isFocusOnClick: true,
      clickFocusRefPropName: 'inputRef',
    },
    percentageOfTotalSpent: {
      key: data => data.total ? '—' : `${((+data.percentageOfTotalSpent) || 0).toFixed(2)}%`,
      className: data => {
        return `_right w-100-200 ${+data.percentageOfTotalSpent > 80
          ? +data.percentageOfTotalSpent > 95
            ? '_red'
            : '_orange'
          : ''}`;
      },
    },
  };

  groupsBodyMapper = {
    campaignGroupId: {
      key: (data) => data.campaignGroupId,
      hover: null,
      className: '_id w-40-60 _right',
    },
    campaignGroupName: {
      key: (data) => data.campaignGroupName,
      hover: null,
      className: '_campaign-name w-250-650',
    },
  };

  fetchDataErrorHandler = (err) => {
    this.props.setNewTotal(emptyLambdaResponse);
    if(err && Object.keys(err).length)  {
      this.props.openToast('Something went wrong. Please try again over a few minutes.');
    }
  };

  render() {
    const {
      isGroups,
      isCampaignsAndNotFromGroup,
      filteredCampaignsIds,
      skeleton,
      updateFreeze,
      freezeRows,
      freezeColumns,
      selectedTableCampaigns,
    } = this.props;

    const bodyMapper = cloneDeep(isGroups ? this.groupsBodyMapper : this.campaignsBodyMapper);

    let onFilter: Filter = null;

    if (isCampaignsAndNotFromGroup && filteredCampaignsIds.length) {
      onFilter = this.handleFilterCampaigns;
    }

    return (
      <React.Fragment>
        <KTable
          className={`dashboard__table ${isGroups ? '' : '_with-checkbox'}`}
          onSelect={this.handleTableCampaignSelection}
          dataPath={this.props.dataPath}
          headerMapping={this.props.headerMapper}
          bodyMapping={{ ...bodyMapper }}
          offsetTop={selectedTableCampaigns.length ? 127 : 70}
          tableParams={{
            freezeRows,
            freezeColumns: (freezeColumns === 1 && !isGroups) ? 2 : freezeColumns,
            freezeUpdateHandler: updateFreeze,
            rowKeyExtractor: (data, index) => +`${(data.campaignId || data.campaignGroupId)}${index}`,
            windowFreeResizeEvent: true,
            rowClickHandler: isGroups ? this.rowClickHandler : undefined,
          }}
          innerRef={this.handleSetRef}
          onDataChanged={this.handleCampaignChange}
          filter={onFilter}
          skeleton={skeleton}
          checkbox={!isGroups}
          transformRequestParams={query => ({
            ...query,
            page_number: query.pgno,
            total_count: query.no_of_entries
          })}
          idField={isGroups ? "campaignGroupId" : "campaignId"}
          emptyTableLabel="No Campaigns. Change Dashboard Parameters."
          onFetchDataError={this.fetchDataErrorHandler}
          countPath="totalCount"
          defaultSorting={{
            direction: 'desc',
            field: 'impressions',
          }}
        />
      </React.Fragment>
    )
  }
}

const campaignsHeaderMapper = {
  campaignId: {
    ...TableHeaderMapping.campaignId,
    className: '_id w-40-60',
    label: 'ID',
  },
  campaignName: {
    ...TableHeaderMapping.campaignName,
    className: '_campaign-name w-250-650',
  },
  percentageOfTotalSpent: TableHeaderMapping.percentageOfTotalSpent,
};

const groupHeaderMapper = {
  campaignGroupId: {
    label: 'ID',
    sortingKey: 'campaignGroupId',
    className: '_id w-40-60 _right',
    draggableGroup: 'group1',
  },
};

const skeletonClasses = {
  campaignId: 'w-40-60',
  campaignGroupId: 'w-40-60',
  checkbox: 'w-50',
  campaignName: 'w-250-650',
  campaignGroup: 'w-250-650',
  maxBid: 'w-80-80',
  dailyBudget: 'w-100-200',
  totalBudget: 'w-100-200',
  totalSpent: 'w-150-200',
  startDate: ' w-80-80',
  endTime: 'w-80-80',
  impressions: 'w-90-90',
  uniqueReach: 'w-100-200',
  created: 'w-100-200',
  modified: 'w-100-200',
  type: 'w-60-60',
  ecpm: 'w-100-200',
  ecpc: 'w-100-200',
  clicks: 'w-60-60',
  winrate: 'w-90-90',
  ctr: 'w-60-60',
  dataCost: 'w-90-90',
  mediaSpent: 'w-100-200',
  percentageOfTotalSpent: 'w-100-200',
};

const mapClasses = ({ value }) => {
  return skeletonClasses[value] || '';
};

const mapState = (state: AppState) => {
  const isGroups = state.filter.tableLevel.value === TableLevel.Groups;
  const isCampaigns = state.filter.tableLevel.value === TableLevel.Campaigns;
  const isCampaignsAndNotFromGroup = isCampaigns && state.campaignGroups.selectedId === null;
  const filteredCampaignsIds = state.table.filteredCampaignsIds;
  const filteredCampaigns = state.table.data.filter(campaign => campaign.campaignId && filteredCampaignsIds.includes(+campaign.campaignId));
  const { selectedTableCampaigns, data } = state.table;
  const { sortingColumns, sortingGroupsColumns, freeze, status } = state.filter;

  return {
    dataPath: 'data',
    data,
    headerMapper: isGroups ? groupHeaderMapper : campaignsHeaderMapper,
    isGroups,
    isCampaignsAndNotFromGroup,
    filteredCampaigns,
    filteredCampaignsIds,
    skeleton: {
      rows: 10,
      columns: isGroups ? sortingGroupsColumns.length : sortingColumns.length,
      classes: isGroups ? sortingGroupsColumns.map(mapClasses) : sortingColumns.map(mapClasses),
    },
    freezeRows: freeze.rows,
    freezeColumns: freeze.columns,
    status,
    selectedTableCampaigns,
    dspId: state.auth.dspId,
    advertiserId: state.auth.userId,
  };
};

const mapActions = {
  addSelectedCampaign: tableActions.addSelectedCampaign,
  selectGroup: campaignGroupsActions.selectGroup,
  checkGroup: campaignGroupsActions.checkGroup,
  clearCampaignsData: tableActions.clearCampaignsData,
  changeLevel: filterActions.changeTableLevel,
  getStatuses: filterActions.getStatuses,
  updateFreeze: filterActions.updateFreeze,
  setCampaigns: tableActions.setCampaigns,
  openToast: toastActions.open,
  closeToast: toastActions.close,
  updateCampaignsBudget: tableActions.updateCampaignsBudget,
  setNewTotal: statisticsActions.setNewTotal,
  getTotal: statisticsActions.getTotal,
  updateCampaignName: tableActions.updateCampaignName,
  updateCampaignsList: tableActions.updateCampaignList,
};

export const Table = connect(mapState, mapActions)(TableComponent);
