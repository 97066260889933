import React from 'react';
import { Button, TextField } from 'factor';

import './createGroup/style.scss';

interface Props {
  valueFromSearch: string;
  onCreateGroup: (groupName: string) => void;
  creatingStatusChanged: (status: boolean) => void;
}

interface State {
  newGroupName: string;
  creatingStarted: boolean;
}

class CreateGroupComponent extends React.Component<Props, State> {
  state = {
    newGroupName: '',
    creatingStarted: false,
  }

  inputRef;

  handleCreateGroup = () => {
    const { onCreateGroup, valueFromSearch } = this.props;
    const { creatingStarted, newGroupName } = this.state;

    if (!creatingStarted) {
      this.setState({
        creatingStarted: true,
        newGroupName: valueFromSearch,
      }, () => this.inputRef.select());
      return;
    }

    onCreateGroup(newGroupName.trim());
    this.setState({
      creatingStarted: false,
      newGroupName: '',
    });
  }

  onCancel = () => {
    this.setState({
      creatingStarted: false,
      newGroupName: '',
    });
  }

  componentDidUpdate(_, prevState) {
    if (prevState.creatingStarted !== this.state.creatingStarted) {
      this.props.creatingStatusChanged(this.state.creatingStarted);
    }
  }

  render() {
    const { newGroupName, creatingStarted } = this.state;

    return (
      <div className="create-group-container">
        {creatingStarted &&
          <div className="create-group-container__text-field">
            <TextField
              name="newGroupName"
              label="Group Name"
              onChange={v => {
                this.setState({
                  newGroupName: v,
                });
              }}
              value={newGroupName}
              inputRef={e => this.inputRef = e}
              autofocus={true}
            />
          </div>
        }
        <div className="create-group-container-buttons d-flex mt-3 mb-2">
          {creatingStarted &&
            <Button
              className="create-group-container__button btn-square _conflower-blue _sm mr-2"
              onClick={this.onCancel}>Cancel
            </Button>
          }
          <Button className={`create-group-container__button ${creatingStarted ? 'btn-square _filled' : 'btn-round'} _conflower-blue _sm`}
            disabled={creatingStarted && !newGroupName.trim().length}
            onClick={this.handleCreateGroup}>Create New Group
          </Button>
        </div>
      </div>
    );
  }
}

export const CreateGroup = CreateGroupComponent;
