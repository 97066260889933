import React from 'react';
import './sidebarContent/styles/styles.scss';
import HorizontalBarChart from './sidebarContent/components/horizontalBarChart/HorizontalBarChart';
import { GraphsWrapper } from './sidebarContent/GraphsWrapper';

export const SidebarContent = () => {
  return (
    <div className="sidebar-charts">
        <GraphsWrapper className={'sidebar-charts__item'}/>
        <HorizontalBarChart className="sidebar-charts__item" />
    </div>
  )
};
