import { reducerFromMap } from '../../utils/actions';
import { Action } from '../../models/Action';
import { campaingGroupsConstants } from './constants';
import { CampaignGroup, GroupsList } from '../../models/CampaignGroup';

export interface CampaignGroupsState {
  loading: boolean;
  data: GroupsList[];
  selectedId: number | null;
  selectedGroups: CampaignGroup[];
}

export const defaultCampaignsState: CampaignGroupsState = {
  loading: false,
  data: [],
  selectedId: null,
  selectedGroups: [],
};

function loadingStart(state): CampaignGroupsState {
  return {
    ...state,
    loading: true,
  };
}

function loadingSuccess(state, action: Action<CampaignGroup[]>): CampaignGroupsState {
  return {
    ...state,
    loading: false,
    data: action.payload,
  };
}

function loadingError(state): CampaignGroupsState {
  return {
    ...state,
    loading: false,
    data: [],
  };
}

function groupSelect(state, action: Action<number>): CampaignGroupsState {
  return {
    ...state,
    selectedId: action.payload,
  }
} 

function groupReset(state): CampaignGroupsState {
  return {
    ...state,
    selectedId: null,
  }
} 

function groupCreate(state, action): CampaignGroupsState {
  return {
    ...state, 
    data: [
      ...state.data,
      action.payload
    ]
  }
}

function checkGroup(state: CampaignGroupsState, action: Action<CampaignGroup>): CampaignGroupsState {
  const { selectedGroups } = state;
  selectedGroups.push(action.payload);

  return {
    ...state,
    selectedGroups: [
      ...selectedGroups,
    ],
  }
}

function uncheckGroup(state: CampaignGroupsState, action: Action<CampaignGroup>): CampaignGroupsState {
  const { selectedGroups } = state;
  const index = selectedGroups.findIndex(d => d.id === action.payload.id);
  if (index !== -1) {
    selectedGroups.splice(index, 1);
  }
  return {
    ...state,
    selectedGroups: [
      ...selectedGroups,
    ],
  };
}

const reducer = reducerFromMap(
  defaultCampaignsState,
  {
    [campaingGroupsConstants.LOAD_CAMPAIGN_GROUPS__START]: loadingStart,
    [campaingGroupsConstants.LOAD_CAMPAIGN_GROUPS__SUCCESS]: loadingSuccess,
    [campaingGroupsConstants.LOAD_CAMPAIGN_GROUPS__ERROR]: loadingError,
    [campaingGroupsConstants.CAMPAIGN_GROUPS__SELECT]: groupSelect,
    [campaingGroupsConstants.CAMPAIGN_GROUPS__RESET]: groupReset,
    [campaingGroupsConstants.CAMPAIGN_GROUPS__CHECK]: checkGroup,
    [campaingGroupsConstants.CAMPAIGN_GROUPS__UNCHECK]: uncheckGroup,
    [campaingGroupsConstants.CAMPAIGN_CREATE_GROUP]: groupCreate,
  }
);

export const campaignGroups = (state: CampaignGroupsState = defaultCampaignsState, action: Action<any>) => reducer(state, action);
