import { getInstance } from './index';
import { ParamsDTO } from '../models/Params';
import axios, { Canceler } from 'axios';

let cancel: Canceler;

export function getReportsData(path: string, params: ParamsDTO): Promise<any> {
  if (cancel) {
    cancel('Canceled by the user');
  }
  return getInstance()
    .post(`/${path}/`, params, {
      cancelToken: new axios.CancelToken((c) => {
        cancel = c;
      }),
    })
    .then(response => response.data)
    .catch(err => {
      console.log('err', err);
      return Promise.reject(err);
    });
}

export function getReportsTypes(): Promise<any> {
  if (cancel) {
    cancel('Canceled by the user');
  }
  return getInstance()
    .get('/creative_types/')
    .then(response => response.data)
    .catch(err => {
      console.log('err', err);
      return Promise.reject(err)
    });
}
