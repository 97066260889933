import { reducerFromMap } from '../../utils/actions';
import { Action } from '../../models/Action';
import { authConstants } from './constants';
import { User } from '../../models/User';
import { createIqmInstance } from '../../api';
import { createLambdaInstance } from '../../api/lambdaAPI';

export type AuthState = {
  authorized: boolean,
  apiToken: string,
  userType: number,
  domainURL: string,
  dataLambdaUrl: string,
  loading: boolean,
  error: string,
  firstName: string,
  lastName: string,
  timezoneName: string,
  timezoneId: number,
  userId: number | null,
  dspId: number | null,
}

export const defaultAuthState: AuthState = {
  authorized: false,
  apiToken: '',
  userType: -1,
  domainURL: '',
  dataLambdaUrl: '',
  loading: false,
  error: '',
  firstName: '',
  lastName: '',
  timezoneId: -1,
  timezoneName: 'UTC',
  userId: null,
  dspId: null,
};

function loginSuccess(state: AuthState, action: Action<User>): AuthState {
  const { payload } = action;
  return {
    ...state,
    ...payload,
    authorized: true,
  };
}

function logout(): AuthState {
  return {
    ...defaultAuthState,
  };
}

const reducer = reducerFromMap<AuthState>(defaultAuthState, {
  [authConstants.LOGIN_SUCCESS]: loginSuccess,
  [authConstants.LOGOUT]: logout,
});

export const auth = (state: AuthState = defaultAuthState, action: Action<any>) =>
  reducer(state, action);
