import React from 'react';
import { createPortal } from 'react-dom';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import moment from 'moment';
import { formatNumberWithSuffix, CurrencyFormat } from '../../../../../utils/format';
import {
  PairedGraphModel,
  percentFormatMetrics,
  integerFormatMetrics,
  currencyFormatMetrics,
} from '../../../../../models/Graph';
import { DATE_FORMAT } from '../../../../../utils/date';
import { GraphsNames } from '../../../../../store/graphs/reducers';
import { Option, OptionID } from '../../../../../models/Option';
import { ChartTooltip } from './barWithLineChart/ChartTooltip';
import { AppState } from '../../../../../store';
import { connect } from 'react-redux';
import { DateRange } from '../../../../../store/filter/reducers';

function calculateArray(arr: any[], len: number) {
  const newArr: any[] = [];
  const pivot = Math.ceil((arr.length - 2) / (len - 1));

  let hasLast = false;
  for (let ii = 0; ii < len; ii++) {
    const ind = pivot * ii > arr.length - 1 ? arr.length - 1 : pivot * ii;
    if (!hasLast) {
      newArr.push(arr[ind]);
    }
    hasLast = ind === arr.length - 1;
  }
  return newArr;
}

const MIN_LENGTH = 12;
const MAX_LENGTH = 24;

interface Props {
  data: Array<PairedGraphModel>;
  [GraphsNames.graph1]: Option;
  [GraphsNames.graph2]: Option;
  dateRange: DateRange | null;
  timezone: OptionID;
}

interface State {
  tooltipIsActive: boolean;
  label: string;
  value1: number;
  value2: number;
  coordX: number;
  coordY: number;
  tranformedData: Array<PairedGraphModel>;
}

const tickStyle = {
  fontSize: '12px',
  fill: 'rgba(120,120,120,.5)',
  fontFamily: 'Lato Regular, sans-serif',
};

class BarWithLineChartComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      tooltipIsActive: false,
      label: '',
      value1: 0,
      value2: 0,
      coordX: 0,
      coordY: 0,
      tranformedData: props.data.length > MAX_LENGTH
        ? calculateArray(props.data, MAX_LENGTH)
        : props.data,
    };
  }

  mouseMoveHandler = (data, event) => {
    const { dateRange } = this.props;

    if (data && data.isTooltipActive && dateRange) {
      const values = data.activePayload.map(i => i.value);
      this.setState({
        tooltipIsActive: true,
        label: this.createTooltipLabel(
          get(data, 'activePayload[0].payload.start', 0),
          get(data, 'activePayload[0].payload.end', 0),
        ),
        value1: values[0],
        value2: values[1],
        coordX: event.clientX + 10,
        coordY: event.clientY + 20,
      });
    } else {
      this.setState({ tooltipIsActive: false });
    }
  }

  debouncedMouseMoveHandler = debounce((data, event) => this.mouseMoveHandler(data, event), 50);

  renderTooltip = () => {
    const { graph1, graph2 } = this.props;
    const {
      label,
      coordX,
      coordY,
      value1,
      value2,
      tooltipIsActive,
    } = this.state;

    const valuesMapper: (string | number)[] = [value1, value2];

    [graph1, graph2].forEach((g, i) => {
      if (percentFormatMetrics.includes(g.label)) {
        valuesMapper[i] = `${(+valuesMapper[i]).toFixed(2)}%`;
      } else if (integerFormatMetrics.includes(g.label)) {
        valuesMapper[i] = `${formatNumberWithSuffix(valuesMapper[i])}`;
      } else if (currencyFormatMetrics.includes(g.label)) {
        valuesMapper[i] = `${CurrencyFormat.format(+valuesMapper[i])}`
      }
    })

    return tooltipIsActive ? (
      <ChartTooltip
        label={label}
        coordX={coordX}
        coordY={coordY}
        value1={`${graph1.label}: ${valuesMapper[0]}`}
        value2={`${graph2.label}: ${valuesMapper[1]}`}
        withDots
      />
    ) : null;
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props;

    if (!isEqual(prevProps.data, data)) {
      this.setState({
        tranformedData: data.length > MAX_LENGTH
          ? calculateArray(data, MAX_LENGTH)
          : data,
      })
    }
  }

  createTooltipLabel = (start: number, end: number): string => {
    const { dateRange, timezone } = this.props;
    const diffDays = moment(end).diff(moment(start), 'd');
    let startLabelFormat = DATE_FORMAT;
    let endLabelFormat = DATE_FORMAT;

    if (dateRange) {
      const diffDaysGlobal = dateRange.end.diff(dateRange.start, 'd');
      if (diffDaysGlobal >= MIN_LENGTH &&  diffDaysGlobal < MAX_LENGTH) {
        return `${moment(start).tz(timezone.value).format(DATE_FORMAT)}`
      }
      if (diffDaysGlobal < MAX_LENGTH) {
        startLabelFormat = (diffDaysGlobal < 1) ? 'hh:mm A' : `${DATE_FORMAT} hh:mm A`;
        endLabelFormat = (diffDays > 1) ? `${DATE_FORMAT} hh:mm A` : 'hh:mm A';
      }
    }

    return `${moment(start).tz(timezone.value).format(startLabelFormat)} - ${moment(end).tz(timezone.value).format(endLabelFormat)}`;
  }

  
  render() {
    const { data, timezone } = this.props;
    const { tranformedData } = this.state;

    if (!data.length || !timezone) {
      return null;
    }

    return (
      <React.Fragment>
        <ResponsiveContainer width={'100%'} height={200}>
          <ComposedChart
            width={600}
            height={400}
            data={tranformedData}
            margin={{ top: 10, right: 0, bottom: 0, left: -15 }}
            onMouseMove={this.debouncedMouseMoveHandler}
            onMouseLeave={this.debouncedMouseMoveHandler}
          >
            <CartesianGrid stroke='#f5f5f5' />
            <XAxis
              dataKey="xAxiesLabel"
              tickFormatter={(v) => moment(v).tz(timezone.value).format(DATE_FORMAT)}
              interval="preserveStartEnd"
              tick={tickStyle}
            />
            <YAxis
              yAxisId="left"
              tickFormatter={v => formatNumberWithSuffix(v)}
              tick={tickStyle}
              width={70}
            />
            <YAxis
              yAxisId="right"
              orientation={'right'}
              tickFormatter={v => formatNumberWithSuffix(v)}
              tick={tickStyle}
            />
            <Tooltip
              contentStyle={{ opacity: 0 }}
            />
            <Bar
              yAxisId="left"
              radius={[3, 3, 0, 0]}
              dataKey={GraphsNames.graph1}
              barSize={20}
              fill='#28bc97' />
            <Line
              yAxisId="right"
              type='monotone'
              dataKey={GraphsNames.graph2}
              stroke='#4d5ecc'
              strokeWidth={2} />
          </ComposedChart>
        </ResponsiveContainer>
        {createPortal(this.renderTooltip(), document.body)}
      </React.Fragment>
    );
  }
}

const mapState = (state: AppState) => ({
  dateRange: state.filter.dateRange,
  timezone: state.filter.timezone,
});

export const BarWithLineChart = connect(mapState)(BarWithLineChartComponent);
