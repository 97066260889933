import { getInstance } from './index';
import { TableResponse } from '../models/Table';
import { ParamsDTO } from '../models/Params';
import axios, { Canceler } from 'axios';
import { CampaignStatus, CampaignDTO, UpdateCampaignsEndDate } from '../models/Campaign';
import { UpdateBudgetResponse } from '../models/Response';

let cancel: Canceler;

export function getTableData(params: ParamsDTO): Promise<TableResponse> {
  if (cancel) {
    cancel('Canceled by the user');
  }
  return getInstance()
    .post('/campaigns/list', params, {
      cancelToken: new axios.CancelToken((c) => {
        cancel = c;
      }),
    })
    .then(response => response.data)
    .catch(err => {
      console.log('err', err);
      return Promise.reject(err);
    });
}

export function getTableDataFromGroup(params: any, id: number): Promise<TableResponse> {
  if (cancel) {
    cancel('Canceled by the user');
  }
  return getInstance()
    .get(`/v2/adv/campaigngroup/${id}/campaigns/list/details`, {
      params,
      cancelToken: new axios.CancelToken((c) => {
        cancel = c;
      }),
    })
    .then(response =>  response.data)
    .catch(err => {
      console.log('err', err);
      return Promise.reject(err);
    });
}

export function getCampaignDetails(id: number) {
  return getInstance()
    .get(`/campaigns/${id}`)
    .then(response => response)
    .catch(err => {
      console.log('err', err);
      return Promise.reject(err);
    });
}

export function updateCampaign(id: string | number, data: any) {
  return getInstance()
    .patch(`/campaign/${id}`, data)
    .then(response => response.data)
    .catch(err => {
      console.log('err', err);
      return Promise.reject(err);
    });
}

export function updateCampaignCompletely(id: string | number, campaignData: CampaignDTO, data: any) {
  return getInstance()
    .put(`/campaigns/${id}`, {
      ...campaignData,
      ...data,
    })
    .then(response => response.data)
    .catch(err => {
      console.log('err', err);
      return Promise.reject(err);
    });
}

export function updateCampaignBudget(reqBody: any) {
  return getInstance()
    .put('/v2/adv/campaigns/update-budget', reqBody)
    .then(response => response.data)
    .catch(err => {
      console.log('err', err);
      return Promise.reject(err.response.data || {});
    });
}

export function campaignsCount(): Promise<CampaignStatus> {
  return getInstance()
    .get('/campaign_count/all')
    .then(response => response.data)
    .catch(err => {
      console.log('err', err);
      return Promise.reject(err);
    });
}

export function duplicateCampaigns(params) {
  return getInstance()
    .post('/v2/adv/campaigns/duplicate', params)
    .then(response => response.data)
    .catch(err => {
      console.log('err', err);
      return Promise.reject(err);
    });
}

export function pauseCampaign(params) {
  return getInstance()
    .put(`v2/adv/campaigns/update-status`, {
      ...params,
      status: 'paused',
    })
    .then(response => response)
    .catch(err => {
      console.log('err', err);
      return Promise.reject(err);
    });
}

export function deleteCampaign(reqBody: any) {
  return getInstance()
    .put('/v2/adv/campaigns/update-status', reqBody)
    .then(response => response)
    .catch(err => {
      console.log('err', err);
      return Promise.reject(err);
    });
}

export function runCampaign(params) {
  return getInstance()
    .put(`v2/adv/campaigns/update-status`, {
      ...params,
      status: 'running',
    })
    .then(response => response)
    .catch(err => {
      console.log('err', err);
      return Promise.reject(err);
    });
}

export function restoreCampaign(params) {
  return getInstance()
    .put(`v2/adv/campaigns/update-status`, {
      ...params,
      status: 'draft',
    })
    .then(response => response)
    .catch(err => {
      console.log('err', err);
      return Promise.reject(err);
    });
}

export function updateEndTime(params: UpdateCampaignsEndDate): Promise<UpdateBudgetResponse<any>> {
  return getInstance()
    .put('/v2/adv/campaigns/update-end-date', params)
    .then(response => response.data)
    .catch(err => {
      console.log('Error while update End date for campaigns', err);
      return Promise.reject(err);
    });
}
