import axios, { AxiosInstance } from 'axios';
import { InstanceConfig } from '../index';
import { HEADER_TOKEN_NAME, HEADER_BASE_URL_NAME, LAMBDA_API_URL, LAMBDA_BASE_URL_HEADER } from '../../config';
import { createDashboard, getDashboards, updateDashboard, removeDashboard } from './Dashboards';
import { getData } from './CommonData';
import { getGraphData } from './GraphData';
import { getReportsGraphData } from './ReportsGraphData';

let lambdaInstance: AxiosInstance;

export function createLambdaInstance(config: InstanceConfig) {
  const instance = axios.create({
    baseURL: LAMBDA_API_URL,
    headers: {
      [HEADER_TOKEN_NAME]: config.token,
      [HEADER_BASE_URL_NAME]: LAMBDA_BASE_URL_HEADER,
    },
  });

  instance.interceptors.response.use((response: any) => {
    return response;
  }, (response: any) => {
    if (axios.isCancel(response)) {
      const res = {
        response: {
          data: response,
        }
      };

      throw res;
    }
    return Promise.reject(response);
  });

  lambdaInstance = instance;
}

export function getLambdaInstance(): AxiosInstance {
  return lambdaInstance || axios;
}

export const lambdaAPI = {
  dashboards: {
    create: createDashboard,
    get: getDashboards,
    update: updateDashboard,
    remove: removeDashboard,
  },
  common: {
    get: getData,
  },
  graph: {
    get: getGraphData,
  },
  reportsGraph: {
    get: getReportsGraphData,
  },
}
