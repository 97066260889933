import React from 'react';
import { connect } from 'react-redux';
import { GraphSelectWrapper } from './GraphSelectWrapper';
import { Graphs } from './Graphs';
import { GraphsNames } from '../../../../store/graphs/reducers';
import { AppState } from '../../../../store';
import { Option } from '../../../../models/Option';

interface Props {
  className: string;
  graph1: Option;
  graph2: Option;
}
class GraphsWrapperComponent extends React.Component<Props> {
  render() {
    const { className, graph1, graph2 } = this.props;

    return (
      <div className={`chart-block ${className}`}>
        <header className="chart-header chart-block__header _vertical">
          <div className="chart-header__controls">
            <GraphSelectWrapper
              className={'chart-header__select _with-legend'}
              excludingOption={graph2}
              stateKey={GraphsNames.graph1}
              legendColor="#28bc97"
              tooltipParams={{ position: 'bottom-left' }}
            />
            <span className="chart-header__controls-vs">
              vs
            </span>
            <GraphSelectWrapper
              className={'chart-header__select _with-legend'}
              excludingOption={graph1}
              stateKey={GraphsNames.graph2}
              legendColor="#4d5ecc"
              tooltipParams={{ position: 'bottom-right' }}
            />
          </div>
        </header>
        <div className="chart-block__main">
          <Graphs />
        </div>
      </div>
    );
  }
};

const mapState = (state: AppState) => ({
  graph1: state.filter.graph1,
  graph2: state.filter.graph2,
});

export const GraphsWrapper = connect(mapState)(GraphsWrapperComponent);
