import { StatisticsReportDTO, StatisticsTotalResult } from '../../models/Statistics';
import { reducerFromMap } from '../../utils/actions';
import { Action } from '../../models/Action';
import { statisticsConstants } from './constants';
import { CancellarError } from '../../models/Response';

export interface StatisticsState {
  report: StatisticsReportDTO;
  total: StatisticsTotalResult | null;
  totalPrev: StatisticsTotalResult | null;
  loading: boolean;
  error: string | Object;
}

const defaultStatisticsState: StatisticsState = {
  report: null,
  total: null,
  totalPrev: null,
  loading: false,
  error: '',
};

function getTotalStart(state: StatisticsState): StatisticsState {
  return {
    ...state,
    loading: true,
    error: ''
  };
}

function getTotalSuccess(state: StatisticsState, action: Action<StatisticsTotalResult>): StatisticsState {
  return {
    ...state,
    loading: false,
    total: action.payload,
  };
}

function getTotalPrevSuccess(state: StatisticsState, action: Action<StatisticsTotalResult>): StatisticsState {
  return {
    ...state,
    totalPrev: action.payload,
  };
}

function getTotalError(state: StatisticsState, action: Action<CancellarError<Response>>): StatisticsState {
  return {
    ...state,
    loading: action.payload.response ? !action.payload.response.status : false,
    error: action.payload,
  };
}

function clearTotal(state: StatisticsState): StatisticsState {
  return {
    ...state,
    total: null,
    totalPrev: null,
  }
}

const reducer = reducerFromMap(
  defaultStatisticsState,
  {
    [statisticsConstants.GET_TOTAL__START]: getTotalStart,
    [statisticsConstants.GET_TOTAL__SUCCESS]: getTotalSuccess,
    [statisticsConstants.GET_TOTAL_PREV__SUCCESS]: getTotalPrevSuccess,
    [statisticsConstants.GET_TOTAL__ERROR]: getTotalError,
    [statisticsConstants.CLEAR_TOTAL]: clearTotal,
  }
);

export const statistics = (state: StatisticsState, action: Action<any>) => reducer(state, action);
