import React from 'react';
import { DialogContent, Button, Checkbox } from 'factor';
import { localStorageService } from '../../../../services/localStorage';

interface Props {
  onClose: () => void;
  onReset: () => void;
}

interface State {
  remember: boolean;
  showModal: boolean;
}

const STORAGE_NAME = 'resetDashboard';

class ResetDialogContentComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      remember: false,
      showModal: this.checkShowingModal(),
    };
  }

  handleChBoxChange = (value: boolean) => {
    this.setState({
      remember: value,
    });
  };

  checkShowingModal() {
    const { onReset } = this.props;
    const store = localStorageService.getLocal(STORAGE_NAME);
    if (store) {
      onReset();
    }
    return !store;
  };

  handleReset = () => {
    if (this.state.remember) {
      localStorageService.saveLocal<boolean>(STORAGE_NAME, true);
    }
    this.props.onReset();
  };

  render() {
    const { onClose } = this.props;
    const { remember, showModal } = this.state;

    return showModal ? (
      <DialogContent>
        <h3 className="title-card mb-4">Reset Dashboard?</h3>
        <h5 className="title-card-subtitle mb-4">Reset all the Dashboard parameters to default? It will discard all the unsaved changes on the Dashboard.</h5>
        <div className="d-flex justify-content-end align-items-center mt-2">
          <div className="mr-3 flex-grow-1">
            <Checkbox
              label="Don't ask me again"
              checked={remember}
              onChange={this.handleChBoxChange}
            />
          </div>
          <Button
            className="btn-square _conflower-blue mr-2"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            className="btn btn-square _conflower-blue _filled"
            onClick={this.handleReset}
          >
            Reset
          </Button>
        </div>
      </DialogContent>
    ) : null;
  }
}

export const ResetDialogContent = ResetDialogContentComponent;
