import { getInstance } from './index';
import { OptionID } from '../models/Option';

export function getTimezones(): Promise<OptionID[]> {
  return getInstance()
    .get('/timezones')
    .then(response => {
      return response.data;
    })
    .then((data: [{id: number, label: string, name: string }]) => {
      return data.map(item => {
        return {
          label: item.label,
          value: item.name,
          id: item.id,
        };
      });
    })
    .catch(() => {
      return [];
    });
}
