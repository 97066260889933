import * as React from 'react';
import { NavigationBar } from '../components/NavigationBar';
import { DashboardWrapper } from './dashboard/DashboardWrapper';
import { Sidebar } from '../components/Sidebar';
import { SidebarContent } from '../components/sidebar/components/SidebarContent';
import { DashboardContent } from './dashboard/DashboardContent';
import './dashboard/styles.scss';

export class Dashboard extends React.Component {
  render() {
    return (
      <React.Fragment>
        <NavigationBar/>
        <DashboardWrapper>
          <Sidebar>
            <SidebarContent/>
          </Sidebar>
          <DashboardContent/>
        </DashboardWrapper>
      </React.Fragment>
    );
  }
}
