import React, { useState, useEffect } from 'react';
import { CurrencyFormat } from '../../../utils/format';
import { API } from '../../../api';
import { Button, Tooltip } from 'factor';

export const Balance = (props) => {
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    const success = ({ availableBalance }) => {
      setBalance(availableBalance);
    };
    const error = () => {
      setBalance(0)
    };

    API.profile.getAvailableBalance()
      .then(success)
      .catch(error)
  }, []);

  return (
    <div className="nav-bar__balance">
      <div className="nav-bar__balance-info">
        <Tooltip label="Available Balance">
          <span className="nav-bar__balance-value">
            {CurrencyFormat.format(balance)}
          </span>
        </Tooltip>
      </div>
      <Tooltip label="Add more funds to your account (opens in new tab)">
        <Button onClick={props.addBalance} className="btn-round _conflower-blue">
          Add Funds
        </Button>
      </Tooltip>
    </div>
  )
};
