import {
  applyMiddleware,
  combineReducers,
  createStore,
  compose,
} from 'redux';
import thunk from 'redux-thunk';
import throttle from 'lodash/throttle';
import { auth, AuthState } from './auth/reducer';
import { app, AppState as IAppState } from './app/reducers';
import { profile, ProfileState } from './profile/reducers';
import { statistics, StatisticsState } from './statistics/reducers';
import { graph, GraphState } from './graphs/reducers';
import { table, TableState } from './table/reducers';
// import { advertisers, AdvertisersState } from './advertisers/reducers';
import { filter, FilterState, LOCAL_FILTER_KEY } from './filter/reducers';
import { campaignGroups, CampaignGroupsState } from './campaignGroups/reducers';
import { toast, ToastState } from './toast/reducers';
import { reports, ReportsState, LOCAL_REPORTS_KEY } from './reports/reducers';
import { filteredCampaignGroups, FilteredCampaignGroupsState} from './filteredCampaignGroups/reducers';
import { dashboards, DashboardState } from './dashboards/reducers';
import { sessionStorageService } from '../services/sessionStorage';

// export interface AppState {
//   auth: AuthState,
//   profile: ProfileState,
//   statistics: StatisticsState,
//   graph: GraphState,
//   table: TableState,
//   advertisers: AdvertisersState,
//   filter: FilterState,
// }

export interface AppState {
  app: IAppState;
  auth: AuthState;
  filter: FilterState;
  statistics: StatisticsState,
  table: TableState,
  profile: ProfileState,
  campaignGroups: CampaignGroupsState;
  graph: GraphState,
  toast: ToastState,
  reports: ReportsState,
  filteredCampaignGroups: FilteredCampaignGroupsState,
  dashboards: DashboardState,
}

const reducer = combineReducers({
  auth,
  app,
  profile,
  statistics,
  graph,
  table,
  // advertisers,
  filter,
  campaignGroups,
  toast,
  reports,
  filteredCampaignGroups,
  dashboards,
});

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore<any, any, any, any>(reducer, composeEnhancers(applyMiddleware(thunk)));

store.subscribe((throttle(() => {
  sessionStorageService.save<FilterState>(LOCAL_FILTER_KEY, store.getState().filter);
  sessionStorageService.save<ReportsState>(LOCAL_REPORTS_KEY, store.getState().reports);
}, 1000)));
