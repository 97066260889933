import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import get from 'lodash/get';
import { DialogContent, Button } from 'factor';
import { TimezoneProvider, TimezonePicker, EpochDatePicker } from 'iqm-framework';
import { AppState } from '../../../../../../../store';
import { Campaign } from '../../../../../../../models/Campaign';
import { API } from '../../../../../../../api';
import { TableComponentInstance } from '../../../Table'
import { toastActions } from '../../../../../../../store/toast/actions';
import { pluralize } from '../../../../../../../utils/pluralize';
import { ToastContent } from '../../../../../../../components/toastContent/ToastContent';
import { OptionID } from '../../../../../../../models/Option';
import { filterActions } from '../../../../../../../store/filter/actions';

interface Props {
  selectedCampaigns: Campaign[];
  action: 'run' | 'restore';
  label: string;
  onClose: () => void;
  openToast: (message: string | JSX.Element) => void;
  dspId: number | null;
  advertiserId: number | null;
  timezone: OptionID;
  timezones: OptionID[];
  buttonLabel?: string;
  getStatuses: () => void;
}

interface State {
  value: number | null;
}

class RunningDialogContentComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
    };
  }

  get timezone() {
    const { timezone, timezones } = this.props;
    let campaignTz = timezone;
    const selectedCampaignsTimezones = this.selectedCampaignsTimezones();

    if (selectedCampaignsTimezones.length === 1) {
      campaignTz = timezones.find(tz => tz.value === selectedCampaignsTimezones[0]) || timezone;
    }

    return campaignTz;
  }

  selectedCampaignsTimezones = () => {
    const { selectedCampaigns } = this.props;
    return selectedCampaigns.reduce((acc: string[], campaign) => {
      if (!acc.includes(campaign.campaignTimezone)) {
        acc.push(campaign.campaignTimezone);
      }
      return acc;
    }, []);
  }

  editHandler = () => {
    const { selectedCampaigns, onClose, openToast, label, advertiserId, dspId, getStatuses, action } = this.props;
    const { value } = this.state;
    let endDateTime;

    if (TableComponentInstance) {
      const ids = selectedCampaigns.map(c => c.campaignId);
      if (value) {
        const momentValue = moment(value);
        endDateTime = momentValue.diff(moment(), 'm') < 15 ? 
          moment().add(15, 'm').unix() : 
          momentValue.unix();
      }
      const campaignAction = action === 'run' ? 'runCampaign' : 'restoreCampaign';

      API.campaigns[campaignAction]({
        advertiserId,
        dspId,
        campaignIds: ids.join(','),
        endDate: endDateTime,
      })
        .then(res => {
          const toastMessageCampaign = selectedCampaigns.length > 1 ? 'Campaigns': 
            `Campaign ${selectedCampaigns[0].campaignName}`;
          let toastMessage = `${toastMessageCampaign} status changed`;
          const errors = get(res, 'data.responseObject.reason', []);
          if (errors.length) {
            const errorsMapper = errors.reduce((acc, i) => {
              const findedIndex = acc.findIndex(a => a.errorMessage === i.errorMessage);
              findedIndex > -1 ? acc[findedIndex].id.push(i.id) : acc.push({
                errorMessage: i.errorMessage,
                id: [i.id],
              })
              return acc;
            }, []);

            const errorMessage = errorsMapper.map(e => {
              const { errorMessage, id } = e;
              return `${pluralize('Campaing', id.length, {
                single: `Campaign with id ${id.join(', ')} is`,
                multiple: `Campaigns with id ${id.join(', ')} are`,
              })} not updated! ${errorMessage ? 'Reason: ' + errorMessage : ''}`;
            });

            throw errorMessage;
          }
          TableComponentInstance.clearSelected();
          TableComponentInstance.getNewData();
          getStatuses();
          onClose();
          openToast(toastMessage);
        })
        .catch(e => {
          let toastMessage = `Error while update ${label}`;
          TableComponentInstance.getNewData(toastMessage);
          openToast(e.length ? <ToastContent messages={e} /> : toastMessage);
          onClose();
        });
    }
  };

  isEndDateADayInFuture = (campaign) => {
    return moment.tz(campaign.end_time * 1000, 'UTC').diff(moment.tz(moment(), 'UTC'), 'days') >= 1;
  };

  getDefaultDate = tz => {
    const {
      selectedCampaigns
    } = this.props;

    let defaultDate;

    if (selectedCampaigns.length === 1) {
      if (this.isEndDateADayInFuture(selectedCampaigns[0])) {
        defaultDate = moment(selectedCampaigns[0].endTime * 1000).tz(tz);
      } else {
        defaultDate = moment().add(1, 'd').tz(tz);
      }
    } else if (selectedCampaigns.some(c => c.status === 'expired')) {
      defaultDate = moment().add(1, 'd').tz(tz);
    }

    return moment.tz(defaultDate.format('YYYY-MM-DD HH:mm:ss'), moment.tz.guess());
  };

  shouldPopulatedDefaultEndDate = () => {
    const {
      selectedCampaigns
    } = this.props;

    if (selectedCampaigns.length > 1) {
      return !(
        selectedCampaigns.every(c => c.status === 'deleted') || 
        selectedCampaigns.every(c => c.status === 'paused')
      );
    } else if (selectedCampaigns[0].status === 'deleted' && selectedCampaigns[0].budgetPacing === 'true') {
      return false;
    } else {
      return Boolean(selectedCampaigns[0].endTime);
    }
  };

  onDateChanged = value => {
    this.setState({
      value,
    });
  };

  datePickerErrorHandler = message => {
    this.props.openToast(message);
  };

  render() {
    const { onClose, selectedCampaigns, label, buttonLabel, timezone } = this.props;
    const { value } = this.state;

    const moreThenOneTimezoneSelected = this.selectedCampaignsTimezones().length > 1;
    const shouldPopulatedDefaultEndDate = this.shouldPopulatedDefaultEndDate();

    return (
      <DialogContent>
        <TimezoneProvider timezone={this.timezone}>
          <h3 className="title-card mb-3">{label}</h3>
          {(selectedCampaigns.length === 1)
            ? <h5 className={`title-card-subtitle ${moreThenOneTimezoneSelected ? 'mb-2' : 'mb-4'}`}>Enter the
            <span className="_black"> End Date </span>
              for the
            <span className="_black"> {selectedCampaigns[0].campaignName}</span> campaign.</h5>
            : <h5 className={`title-card-subtitle ${moreThenOneTimezoneSelected ? 'mb-2' : 'mb-4'}`}>Enter the
            <span className="_black"> End Date </span>
              for the
            <span className="_black"> {selectedCampaigns.length}</span> campaigns.</h5>
          }
          {moreThenOneTimezoneSelected && (
            <h5 className="title-card-subtitle mb-4">Selected campaigns have different timezones, the time displayed in
            <span className="_black"> {timezone && timezone.value}</span> timezone and would be converted to Campaign timezone when saved.</h5>
          )}
          <div className="mb-3">
            <EpochDatePicker
              dateFormat="MM/DD/YYYY hh:mm A"
              datePickerProps={{
                numberOfCalendars: 1,
              }}
              label="End Date and Time"
              withTimePicker={true}
              singleDateMode={true}
              singleDate={value}
              singleDatePlaceholder={!shouldPopulatedDefaultEndDate ? '(Optional)' : ''}
              onDateChanged={this.onDateChanged}
              defaultDate={shouldPopulatedDefaultEndDate ? this.getDefaultDate : null}
              calendarMinimumDate={moment().add(15, 'm').valueOf()}
              onError={this.datePickerErrorHandler}
              tooltipParams={{ label: 'Date and Time when the Campaign will end' }}
            />
          </div>
          <div className="d-flex justify-content-end align-items-center mt-2">
            <div className="d-flex duplicate-dialog__timezone">
              <TimezonePicker onTimezoneChange={null} />
            </div>
            <Button
              className="btn-square _conflower-blue mr-2"
              onClick={onClose}> Cancel
            </Button>
            <Button
              className="btn-square _conflower-blue _filled"
              onClick={() => this.editHandler()}
            >
              {buttonLabel || 'Run'}
            </Button>
          </div>
        </TimezoneProvider>
      </DialogContent>
    );
  }
}

const mapState = (state: AppState) => ({
  selectedCampaigns: state.table.selectedTableCampaigns,
  dspId: state.auth.dspId,
  advertiserId: state.auth.userId,
  timezone: state.filter.timezone,
  timezones: state.filter.timezones,
});

const mapActions = {
  openToast: toastActions.open,
  getStatuses: filterActions.getStatuses,
};

export const RunningDialogContent = connect(mapState, mapActions)(RunningDialogContentComponent);
