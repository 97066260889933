import React from 'react';
import { Snackbar } from 'factor';
import { connect } from 'react-redux';
import { AppState } from '../../store';
import { toastActions } from '../../store/toast/actions';

interface Props {
  sidebarOpened: boolean;
  toastOpened: boolean;
  toastMessage: string | JSX.Element;
  closeToast: () => void;
}

class DashboardWrapperComponent extends React.Component<Props> {
  render() {
    const {
      children,
      sidebarOpened,
      toastOpened,
      closeToast,
      toastMessage } = this.props;
    return (
      <React.Fragment>
        <div className={`main _sidebar ${sidebarOpened ? '_opened' : ''}`}>
          {children}
        </div>
        <Snackbar
          open={toastOpened}
          onClose={closeToast}
          message={toastMessage}
        />
      </React.Fragment>
    )
  }
}

const mapState = (state: AppState) => {
  return {
    sidebarOpened: state.app.sidebarOpened,
    toastOpened: state.toast.isOpen,
    toastMessage: state.toast.message,
  };
};

const mapActions = {
  closeToast: toastActions.close,
};

export const DashboardWrapper = connect(mapState, mapActions)(DashboardWrapperComponent);
