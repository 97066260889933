import moment from 'moment';
import { reducerFromMap } from '../../utils/actions';
import { filterConstants } from './constants';
import { Action } from '../../models/Action';
import { defaultsortingColumnOptions, defaultSortingGroupsColumnOptions, Option, Select, StickedOption, OptionID } from '../../models/Option';
import { CampaignStatusType } from '../../models/Campaign';
import { Graph1DefaultMetricOption, Graph2DefaultMetricOption } from '../../models/Graph';
import { GraphsNames } from '../graphs/reducers';
import { sessionStorageService } from '../../services/sessionStorage';

export const LOCAL_FILTER_KEY = 'filterState';

export type DateRange = {
  start: moment.Moment,
  end: moment.Moment,
}

export interface Template {
  label: string;
  value: number;
  data: FilterState;
}

export interface GraphSelectChangeValue {
  key: GraphsNames;
  value: Option;
}

export type TemplateValue = Template | null;

export interface Freeze {
  columns: number;
  rows: number;
}

export interface FilterState {
  initialDateRange: DateRange | null;
  dateRange: DateRange | null;
  defaultDateRangeWasUpdated: Boolean;
  advertisers: Select;
  creativeTypes: Select;
  campaignGroups: Select;
  timezone: OptionID | null;
  timezones: OptionID[];
  tableLevel: Option;
  search: string;
  status: Option<CampaignStatusType>;
  statusOptions: Option<CampaignStatusType>[];
  [GraphsNames.graph1]: Option;
  [GraphsNames.graph2]: Option;
  sortingColumns: StickedOption[];
  sortingGroupsColumns: StickedOption[];
  freeze: Freeze;
  campaignTypes: Option[];
  selectedCampaignTypes: Option[];
  isSelectingCampaignsByGroup: boolean;
}

export enum TableLevel {
  Campaigns = 'campaigns',
  Groups = 'groups',
}

export const TableLevels: Option<TableLevel>[] = [
  {
    label: 'Campaigns',
    value: TableLevel.Campaigns,
  },
  {
    label: 'Groups',
    value: TableLevel.Groups,
  }
];

const localDefaultFilterState: FilterState = sessionStorageService.get<FilterState>(LOCAL_FILTER_KEY).data;

export const defaultFilterState: FilterState = {
  initialDateRange: null,
  dateRange: null,
  defaultDateRangeWasUpdated: false,
  advertisers: null,
  creativeTypes: null,
  campaignGroups: null,
  timezone: null,
  timezones: [],
  tableLevel: TableLevels[0],
  search: '',
  status: {
    label: 'Running',
    value: CampaignStatusType.running,
  },
  statusOptions: [],
  [GraphsNames.graph1]: Graph1DefaultMetricOption,
  [GraphsNames.graph2]: Graph2DefaultMetricOption,
  sortingColumns: defaultsortingColumnOptions,
  sortingGroupsColumns: defaultSortingGroupsColumnOptions,
  freeze: {
    columns: 0,
    rows: 0,
  },
  campaignTypes: [],
  selectedCampaignTypes: [],
  isSelectingCampaignsByGroup: false,
};

function setInitialDateRange(state: FilterState, action: Action<DateRange>): FilterState {
  return {
    ...state,
    initialDateRange: action.payload,
    defaultDateRangeWasUpdated: true,
    dateRange: action.payload,
  }
}

function setDateRange(state: FilterState, action: Action<DateRange>): FilterState {
  return {
    ...state,
    dateRange: action.payload,
  }
}

function selectTimezone(state: FilterState, action: Action<OptionID>): FilterState {
  return {
    ...state,
    timezone: action.payload,
  };
}

function getTimezones(state: FilterState, action: Action<OptionID[]>): FilterState {
  return {
    ...state,
    timezones: action.payload,
  }
}

function changeTableLevel(state: FilterState, action: Action<Option>): FilterState {
  return {
    ...state,
    tableLevel: action.payload,
  };
}

function updateSearch(state: FilterState, action: Action<string>): FilterState {
  return {
    ...state,
    search: action.payload,
  };
}

function setStatus(state: FilterState, action: Action<Option<CampaignStatusType>[]>): FilterState {
  const statusOptions = action.payload;
  const selectedValue = state.status.value;
  const selected = statusOptions.find(i => i.value === selectedValue);

  return {
    ...state,
    status: selected || defaultFilterState.status,
    statusOptions,
  };
}

function updateStatus(state: FilterState, action: Action<Option>): FilterState {
  return {
    ...state,
    status: action.payload,
  };
}

function updateGraphSelect(state: FilterState, action: Action<GraphSelectChangeValue>): FilterState {
  return {
    ...state,
    [action.payload.key]: action.payload.value,
  };
}

function changeSortingColumns(state: FilterState, action: Action<StickedOption[]>): FilterState {
  return {
    ...state,
    sortingColumns: action.payload,
  };
}

function changeSortingGroupsColumns(state: FilterState, action: Action<StickedOption[]>): FilterState {
  return {
    ...state,
    sortingGroupsColumns: action.payload,
  };
}

function updateFreeze(state: FilterState, action: Action<Freeze>): FilterState {
  return {
    ...state,
    freeze: action.payload,
  };
}

function updateFilters(state: FilterState, action: Action<any>): FilterState {
  const updatedState = Object.assign(state, action.payload);
  return {
    ...updatedState,
    dateRange: action.payload.dateRange ? {
      start: moment(action.payload.dateRange.start),
      end: moment(action.payload.dateRange.end),
    } : null,
  };
}

function setCampaignTypes(state: FilterState, action: Action<Option[]>): FilterState {
  return {
    ...state,
    campaignTypes: action.payload,
  };
}

function selectCampaignType(state: FilterState, action: Action<Option[]>): FilterState {
  return {
    ...state,
    selectedCampaignTypes: action.payload,
  }
}

function deselectCampaignType(state: FilterState): FilterState {
  return {
    ...state,
    selectedCampaignTypes: [],
  }
}

function changeSelectingCampaignsMode(state: FilterState, action: Action<boolean>): FilterState {
  return {
    ...state,
    isSelectingCampaignsByGroup: action.payload,
  }
}

const reducer = reducerFromMap(
  Object.keys(localDefaultFilterState).length
    ? {
      ...localDefaultFilterState,
      dateRange: localDefaultFilterState.dateRange,
    }
    : defaultFilterState,
  {
    [filterConstants.SET_DATE_RANGE]: setDateRange,
    [filterConstants.SET_INITIAL_DATE_RANGE]: setInitialDateRange,
    [filterConstants.SELECT_TIMEZONE]: selectTimezone,
    [filterConstants.GET_TIMEZONES]: getTimezones,
    [filterConstants.CHANGE_TABLE_LEVEL]: changeTableLevel,
    [filterConstants.UPDATE_SEARCH]: updateSearch,
    [filterConstants.SET_STATUS]: setStatus,
    [filterConstants.UPDATE_STATUS]: updateStatus,
    [filterConstants.UPDATE_GRAPH_SELECT]: updateGraphSelect,
    [filterConstants.CHANGE_SORTING_COLUMNS]: changeSortingColumns,
    [filterConstants.CHANGE_SORTING_GROUPS_COLUMNS]: changeSortingGroupsColumns,
    [filterConstants.UPDATE_FREEZE_TABLE_PART]: updateFreeze,
    [filterConstants.UPDATE_FILTERS]: updateFilters,
    [filterConstants.SET_CAMPAIGN_TYPES]: setCampaignTypes,
    [filterConstants.SELECT_CAMPAIGN_TYPE]: selectCampaignType,
    [filterConstants.DESELECT_CAMPAIGN_TYPE]: deselectCampaignType,
    [filterConstants.CHANGE_SELECTING_CAMPAIGNS_MODE]: changeSelectingCampaignsMode,
  }
);

export const filter = (state: FilterState, action: Action<any>) => reducer(state, action);
