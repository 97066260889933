import React from 'react';
import { connect } from 'react-redux';
import { Select } from 'factor';
import { ResponsiveBar } from '@nivo/bar';
import { createPortal } from 'react-dom';
import { ChartMetric } from '../../../../../../models/ChartMetric';
import { Option } from '../../../../../../models/Option';
import { dimensionOptions, metricOptions, ReportingAvialableMetrics, DimensionsHelper } from '../../../../../../models/Graph';
import { reportsActions, GetLambdaReports } from '../../../../../../store/reports/actions';
import { AppState } from '../../../../../../store/index';
import { StatisticReport } from '../../../../../../store/reports/reducers';
import { SkeletonChart } from '../SkeletonChart';
import CustomBarChartBarItem from './CustomBarChartBarItem';
import CustomBarChartTick from './CustomBarChartTick';
import { ChartTooltip } from '../barWithLineChart/ChartTooltip';
import {
  percentFormatMetrics,
  integerFormatMetrics,
  currencyFormatMetrics
} from '../../../../../../models/Graph';
import {
  CurrencyFormat,
  formatNumberWithSuffix
} from "../../../../../../utils/format";



const DIMENSIONS_KEYS_MAPPER = {
  os: 'devOs',
  creative: 'creativeName',
  creative_type: 'creativeTypeName',
  manufacturer: 'manufacturerName',
  campaign_group: 'campaignGroupName',
  income_range: 'incomeGroup',
  device_type: 'deviceType',
  traffic_type: 'trafficType',
  exchange: 'exchName',
}

interface Props extends GetLambdaReports {
  className?: string;
  data: StatisticReport[];
  selectedMetric: Option;
  selectedDimension: Option | null;
  loading: boolean;
  changeMetric: (metric: Option) => void;
  changeDimension: (dimension: Option) => void;
}

interface State {
  chart1Metric1?: ChartMetric | null,
  chart1Metric2?: ChartMetric | null,
  label: any,
  value: any,
  coordX: any,
  coordY: any,
  tooltipIsActive: any
}

const BarWrapper = (props: any) => <ResponsiveBar{...props} />;

class HorizontalBarChartComponent extends React.PureComponent<Props, State> {

  constructor(props) {
    super(props);
    this.state = {
      chart1Metric1: null,
      chart1Metric2: null,
      label: null,
      value: null,
      coordX: null,
      coordY: null,
      tooltipIsActive: null,
    }
  }

  formatData(data): Option[] {
    if (!data) {
      return [];
    }

    let result = data.map((d, index) => {
      const { selectedMetric, selectedDimension } = this.props;
      if (selectedDimension) {
        const selectedDimensionKey = DIMENSIONS_KEYS_MAPPER[selectedDimension.value] || DimensionsHelper[selectedDimension.value] || selectedDimension.value;
        const selectedMetricKey = ReportingAvialableMetrics[selectedMetric.value];

        return {
          // for showing 0 values
          value: +d[selectedMetricKey] + 100,
          label: `${d[selectedDimensionKey]}${index}`,
        };
      }
    });

    return result.reverse();
  };

  handleChangeDimension = dimension => {
    const { changeDimension, getLambdaReports, selectedDimension } = this.props;
    if (selectedDimension ? (selectedDimension.value !== dimension.value) : 1) {
      changeDimension(dimension);
      getLambdaReports();
    }
  };

  handleChangeMetric = metric => {
    const { changeMetric, getLambdaReports, selectedMetric } = this.props;
    if (selectedMetric.value !== metric.value) {
      changeMetric(metric);
      getLambdaReports();
    }
  }

  getCustomBarTooltipOption = data => {
    const selectedMetric = this.props.selectedMetric;
    let value: string | number = data.value - 100;

    if (percentFormatMetrics.includes(selectedMetric.label)) {
      value = `${(+value).toFixed(2)}%`;
    } else if (integerFormatMetrics.includes(selectedMetric.label)) {
      value = `${formatNumberWithSuffix(value)}`;
    } else if (currencyFormatMetrics.includes(selectedMetric.label)) {
      value = `${CurrencyFormat.format(value)}`;
    }

    return {
      label: data.indexValue,
      value: `${this.props.selectedMetric.label}: ${value}`
    }
  }

  mouseMoveHandler = (event, label, value?) => {
    this.setState({
      tooltipIsActive: true,
      coordX: event.clientX + 10,
      coordY: event.clientY + 15,
      label: label,
      value: value
    });
  }

  closeTooltip = () => {
    this.setState({ tooltipIsActive: false });
    this.forceUpdate();
  }

  renderCustomAxisLeftTick = tick =>
    <CustomBarChartTick
      key={tick.value}
      tick={tick}
      onMouseLeave={this.closeTooltip}
      onMouseMove={event => this.mouseMoveHandler({ clientX: event.clientX, clientY: event.clientY }, tick.value)}
      onMouseEnter={event => this.mouseMoveHandler({ clientX: event.clientX, clientY: event.clientY }, tick.value)}
    />

  renderCustomBarItem = barItem => (
    <CustomBarChartBarItem
      key={barItem.data.indexValue}
      barItem={barItem}
      onMouseLeave={this.closeTooltip}
      onMouseMove={event => {
        const customBarTooltipOption = this.getCustomBarTooltipOption(barItem.data);
        this.mouseMoveHandler({ clientX: event.clientX, clientY: event.clientY }, customBarTooltipOption.label, customBarTooltipOption.value)
      }}
      onMouseEnter={event => {
        const customBarTooltipOption = this.getCustomBarTooltipOption(barItem.data);
        this.mouseMoveHandler({ clientX: event.clientX, clientY: event.clientY }, customBarTooltipOption.label, customBarTooltipOption.value)
      }}
    />
  );

  renderTooltip = () => {
    const {
      label,
      value,
      coordX,
      coordY,
      tooltipIsActive,
    } = this.state;

    return tooltipIsActive ? (
      <ChartTooltip
        label={label.slice(0, -1)}
        value1={value}
        coordX={coordX}
        coordY={coordY}
      />
    ) : null;
  }

  render() {
    const {
      className,
      data,
      selectedMetric,
      selectedDimension,
      loading,
    } = this.props;

    const formattedData = this.formatData(data);

    return (
      <div className={`chart-block ${className}`}>
        <header className="chart-header">
          <div className="chart-header__controls">
            <div className="chart-header__select">
              <Select
                  options={dimensionOptions}
                  value={selectedDimension}
                  onChange={this.handleChangeDimension}
                  placeholder="Group By"
                  label="Group By"
                  tooltipParams={{
                    label: 'Select aspect or feature of the campaign, audience, technology, delivery, or location; to group measured data by them. For example, selecting Zip Code will give you data break-down by each Zip Code where the campaigns were serving',
                    position: 'bottom-left',
                    auto: false,
                    labelMaxWidth: 304,
                  }}
              />
            </div>
            <div className="chart-header__select">
              <Select
                options={metricOptions}
                value={selectedMetric}
                onChange={this.handleChangeMetric}
                placeholder='Metric'
                label='Metric'
                tooltipParams={{
                  label: 'Metric or Key Performance Indicator (KPI) is the measured value that you can use to understand the effectiveness of your campaigns',
                  position: 'bottom-right',
                  auto: false,
                  labelMaxWidth: 304,
                }}
              />
            </div>
          </div>
        </header>

        {
          loading ?
            <div className="chart-block__main _horizontal">
              <SkeletonChart />
            </div> :
            <React.Fragment>
              <div className="chart-block__main" onMouseLeave={this.closeTooltip}>
                <BarWrapper
                  data={formattedData}
                  layout="horizontal"
                  keys={['value']}
                  indexBy="label"
                  barComponent={this.renderCustomBarItem}
                  isInteractive={true}
                  margin={{
                    top: 0,
                    right: 75,
                    bottom: 0,
                    left: 85
                  }}
                  borderRadius={3}
                  padding={0}
                  colorBy="index"
                  colors={["#28bc97"]}
                  axisBottom={{
                    tickSize: 0,
                  }}
                  axisLeft={{
                    tickSize: 0,
                    tickPadding: 12,
                    renderTick: this.renderCustomAxisLeftTick
                  }}
                  theme={{
                    selectedMetric: this.props.selectedMetric,
                  }}
                  enableGridX={false}
                  enableGridY={false}
                />
              </div>
              <p className="chart-block__footnote ml-0 mb-0">Only top 5 are displayed</p>
              {createPortal(this.renderTooltip(), document.body)}
            </React.Fragment>
        }
      </div >
    )
  }
}

const mapState = (state: AppState) => ({
  data: state.reports.data,
  selectedMetric: state.reports.metric,
  selectedDimension: state.reports.dimension,
  loading: state.reports.loading,
});

const mapActions = {
  changeMetric: reportsActions.changeReportMetric,
  changeDimension: reportsActions.changeReportDimension,
  getLambdaReports: reportsActions.getLambdaReports,
};

export default connect(mapState, mapActions)(HorizontalBarChartComponent);
