import { reducerFromMap } from '../../utils/actions';
import { Action } from '../../models/Action';
import { toastConstants } from './constants';

export interface ToastState {
  isOpen: boolean,
  message: string | JSX.Element,
}

const defaultToastState: ToastState = {
  isOpen: false,
  message: '',
};

function open(state: ToastState, action: Action<string | JSX.Element>): ToastState {
  return {
    ...state,
    isOpen: true,
    message: action.payload,
  };
}

function close(): ToastState {
  return {
    isOpen: false,
    message: '',
  };
}

const reducer = reducerFromMap(
  defaultToastState,
  {
    [toastConstants.TOAST_OPEN]: open,
    [toastConstants.TOAST_CLOSE]: close,
  }
);

export const toast = (state: ToastState = defaultToastState, action: Action<any>) => reducer(state, action);
