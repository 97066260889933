import React from 'react';
import { connect } from 'react-redux';
import { DialogContent, Button } from 'factor';
import { AppState } from '../../../../../../../store';
import { Campaign } from '../../../../../../../models/Campaign';
import { AuthState } from '../../../../../../../store/auth/reducer';

interface Props {
  selectedCampaigns: Campaign[];
  authData: AuthState;
  onClose: () => void;
}

class EditDialogContentComponent extends React.Component<Props> {
  editHandler = async (id: number) => {
    const { domainURL, apiToken } = this.props.authData;
    const redirectUrl = domainURL.includes("stage") ? "campaigns-sep.stage.iqm.com" : "campaigns-sep.iqm.com";

    window.open(`
      http://${redirectUrl}/#/login/${btoa(apiToken)}/${btoa(domainURL)}?page=editCampaign&campaign_id=${id}
    `, '_blank');
  };

  componentDidMount() {
    if (this.props.selectedCampaigns.length === 1) {
      this.editHandler(this.props.selectedCampaigns[0].campaignId);
      this.props.onClose();
    }
  };

  render() {
    const { onClose, selectedCampaigns } = this.props;

    const dialogContent = selectedCampaigns.length > 1 
      ? (
        <DialogContent>
          <h3 className="title-card mb-4">Edit Campaign</h3>
          <h5 className="title-card-subtitle mb-4">Please select only one Campaign</h5>
          {selectedCampaigns.length > 1 &&
            <div className="d-flex justify-content-end mt-2">
              <Button
                className="btn-square _conflower-blue _filled"
                onClick={onClose}
              >
                Ok
              </Button>
            </div>
          }
        </DialogContent>
      )
      : null;

    return dialogContent;
  }
}

const mapState = (state: AppState) => ({
  selectedCampaigns: state.table.selectedTableCampaigns,
  authData: state.auth,
});

export const EditDialogContent = connect(mapState)(EditDialogContentComponent);
