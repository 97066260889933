import React from 'react';
import { connect } from 'react-redux';
import { Switch, Tooltip } from 'factor';
import { Option } from '../../../../../models/Option';
import { TableLevels } from '../../../../../store/filter/reducers';
import { AppState } from '../../../../../store';
import { filterActions } from '../../../../../store/filter/actions';
import { campaignGroupsActions } from '../../../../../store/campaignGroups/actions';
import { tableActions } from '../../../../../store/table/actions';

interface Props {
  level: Option;
  changeLevel: (value: Option) => void;
  resetSelectedGroup: () => void;
  clearCampaignsData: () => void;
  getStatuses: () => void;
  clearSelectedCampaigns: () => void;
  className?: string;
}

class ButtonsGroupWrapperComponent extends React.PureComponent<Props> {
  items: Option[] = TableLevels;
  onChangeHandler = (item: Option) => { 
    this.props.changeLevel(item);
    this.props.resetSelectedGroup();
    this.props.clearSelectedCampaigns();
    if (item.value === 'campaigns') {
      this.props.clearCampaignsData();
      this.props.getStatuses();
    }
  };

  render() {
    const { level } = this.props;
    return (
      <Tooltip
        className="filter__btn-group"
        label="Group By table data by Campaigns or Campaign Groups"
      >
        <Switch
          className="_md"
          list={this.items.map(i => i.label)}
          name="levelSwitcher"
          value={level.value==='groups'}
          onChange={(v) => {
            const valueKey = v ? 'groups' : 'campaigns'
            const selectedLevel = this.items.find(i => i.value === valueKey);
            selectedLevel && this.onChangeHandler(selectedLevel);
          }}
        />
      </Tooltip>
    );
  }
}

const mapState = (state: AppState) => {
  return {
    level: state.filter.tableLevel,
  };
};

const mapActions = {
  changeLevel: filterActions.changeTableLevel,
  getStatuses: filterActions.getStatuses,
  resetSelectedGroup: campaignGroupsActions.resetGroup,
  clearCampaignsData: tableActions.clearCampaignsData,
  clearSelectedCampaigns: tableActions.clearSelectedCampaigns,
};
export const ButtonsGroupWrapper = connect(mapState, mapActions)(ButtonsGroupWrapperComponent);
