import React from 'react';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';
import { Dialog } from 'factor';
import { BtnMenu } from './components/BtnMenu';
import { ChangeBudgetsContent } from './components/ChangeBudgetsContent';
import { ReactComponent as ArrowDownIcon } from '../../../../../../svg/arrow-down.svg';
import { AppState } from '../../../../../../store';
import { Campaign } from '../../../../../../models/Campaign';

interface Props {
  selectedCampaigns: Campaign[];
}
interface State {
  btnMenuIsOpen: boolean;
  dialogIsOpen: boolean;
  action: string | null;
}

enum Actions {
  changeBudget = 'total_budget',
  changeDailyBudget = 'daily_budget',
  changeMaxBid = 'max_bid',
}

class BudgetActionsButtonComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props)

    this.state = {
      btnMenuIsOpen: false,
      dialogIsOpen: false,
      action: null,
    };
  }

  toggleBtnMenu = () => {
    const { btnMenuIsOpen } = this.state;
    this.setState({
      btnMenuIsOpen: !btnMenuIsOpen,
    });
  };

  openBtnMenu = () => {
    this.setState({
      btnMenuIsOpen: true,
    });
  };

  closeBtnMenu = () => {
    this.setState({
      btnMenuIsOpen: false,
    });
  };

  closeDialog = () => {
    this.setState({
      dialogIsOpen: false,
    })
  };

  actionHandler = (action) => {
    this.setState({
      btnMenuIsOpen: false,
      dialogIsOpen: true,
      action,
    })
  };

  render() {
    const { selectedCampaigns } = this.props;
    const { btnMenuIsOpen, dialogIsOpen, action } = this.state;
    let dialogContent;

    switch (action) {
      case Actions.changeBudget: {
        const value = selectedCampaigns.length === 1 ? selectedCampaigns[0].budget : '';
        dialogContent = <ChangeBudgetsContent
          label="Total Budget"
          value={value}
          action={Actions.changeBudget}
          onClose={this.closeDialog} />;
        break;
      }
      case Actions.changeDailyBudget: {
        const value = selectedCampaigns.length === 1 ? selectedCampaigns[0].budgetDay : '';
        dialogContent = <ChangeBudgetsContent
          label="Daily Budget"
          value={value}
          action={Actions.changeDailyBudget}
          onClose={this.closeDialog} />;
        break;
      }
      case Actions.changeMaxBid: {
        const value = selectedCampaigns.length === 1 ? selectedCampaigns[0].bid : '';
        dialogContent = <ChangeBudgetsContent
          label="Max Bid"
          value={value}
          action={Actions.changeMaxBid}
          onClose={this.closeDialog} />;
        break;
      }
      default: {
        dialogContent = null;
      }
    }

    const dialog = <Dialog
      open={dialogIsOpen}
      onClickOutside={this.closeDialog}>
      {dialogContent}
    </Dialog>;

    return (
      <React.Fragment>
        <BtnMenu
          isOpen={btnMenuIsOpen}
          open={this.openBtnMenu}
          close={this.closeBtnMenu}
          className="filter__btn"
          tooltipParams={{
            label: 'Change Total Budget, Daily Budget and Max Bid for the selected Campaigns',
          }}
          content={(
            <div className="btn-select">
              <button
                className="btn-select__title"
                onClick={this.closeBtnMenu}
              >
                Budget
              </button>
              <div className="btn-select__buttons">
                <button
                  className="btn-select__btn"
                  onClick={() => this.actionHandler(Actions.changeBudget)}
                >
                  Total Budget
                  </button>
                <button
                  className="btn-select__btn"
                  onClick={() => this.actionHandler(Actions.changeDailyBudget)}
                >
                  Daily Budget
                  </button>
                <button
                  className="btn-select__btn"
                  onClick={() => this.actionHandler(Actions.changeMaxBid)}
                >
                  Max Bid
                  </button>
              </div>
            </div>
          )}
        >
          <span className="btn-round__prefix">
            <ArrowDownIcon />
          </span>
          Set Budget
        </BtnMenu>
        {createPortal(dialog, document.body)}
      </React.Fragment>
    );
  }
}

const mapState = (state: AppState) => ({
  selectedCampaigns: state.table.selectedTableCampaigns,
});

export const BudgetActionsButton = connect(mapState)(BudgetActionsButtonComponent);
