import axios, { AxiosInstance } from 'axios';
import { AuthService } from 'iqm-framework';

import { login } from './Auth';
import { getProfile, getAvatar, getAvailableBalance } from './Profile';
import { getTotalResult } from './Statistics';
import { getGraphData } from './Graphs';
import {
  campaignsCount,
  getTableData,
  getCampaignDetails,
  updateCampaign,
  updateCampaignCompletely,
  updateCampaignBudget,
  getTableDataFromGroup,
  duplicateCampaigns,
  pauseCampaign,
  deleteCampaign,
  updateEndTime,
  runCampaign,
  restoreCampaign
} from './Campaigns';
import { getCampaignsList } from './CampaignsList';
import { getCampaignTypes } from './CampaignTypes';
import { createCampaignGroup, getCampaignsGroupsWithChildren, getStatuses } from './CampaignGrops';
import { getAdvertisers } from './Advertisers';
import { localStorageService } from '../services/localStorage';
import { HEADER_TOKEN_NAME } from '../config';
import { getTimezones } from './Timezone';
import { downloadCSV } from './ReportDownload';
import { getReportsData, getReportsTypes } from './Reports';
import { getData, updateGroups, getGroupsWithCampaigns, deleteGroup } from './FilteredCampaignGroups';

let iqmInstance: AxiosInstance;

export interface InstanceConfig {
  baseURL: string,
  token: string,
}

export function createIqmInstance(config: InstanceConfig) {
  const instance = axios.create({
    ...config,
    baseURL: `https://${config.baseURL}/api`,
    headers: {
      [HEADER_TOKEN_NAME]: config.token,
    },
  });

  instance.interceptors.response.use((response: any) => {
    return response;
  }, (response: any) => {
    if (axios.isCancel(response)) {
      const res = {
        response: {
          data: response,
        }
      };

      throw res;
    }
    if (response.response.status === 401) {
      AuthService.logout();
    }
    return Promise.reject(response);
  });

  iqmInstance = instance;
}

export function getInstance(): AxiosInstance {
  return iqmInstance || axios;
}

export const API = {
  auth: {
    login,
  },
  profile: {
    getProfile,
    getAvatar,
    getAvailableBalance,
  },
  statistics: {
    getTotalResult,
  },
  graph: {
    getGraphData,
  },
  campaigns: {
    getTableData,
    getCampaignDetails,
    updateCampaign,
    updateCampaignCompletely,
    updateCampaignBudget,
    campaignsCount,
    getTableDataFromGroup,
    duplicateCampaigns,
    pauseCampaign,
    deleteCampaign,
    updateEndTime,
    runCampaign,
    restoreCampaign
  },
  campaignsList: {
    getCampaignsList,
  },
  campaignGroups: {
    getCampaignsGroupsWithChildren,
    getStatuses,
    createCampaignGroup,
  },
  campaignTypes: {
    getCampaignTypes,
  },
  advertisers: {
    getAdvertisers,
  },
  timezone: {
    getTimezones,
  },
  report: {
    downloadCSV,
  },
  reports: {
    getReportsData,
    getReportsTypes,
  },
  FilteredCampaignGroups: {
    getData,
    updateGroups,
    getGroupsWithCampaigns,
    deleteGroup,
  },
};
