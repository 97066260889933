import React from 'react';
import '../styles/_skeleton-chart.scss';

export const SkeletonChart = () => {
  return (
    <div className="skeleton-chart">
      <svg viewBox="0 0 415 130" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.5,65.5 L35.4667469,35.0648813 C74.2151885,-4.28918743 137.529783,-4.78014525 176.883852,33.9682963 C177.432334,34.5083381 177.974563,35.0546945 178.51043,35.6072564 L207.5,65.5 L235.148957,93.9086823 C272.935647,132.733657 334.768073,134.406247 374.598428,97.6808417 L409.5,65.5"
          id="Line" stroke="#E8E8E8" strokeWidth="10" strokeLinecap="round" fill="none"/>
      </svg>
    </div>
  );
};
