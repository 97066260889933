import React from 'react';

export default function CustomBarChartTickProps(props) {
  const {
    x,
    y,
    value,
    textX,
    textY,
    textBaseline,
    textAnchor,
    rotate,
  } = props.tick;

  const {
    onMouseLeave,
    onMouseMove,
    onMouseEnter
  } = props;

  let formattedValue = value;
  if (formattedValue.length > 10) {
    formattedValue = `${value.slice(0, 9)}...`;
  } else {
    formattedValue = value.slice(0, -1);
  }

  return (
    <g key={value} transform={`translate(${x},${y})`}>
      <text
        dominantBaseline={textBaseline}
        onMouseLeave={onMouseLeave}
        onMouseMove={onMouseMove}
        onMouseEnter={onMouseEnter}
        textAnchor={textAnchor}
        transform={`translate(${textX},${textY}) rotate(${rotate})`}
        style={{
          fill: "#000000",
          fontFamily: "Lato Regular, sans-serif",
          fontSize: 12,
        }}
      >
        {formattedValue}
      </text>
    </g>
  );
}
