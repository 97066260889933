import { getInstance } from './index';
import { CampaignCreateParams } from '../models/CampaignGroup';

export function getCampaignsGroupsWithChildren(params: { advertiserId: number, dspId: number }) {
  return getInstance()
    .get('v2/adv/campaigngroup/list', {
      params,
    })
    .then(response => response.data)
    .catch(err => {
      console.log('err', err);
      return Promise.reject(err)
    });
}

export function getStatuses(params: { advertiserId: number, dspId: number }, id: number) {
  return getInstance()
    .get(`v2/adv/campaigngroup/${id}/campaigns/status/count`, {
      params,
    })
    .then(response => response.data.responseObject)
    .catch(err => {
      console.log('cant\'t fetch groups statuses', err);
      return Promise.reject(err);
    });
}

export function createCampaignGroup(data: CampaignCreateParams) {
  return getInstance()
    .post('/v2/adv/campaigngroup', data)
    .then(res => res.data)
    .catch(err => {
      console.log('Error create campaign group', err);
      return Promise.reject(err);
    });
}
