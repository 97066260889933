import { GraphDTO, GraphModelValue, GraphObjectDTO, GraphLambdaResponse, Graph1DefaultMetricOption, Graph2DefaultMetricOption } from '../../models/Graph';
import { reducerFromMap } from '../../utils/actions';
import { graphConstants } from './constants';
import { Action } from '../../models/Action';
import { Option } from '../../models/Option';
import { CancellarError } from '../../models/Response';

export enum GraphsNames {
  graph1 = 'graph1',
  graph2 = 'graph2',
}

export interface GraphState {
  loading: boolean;
  data: GraphLambdaResponse[];
}

const defaultGraphState: GraphState = {
  loading: false,
  data: [],
};

function convert(arr: GraphDTO): GraphModelValue {
  if (!arr || !arr.length) {
    return {
      id: '',
      data: [],
    };
  }
  const data: GraphObjectDTO = arr[0];
  return {
    id: data.key,
    data: data.values,
  };
}

function graphDataStartLoading(state: GraphState): GraphState {
  return {
    ...state,
    loading: true,
  };
}

function graphDataLoaded(state: GraphState, action: Action<{ data: GraphDTO, graph: GraphsNames }>): GraphState {
  return {
    ...state,
    [action.payload.graph]: convert(action.payload.data),
    loading: false,
  };
}

function graphDataError(state: GraphState, action: Action<CancellarError<Response>>): GraphState {
  return {
    ...state,
    loading: action.payload.response ? !action.payload.response.status : false,
  };
}

function graphDataLambdaLoaded(state: GraphState, action: Action<GraphLambdaResponse[]>): GraphState {
  return {
    ...state,
    loading: false,
    data: action.payload,
  };
}

const reducer = reducerFromMap(
  defaultGraphState,
  {
    [graphConstants.LOADING_GRAPH__START]: graphDataStartLoading,
    [graphConstants.LOADING_GRAPH__SUCCESS]: graphDataLoaded,
    [graphConstants.LOADING_GRAPH__ERROR]: graphDataError,
    [graphConstants.LAMBDA_LOADING_GRAPH__SUCCESS]: graphDataLambdaLoaded,
  }
);

export const graph = (state: GraphState, action: Action<any>) => reducer(state, action);
