import { OptionID } from './Option';

export interface CampaignGroupDTO {
  name: string;
  id: number;
  campaigns: CampaignGroupDTO[];
  campaignCount: number;
  created?: number;
  lastModified?: number;
}

export interface CampaignGroup {
  name: string;
  id: number;
  children: CampaignGroup[];
  campaignCount: number;
  created?: number;
  lastModified?: number;
}

export interface GroupsList {
  value: number;
  label: string;
  options?: OptionID[];
}

export interface CampaignCreateParams {
  dspId: number;
  advertiserId: number;
  name: string;
  parentId: string;
}

export const convertSingleCampaignGroupDTOtoCampaignGroup = (group: CampaignGroupDTO): GroupsList => {
  return {
    value: group.id,
    label: group.name,
    options: group.campaigns && group.campaigns.map((campaign, index) => ({
      value: +`${group.id}${campaign.id}${index}`,
      id: campaign.id,
      label: campaign.name,
    })),
  }
}

export const convertCampaignGroupDTOtoCampaignGroup = (groups: CampaignGroupDTO[]): GroupsList[] => {
  return groups.map(convertSingleCampaignGroupDTOtoCampaignGroup);
};
