import React from 'react';
import { connect } from 'react-redux';
import { DialogContent, Button } from 'factor';
import { AppState } from '../../../../../../../store';
import { TableComponentInstance } from '../../../Table';
import { tableActions } from '../../../../../../../store/table/actions';
import { LambdaResponse } from '../../../../../../../models/Response';
import { toastActions } from '../../../../../../../store/toast/actions';
import { pluralize } from '../../../../../../../utils/pluralize';
import { filterActions } from '../../../../../../../store/filter/actions';

interface Props {
  selectedCampaigns: LambdaResponse[];
  onClose: () => void;
  clearSelected: () =>void;
  pause: () => Promise<void>;
  openToast: (name: string) => void;
  getStatuses: () => void;
};

class PauseDialogContentComponent extends React.Component<Props> {
  constructor(props) {
    super(props);
  }

  pauseHandler = () => {
    const { onClose, pause, openToast, getStatuses, selectedCampaigns } = this.props;
    pause()
      .then(() => {
        TableComponentInstance.setState({
          selected: [],
          loading: true,
        });
        TableComponentInstance.getNewData();
        onClose();
        getStatuses();
        const toastMessageCampaign = selectedCampaigns.length > 1 ? 'Campaigns': 
          `Campaign ${selectedCampaigns[0].campaignName}`;
        openToast(`${toastMessageCampaign} status changed`);
      })
      .catch(e => {
        openToast(e);
        onClose();
      });
  }
  
  render() {
    const { onClose, selectedCampaigns } = this.props;

    return (
      <DialogContent>
        <h3 className="title-card mb-4">Pause {pluralize('Campaign', selectedCampaigns.length)}?</h3>
        {selectedCampaigns.length === 1 
          ? <h5 className="title-card-subtitle mb-4">Pause the <span className="_black">{selectedCampaigns[0].campaignName}</span> campaign?</h5>
          : <h5 className="title-card-subtitle mb-4">Pause the <span className="_black">{selectedCampaigns.length}</span> campaigns?</h5>
        } 
        <div className="d-flex justify-content-end mt-2">
          <Button
            className="btn-square _conflower-blue mr-2"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            className="btn-square _conflower-blue _filled"
            onClick={this.pauseHandler}
          >
            Pause
          </Button>
        </div>
      </DialogContent>
    );
  }
}

const mapState = (state: AppState) => ({
  selectedCampaigns: state.table.selectedTableCampaigns,
});

const mapActions = {
  clearSelected: tableActions.clearSelectedCampaigns,
  pause: tableActions.pauseCampaigns,
  openToast: toastActions.open,
  getStatuses: filterActions.getStatuses,
}

export const PauseDialogContent = connect(mapState, mapActions)(PauseDialogContentComponent);
