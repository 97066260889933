import { Dispatch } from "redux";
import moment from 'moment';
import { lambdaAPI } from '../../api/lambdaAPI/index';
import { AppState } from "../";
import { createAction } from "../../utils/actions";
import { Dashboard, DashboardData, convertFilterToDashboardData } from "../../models/Dashboards";
import { dashboardConstants } from "./constants";
import { Option } from "../../models/Option";
import { tableConstants } from "../table/constants";
import { filterConstants } from "../filter/constants";
import { FilterState, defaultFilterState } from "../filter/reducers";
import { reportsConstants } from "../reports/constants";
import { ReportsState } from "../reports/reducers";

export const dashboardsActions = {
  createDashboard: (name: string) => {
    return async (dispatch: Dispatch<any>, getState: () => AppState) => {
      const state = getState();
      const { reports, filter, table } = state;
      const value = Date.now();

      const newDashboard: Dashboard = {
        value,
        label: name,
        id: '',
        data: convertFilterToDashboardData({
          ...filter,
          dimension: reports.dimension,
          metric: reports.metric,
          filteredCampaignsIds: table.filteredCampaignsIds,
        }),
      };

      try {
        const response = await lambdaAPI.dashboards.create(newDashboard);
        dispatch(createAction<Dashboard>(dashboardConstants.CREATE_DASHBOARD, response));
        return Promise.resolve(response);
      } catch(e) {
        console.log('[dashboard had not created]', e);
        return Promise.reject(e);
      }
    };
  },

  getDashboards: () => {
    return async (dispatch: Dispatch<any>) => {
      try {
        createAction<void>(dashboardConstants.DASHBOARD_LOADING_START);
        const response = await lambdaAPI.dashboards.get();
        dispatch(createAction<Dashboard[]>(dashboardConstants.GET_DASHBOARDS, response));
        dispatch(createAction<void>(dashboardConstants.DASHBOARD_LOADING_END));
      } catch(e) {
        console.log('[dashboards had not fetched]', e);
        dispatch(createAction<void>(dashboardConstants.DASHBOARD_LOADING_END));
        return Promise.reject();
      }
    };
  },

  updateDashboard: () => {
    return async (dispatch: Dispatch<any>, getState: () => AppState) => {
      const state = getState();
      const { filter, reports, table } = state
      const { selectedDashboard } = state.dashboards;

      if (selectedDashboard) {
        const updatedDashboard: Dashboard = {
          value: selectedDashboard.value,
          label: selectedDashboard.label,
          id: selectedDashboard.id,
          data: convertFilterToDashboardData({
            ...filter,
            dimension: reports.dimension,
            metric: reports.metric,
            filteredCampaignsIds: table.filteredCampaignsIds,
          }),
        };

        try {
          await lambdaAPI.dashboards.update(selectedDashboard.id, updatedDashboard);
          dispatch(createAction<Dashboard>(dashboardConstants.UPDATE_DASHBOARD, updatedDashboard));
          return Promise.resolve(updatedDashboard);
        } catch(e) {
          console.log('[dashboard had not updated]', e);
          return Promise.reject(e);
        }
      }
    };
  },

  deleteDashboard: (dashboard: Dashboard) => {
    return async (dispatch: Dispatch<any>, getState: () => AppState) => {
      try {
        await lambdaAPI.dashboards.remove(dashboard.id);
        dispatch(createAction<Dashboard>(dashboardConstants.DELETE_DASHBOARD, dashboard));
        return Promise.resolve(dashboard);
      } catch(e) {
        console.log('[dashboard had not deleted]', e);
        return Promise.reject(e);
      }
    }
  },

  selectDashboard: (dashboard: Dashboard | null) => {
    return (dispatch: Dispatch<any>) => {
      if (dashboard) {
        const { dimension, metric } = dashboard.data;
        dispatch(createAction<number[]>(tableConstants.TABLE_SET_FILTERED_CAMPAIGNS_IDS, dashboard.data.filteredCampaignsIds));
        dispatch(createAction<Dashboard>(dashboardConstants.SELECT_DASHBOARD, dashboard));
        dispatch(createAction<DashboardData>(filterConstants.UPDATE_FILTERS, dashboard.data));
        dimension && dispatch(createAction<Option>(reportsConstants.CHANGE_DIMENSION, dimension));
        dispatch(createAction<Option>(reportsConstants.CHANGE_METRIC, metric));

      } else {
        dispatch(createAction<string>(dashboardConstants.CLEAR_SELECTED_DASHBOARD));
      }
    }
  },

  clearSelectedDashboard: () => {
    return (dispatch: Dispatch<any>) => {
      dispatch(createAction<string>(dashboardConstants.CLEAR_SELECTED_DASHBOARD));
    }
  },
}

export interface CreateDashboard {
  createDashboard: (name: string) => Promise<any>;
}

export interface GetDashboards {
  getDashboards: () => void;
}

export interface UpdateDashboard {
  updateDashboard: () => Promise<any>;
}

export interface DeleteDashboard {
  deleteDashboard: (dashboard: Dashboard) => Promise<any>;
}

export interface SelectDashboard {
  selectDashboard: (dashboard: Dashboard) => void;
}

export interface ClearSelectedDashboard {
  clearSelectedDashboard: () => void;
}
