import { getInstance } from './index';
import axios, { Canceler, AxiosResponse } from 'axios';
import { GetFilteredGroupsParams, UpdateFilteredGroupsParams } from '../models/Params';
import { UpdateBudgetResponse } from '../models/Response';

let cancel: Canceler;

export function getData(params: GetFilteredGroupsParams): Promise<any> {
  if (cancel) {
    cancel('Canceled by the user');
  }
  return getInstance()
    .get('/v2/adv/campaigngroup/campaign', {
      params,
      cancelToken: new axios.CancelToken((c) => {
        cancel = c;
      }),
    })
    .then(response => response.data)
    .catch(err => {
      console.log('err', err);
      return Promise.reject(err);
    });
}

export function updateGroups(reqBody: UpdateFilteredGroupsParams): Promise<any> {
  return getInstance()
    .post(`/v2/adv/campaigngroup/add-campaign`, reqBody)
    .then(response => response.data)
    .catch(err => {
      console.log('err', err);
      return Promise.reject(err);
    });
}

let groupCancel: Canceler;
export function getGroupsWithCampaigns(params): Promise<UpdateBudgetResponse<any>> {
  if (groupCancel) {
    groupCancel('Canceled by the user');
  }
  return getInstance()
    .get('/v2/adv/campaigngroup/campaign/list', {
      params,
      cancelToken: new axios.CancelToken((c) => {
        groupCancel = c;
      }),
    })
    .then(response => response.data)
    .catch(err => Promise.reject(err));
}

export function deleteGroup(params): Promise<AxiosResponse> {
  return getInstance()
    .delete('/v2/adv/campaigngroup/remove', { params })
    .then(res => res)
    .catch(err => Promise.reject(err));
}
