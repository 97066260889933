import React from 'react';
import moment from 'moment';
import { TimezoneProvider, TimezonePicker, EpochDateRangePicker } from 'iqm-framework';
import { connect } from 'react-redux';
import { Option, OptionID } from '../../../models/Option';
import { AppState } from '../../../store';
import { filterActions, SelectTimezone } from '../../../store/filter/actions';
import { DateRange } from '../../../store/filter/reducers';
import { commonActions } from '../../../store/common/actions';
import { Dashboard } from '../../../models/Dashboards';
import '../../../components/dropdown/styles.scss';
import { AuthState } from '../../../store/auth/reducer';

interface Props extends SelectTimezone {
  isAuth: boolean;
  className?: string;
  dateRange: DateRange;
  defaultDateRangeWasUpdated: Boolean;
  timezone: OptionID;
  timezones: OptionID[];
  dashboard: Dashboard;
  profile: AuthState;
  getRelatedData: () => void;
  setDateRange: (item: DateRange) => void;
  setInitialDateRange: (item: DateRange) => void;
}

interface PreselectedValues {
  key: string;
  title: string;
  start: (tz: string) => moment.Moment;
  end: (tz: string) => moment.Moment;
}

class DatesContainerComponent extends React.Component<Props> {
  constructor(props) {
    super(props);
  }

  preselected: PreselectedValues[] = [
    EpochDateRangePicker.PRESELECT_KEYS.TODAY,
    EpochDateRangePicker.PRESELECT_KEYS.YESTERDAY,
    EpochDateRangePicker.PRESELECT_KEYS.LAST_WEEK,
    EpochDateRangePicker.PRESELECT_KEYS.LAST_MONTH,
    EpochDateRangePicker.PRESELECT_KEYS.LAST_THREE_MONTHS,
  ];

  componentDidMount = () => {
    const { isAuth } = this.props;
    if (isAuth) {
      this.loadData();
    }
  };

  handleDateChange = ({ startDate: start, endDate: end }) => {
    const isInitialDateRange = !this.props.dateRange;
    const prevStart = this.props.dateRange ? this.props.dateRange.start : null;
    const prevEnd = this.props.dateRange ? this.props.dateRange.end : null;

    const range = {
      start: moment(start),
      end: moment(end),
    };

    isInitialDateRange ? this.props.setInitialDateRange(range) : this.props.setDateRange(range);

    if ((prevStart !== start) || (prevEnd !== end)) {
      this.loadData();
    }
  };

  loadData() {
    this.props.getRelatedData();
  };

  sortTimezones = () => {
    const { timezones } = this.props;
    const utcTimezone: Option[] = [];
    const usTimezones: Option[] = [];
    const othersTimezones: Option[] = [];

    timezones.forEach((tz) => {
      if (tz.label.includes('US/')) {
        usTimezones.push(tz);
      } else if (tz.value === 11) {
        utcTimezone.push(tz);
      } else {
        othersTimezones.push(tz);
      }
    });

    return [
      ...usTimezones,
      ...utcTimezone,
      ...othersTimezones,
    ];
  };

  getDefaultDateRange = () => {
    return {
      getStartDate: tz => {
        const startDate = moment.tz(moment().utc(), 'UTC').tz(tz).startOf('day');
        return moment.tz(startDate.format('YYYY-MM-DD HH:mm:ss'), moment.tz.guess());
      },
      getEndDate: tz => {
        const startDate = moment.tz(moment().utc(), 'UTC').tz(tz).endOf('day');
        return moment.tz(startDate.format('YYYY-MM-DD HH:mm:ss'), moment.tz.guess());
      },
    };
  };

  render() {
    const { className, timezone, timezones, selectTimezone, profile, dashboard, defaultDateRangeWasUpdated, dateRange } = this.props;

    if (!timezone && !dashboard && profile.timezoneId && timezones.length) {
      const userTimezone = timezones.find(tz => tz.id === profile.timezoneId);
      if (userTimezone) {
        selectTimezone(userTimezone);
      }
    }

    const dateRangeNew = (defaultDateRangeWasUpdated && dateRange) ? {
      startDate: dateRange.start.valueOf(),
      endDate: dateRange.end.valueOf(),
    } : null;

    return (
      <TimezoneProvider timezone={timezone}>
        <div className={className}>
          <div className="dashboard-header__timezone-picker">
            <TimezonePicker
              onTimezoneChange={selectTimezone}
              tooltipParams={{
                label: 'Dashboard days will start at 12 am and end at 12 am in the selected timezone',
                auto: false,
                position: 'bottom',
              }}
            />
          </div>
          <EpochDateRangePicker
            dateRange={dateRangeNew}
            dateFormat="MM/DD/YYYY"
            onDateRangeChange={this.handleDateChange}
            datePickerProps={{
              numberOfCalendars: 2,
              singleDateRange: true,
            }}
            preselected={this.preselected}
            insidePreselectors
            defaultDateRange={this.getDefaultDateRange()}
            tooltipParams={{
              label: 'Timeframe for the data to include in the Dashboard',
              auto: false,
              position: 'left',
            }}
          />
        </div>
      </TimezoneProvider>
    );
  }
}

const mapState = (state: AppState) => ({
  dateRange: state.filter.dateRange,
  timezone: state.filter.timezone,
  timezones: state.filter.timezones,
  dashboard: state.dashboards.selectedDashboard,
  profile: state.auth,
  defaultDateRangeWasUpdated: state.filter.defaultDateRangeWasUpdated,
  isAuth: state.auth.authorized,
});

const mapAction = {
  setDateRange: filterActions.setDateRange,
  setInitialDateRange: filterActions.setInitialDateRange,
  selectTimezone: filterActions.selectTimezone,
  getRelatedData: commonActions.getRelatedData,
};

export const DatesContainer = connect(mapState, mapAction)(DatesContainerComponent);
