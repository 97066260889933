import { Response } from './Response';

export enum StatFormat {
  currency = 'currency',
  percent = 'percent',
}
export interface Stat {
  title: string;
  value?: number | string;
  format?: StatFormat;
  prevValue?: number | string;
}

export interface StatisticsTotalResult {
  impressions: number;
  eCPM: number;
  eCPC: number;
  clicks: number;
  spent: number;
  eCTR: number;
  winRate: number;
  mediaSpent: number;
  // actual_spent: number;
  // conversions: number;
  // eCPI: number;
  // eCVR: number;
  // earning: number;
}

export interface StatisticReportModel {
  click: number;
  conversion: number;
  data_cost: number;
  eCPC: number;
  eCPI: number;
  eCPM: number;
  eCTR: number;
  eCVR: number;
  impression: number;
  spent: number;
  total_cost: number;
  winRate: number;
}

export type StatisticsReportDTO = Response<StatisticReportModel> | null;
