import React, { Fragment } from 'react';

export interface DialogContentData {
  prev: string;
  value: string;
}

interface Props extends DialogContentData {
  onChange: (newValue: string) => void;
  campaignName: string;
  campaignTimezone?: string;
}

const DialogDateContentWrapperComponent = (props: Props) => {
  const { onChange, prev, value, campaignName } = props;

  return (
    <React.Fragment>
      <div className="dialog__input mt-2 mb-2">
        <div>
          Changing End Date for Campaign <b>{campaignName}</b> 
          <div className="changing-date">
            {prev && (
              <Fragment>
                from&nbsp;<b>{prev}</b>
              </Fragment>
            )} 
            &nbsp;to&nbsp;
            <span className="new-end-date">{value}</span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export const DialogDateContentWrapper = DialogDateContentWrapperComponent; 
