import React from 'react';
import { connect } from 'react-redux';
import { DialogContent, Button, ButtonsGroup, TextField, Icon, Tooltip } from 'factor';
import get from 'lodash/get';
import { AppState } from '../../../../../../../store';
import { Campaign } from '../../../../../../../models/Campaign';
import { toastActions } from '../../../../../../../store/toast/actions';
import { TableComponentInstance } from '../../../Table'
import { tableActions, UpdateCampaignsBudget, SetCampaigns } from '../../../../../../../store/table/actions';
import { pluralize } from '../../../../../../../utils/pluralize';
import { ToastContent } from '../../../../../../../components/toastContent/ToastContent';
import { LambdaResponse } from '../../../../../../../models/Response';
import './changeBudgetDialogContent/style.scss';
import { statisticsActions, GetTotal } from '../../../../../../../store/statistics/actions';

interface Props extends UpdateCampaignsBudget, SetCampaigns, GetTotal {
  selectedCampaigns: Campaign[];
  action: string;
  label: string;
  value?: string | number;
  data: LambdaResponse[];
  onClose: () => void;
  openToast: (message: string | JSX.Element) => void;
};

interface State {
  value: string;
  totalBudgetOption: 'change' | 'addition' | 'distribution';
}

const budgetMap = {
  max_bid: 'maxBid',
  daily_budget: 'dailyBudget',
  total_budget: 'totalBudget',
};

const totalBudgetOptionsForOneSelectedCampaign = [
  { title: '= Set', value: 'change' },
  { title: '+ Add', value: 'addition' },
];

const totalBudgetOptions = [
    ...totalBudgetOptionsForOneSelectedCampaign,
  { title: '÷ Split', value: 'distribution' },
];

const TOOLTIP_MESSAGE = 'Set exact budget, Add more to existing or Split across selected campaigns';

class ChangeBudgetsContentComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value || '',
      totalBudgetOption: 'change',
    };
  }

  editHandler = async () => {
    const { openToast, onClose, action, label, updateCampaignsBudget, data, setCampaigns } = this.props;
    const { value, totalBudgetOption } = this.state;
    let toastMessage = `${label.charAt(0).toUpperCase() + label.slice(1)} has been changed`;

    try {
      const response = await updateCampaignsBudget({
        budgetType: budgetMap[action],
        value: +value,
        distributionMethod: totalBudgetOption,
      });
      if (get(response, 'responseObject.modified_data', null)) {
        const modifiedData = get(response, 'responseObject.modified_data', [])
          .reduce((acc, campaign) => {
            acc[campaign.campaignId] = {};
            for (let key in campaign) {
              acc[campaign.campaignId.toString()][key] = campaign[key].toString();
            }
            return acc;
          }, {});
        const modifiedDataIds: string[] = Object.keys(modifiedData);
        data.forEach(campaignData => {
          if (modifiedDataIds.includes(campaignData.campaignId)) {
            campaignData = Object.assign(campaignData, modifiedData[campaignData.campaignId]);
          }
          return campaignData;
        });

        setCampaigns(data);
        TableComponentInstance.setState({ data });
        onClose();
      }
      
      if (!get(response, 'responseObject.status', null)) {
        const errorsMapper = response.responseObject.reason.reduce((acc, i) => {
          const findedIndex = acc.findIndex(a => a.errorMessage === i.errorMessage);
          findedIndex > -1 ? acc[findedIndex].id.push(i.id) : acc.push({
            errorMessage: i.errorMessage,
            id: [i.id],
          })
          return acc;
        }, []);

        const toastMessages = errorsMapper.map(e => {
          const { errorMessage, id } = e;
          return `${pluralize('Campaing', id.length, {
            single: `Campaign with id ${id.join(', ')} is`,
            multiple: `Campaigns with id ${id.join(', ')} are`,
          })} not updated! ${errorMessage ? 'Reason: ' + errorMessage : ''}`;
        });

        openToast(<ToastContent messages={toastMessages} />);
      } else {
        openToast(toastMessage);
        this.props.getTotal()
          .then(res => {
            TableComponentInstance && TableComponentInstance.setState({ totalData: [{ ...res, total: true }] });
          });
      }
    } catch (e) {
      let errorMessage = 'Error updating budget';
      const error = get(e, 'responseObject.errorMsg', null);
      if (error) {
        errorMessage = error;
      }
      openToast(errorMessage);
      onClose();
    }
  };

  render() {
    const { onClose, selectedCampaigns, label, action } = this.props;
    const { value, totalBudgetOption } = this.state;

    const isOnlyOneCampaignSelected = selectedCampaigns.length === 1;

    return (
      <DialogContent>
        <h3 className="title-card mb-4">Change {label}</h3>
        {(isOnlyOneCampaignSelected)
          ? <h5 className="title-card-subtitle mb-4">
            Enter the
            <span className="_black"> {label} </span>
            for the
            <span className="_black"> {selectedCampaigns[0].campaignName} </span>
            campaign.</h5>
          : <h5 className="title-card-subtitle mb-4">
            Enter the
            <span className="_black"> {label} </span>
            for the
            <span className="_black"> {selectedCampaigns.length} </span>
            campaigns.</h5>
        }
        <div className="d-flex mb-4 align-items-end budget-dialog-content">
          <div className="pl-0 pr-0 col-4 mr-3">
            <TextField
              name="edit budget"
              label={`New ${label}`}
              type="amount"
              onChange={(value: string) => {
                this.setState({value});
              }}
              value={value}
            />
          </div>
          {action === 'total_budget' &&
            <React.Fragment>
              <div className="mr-3">
                <ButtonsGroup
                  size="sm"
                  items={isOnlyOneCampaignSelected ? totalBudgetOptionsForOneSelectedCampaign : totalBudgetOptions}
                  value={totalBudgetOption}
                  onChange={v => this.setState({ totalBudgetOption: v })}
                />
              </div>
              <Tooltip label={TOOLTIP_MESSAGE}>
                <Icon name="Question" className="mr-3 mb-1" />
              </Tooltip>
            </React.Fragment>
          }
        </div>
        <div className="d-flex justify-content-end mt-2">
          <Button
            className="btn-square _conflower-blue mr-2"
            onClick={onClose}>
            Cancel
          </Button>
          <Button
            className="btn-square _conflower-blue _filled"
            onClick={() => this.editHandler()}
            disabled={!value.length}>
            Change
          </Button>
        </div>
      </DialogContent>
    );
  }
}

const mapState = (state: AppState) => ({
  selectedCampaigns: state.table.selectedTableCampaigns,
  data: state.table.data,
});

const mapActions = {
  openToast: toastActions.open,
  updateCampaignsBudget: tableActions.updateCampaignsBudget,
  setCampaigns: tableActions.setCampaigns,
  getTotal: statisticsActions.getTotal,
}

export const ChangeBudgetsContent = connect(mapState, mapActions)(ChangeBudgetsContentComponent);
