export const CurrencyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
export const PercentFormat = new Intl.NumberFormat('en-US', { style: 'percent' });
export const NumberFormat = new Intl.NumberFormat('en-US');

export enum TextFormat {
  currency = 'currency',
  percent = 'percent',
  number = 'number',
  date = 'date',
}

export const DateFormat = 'MM/DD/YYYY';
export const DateTimeFormat = 'MM/DD/YYYY kk:mm';
export const DateTime12HFormat = 'MM/DD/YYYY hh:mm A';

const numberRanges = [
  { divider: 1e12, suffix: 'T' },
  { divider: 1e9, suffix: 'B' },
  { divider: 1e6, suffix: 'M' },
  { divider: 1e3, suffix: 'k' },
];

export const formatNumberWithSuffix = (number: number | string, alwaysFixed=false) => {
  const parsedNumber = +number;

  if (!alwaysFixed && (number < 1000)) {
    return parsedNumber;
  }

  for (let i = 0; i < numberRanges.length; i++) {
    if (parsedNumber >= numberRanges[i].divider) {
      return (parsedNumber / numberRanges[i].divider).toFixed(1).toString() + numberRanges[i].suffix;

    }
  }
  return parsedNumber.toFixed(1).toString();
};
