import React from 'react';
import { Button, Tooltip } from 'factor';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import moment from 'moment';
import { AppState } from '../../../store';
import { toastActions, Open } from '../../../store/toast/actions';
import { Dashboard, convertFilterToDashboardData } from '../../../models/Dashboards';
import { dashboardsActions, UpdateDashboard } from '../../../store/dashboards/actions';
import { FilterState, filter } from '../../../store/filter/reducers';
import { Option } from '../../../models/Option';
import { TemplateNameModal } from '../../../components/header/components/TemplateNameModal';

interface Props extends Open, UpdateDashboard {
  className?: string;
  selectedDashboard: Dashboard | null;
  filterState: FilterState;
  dimension: Option | null;
  metric: Option;
  isNew: boolean;
  filteredCampaignsIds: number[];
}

interface State {
  showModal: boolean;
}

class CreateTemplateContainerComponent extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
    };
  }

  saveDashboard = () => {
    const { open, updateDashboard, selectedDashboard, isNew } = this.props;
    if (selectedDashboard) {
      updateDashboard()
        .then(response => open(`${response.label} has been saved!`))
        .catch(() => open(`${selectedDashboard.label} hasn't been saved!`));
    } else if (isNew) {
      this.setState({
        showModal: true,
      });
    }
  };

  toggleSaveModal = () => {
    this.setState((state) => {
      return {
        showModal: !state.showModal,
      };
    });
  };

  render() {
    const {
      className,
      selectedDashboard,
      filterState,
      dimension,
      metric,
      isNew,
      filteredCampaignsIds,
    } = this.props;

    const { showModal } = this.state;

    let dashboardWasUpdated = false;

    if (selectedDashboard && filterState.dateRange) {
      const formatedFilterState = {
        ...convertFilterToDashboardData({ ...filterState, metric, dimension, filteredCampaignsIds }),
        dateRange: filterState.dateRange ? {
          start: moment(filterState.dateRange.start).unix(),
          end: moment(filterState.dateRange.end).unix(),
        } : null,
      }
      const formatedSelectedDashboardData = {
        ...selectedDashboard.data,
        dateRange: selectedDashboard.data.dateRange ? {
          start: moment(selectedDashboard.data.dateRange.start).unix(),
          end: moment(selectedDashboard.data.dateRange.end).unix(),
        } : null,
      };

      dashboardWasUpdated = !isEqual(omitBy(formatedFilterState, isNil), omitBy(formatedSelectedDashboardData, isNil));
    }

    return (
      <React.Fragment>
        {(dashboardWasUpdated || isNew) &&
          <Tooltip label='Save frequently used combinations of parameters, filters and widgets as Dashboards and quickly switch between them.'>
            <Button
              className={`${isNew ? 'btn-round' : 'btn-square'} _conflower-blue mr-3 ${className}`}
              onClick={this.saveDashboard}
            >
              {/*{isNew ? 'Create Dashboard' : 'Save Dashboard'}*/}
              Save Dashboard
            </Button>
          </Tooltip>
        }
        <TemplateNameModal onClose={this.toggleSaveModal} open={showModal} />
      </React.Fragment>
    );
  }
}

const mapState = (state: AppState) => ({
  selectedDashboard: state.dashboards.selectedDashboard,
  filterState: state.filter,
  dimension: state.reports.dimension,
  metric: state.reports.metric,
  filteredCampaignsIds: state.table.filteredCampaignsIds,
});

const mapAction = {
  open: toastActions.open,
  updateDashboard: dashboardsActions.updateDashboard,
};

export const CreateTemplateContainer = connect(mapState, mapAction)(CreateTemplateContainerComponent);
