import { reducerFromMap } from '../../utils/actions';
import { Action } from '../../models/Action';
import { reportsConstants } from './constants';
import { StatisticReportModel } from '../../models/Statistics';
import { Option } from '../../models/Option';
import { CancellarError } from '../../models/Response';
import { sessionStorageService } from '../../services/sessionStorage';

export const LOCAL_REPORTS_KEY = 'reportsState';

export interface StatisticReport extends StatisticReportModel {
  name: string;
}

export interface ReportsState {
  data: StatisticReport[];
  loading: boolean;
  dimension: Option | null;
  metric: Option;
  types: Option[];
}

const localDefaultReportState = sessionStorageService.get<ReportsState>(LOCAL_REPORTS_KEY).data;

export const defaultReportState = {
  data: [],
  loading: false,
  dimension: {
    label: 'Campaigns',
    value: 'campaign',
  },
  metric: {
    label: 'Clicks',
    value: 'clicks',
  },
  types: [],
}

function reportDataStartLoading(state: ReportsState): ReportsState {
  return {
    ...state,
    loading: true,
  };
}

function reportDataLoaded(state: ReportsState, action: Action<StatisticReport[]>): ReportsState {
  return {
    ...state,
    data: action.payload,
    loading: false,
  };
}

function reportDataError(state: ReportsState, action: Action<CancellarError<Response>>): ReportsState {
  return {
    ...state,
    loading: action.payload.response ? !action.payload.response.status : false,
  };
}

function changeDimension(state: ReportsState, action: Action<Option>): ReportsState {
  return {
    ...state,
    dimension: action.payload,
  };
}

function changeMetric(state: ReportsState, action: Action<Option>): ReportsState {
  return {
    ...state,
    metric: action.payload,
  };
}

function resetState(state: ReportsState): ReportsState {
  return {
    ...defaultReportState,
  };
}

const reducer = reducerFromMap(
  Object.keys(localDefaultReportState).length
    ? localDefaultReportState
    : defaultReportState,
  {
    [reportsConstants.LOADING_REPORTS__START]: reportDataStartLoading,
    [reportsConstants.LOADING_REPORTS__SUCCESS]: reportDataLoaded,
    [reportsConstants.LOADING_REPOSTS__ERROR]: reportDataError,
    [reportsConstants.CHANGE_DIMENSION]: changeDimension,
    [reportsConstants.CHANGE_METRIC]: changeMetric,
    [reportsConstants.RESET_STATE]: resetState,
  }
);

export const reports = (state: ReportsState, action: Action<any>) => reducer(state, action);
