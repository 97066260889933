import React from 'react';
import { connect } from 'react-redux';
import { Header } from 'iqm-framework';
import { Button } from 'factor';
import { Balance } from './navigationBar/balance/Balance';
import './navigationBar/style.scss';

import { AppState } from '../store';

interface Props {
  domainURL: string;
  token: string;
}

class NavigationBarComponent extends React.Component<Props> {
  //TODO: add clear auth state
  handleLogout = () => {};

  onCreateCampaignClick = () => {
    const {
      domainURL,
      token
    } = this.props;
    const campaignSepUrl = domainURL.includes("stage") ?
      "campaigns-sep.stage.iqm.com" : "campaigns-sep.iqm.com";
    window.open(encodeURI(`https://${campaignSepUrl}/#/login/${btoa(token)}/${btoa(domainURL)}`), '_blank');
  };

  addBalanceHandler = () => {
    const {
      domainURL,
      token
    } = this.props;
    const appSepUrl = domainURL.includes("stage") ?
      "app-sep.stage.iqm.com" : "app-sep.iqm.com";
    window.open(encodeURI(`https://${appSepUrl}/#/signin?token=${token}&page=addfunds`), '_blank');
  };

  render() {
    return (
      <Header
        leftLogo={'Dashboard'}
        onLogout={this.handleLogout}
        title="Dashboard"
        isBeta
      >
        <Button className="btn-round _conflower-blue _filled _md" onClick={this.onCreateCampaignClick}>
          <span className="btn-round__prefix">+</span>
          New Campaign
        </Button>
        <Balance addBalance={this.addBalanceHandler}/>
      </Header>
    );
  }
}

const mapState = (state: AppState) => ({
  domainURL: state.auth.domainURL,
  token: state.auth.apiToken,
});

export const NavigationBar = connect(mapState)(NavigationBarComponent);
