import { ColumnOptions } from 'iqm-framework';

export type Option<T = any> = {
  label: string;
  value: T;
}

export interface OptionID extends Option {
  id: number;
}

export interface StickedOption<T = any> extends Option<T> {
  alwaysEnabled?: boolean;
}

export type Select = Array<Option> | null;

export const sortingColumnOptions: StickedOption[] = [
  {
    label: 'ID',
    value: 'campaignId',
    alwaysEnabled: true,
    draggableGroup: 1,
  },
  ColumnOptions.ColumnCampaignName,
  ColumnOptions.ColumnMaxBid,
  ColumnOptions.ColumnDailyBudget,
  ColumnOptions.ColumnTotalBudget,
  { value: 'percentageOfTotalSpent', label: 'Budget vs Spent(%)' },
  ColumnOptions.ColumnImpressions,
  ColumnOptions.ColumnClicks,
  ColumnOptions.ColumnCtr,
  ColumnOptions.ColumnTotalSpent,
  // ColumnOptions.ColumnGoal, // temporarily hidden
  ColumnOptions.ColumnWinrate,
  ColumnOptions.ColumnStartDate,
  ColumnOptions.ColumnEndDate,
  ColumnOptions.ColumnEcpm,
  ColumnOptions.ColumnEcpc,
  ColumnOptions.ColumnDataCost,
  ColumnOptions.ColumnMediaSpent,
  ColumnOptions.ColumnVcr,
  ColumnOptions.ColumnStartCount,
  ColumnOptions.ColumnFirstCount,
  ColumnOptions.ColumnMidCount,
  ColumnOptions.ColumnThirdCount,
  ColumnOptions.ColumnCompleteCount,
  ColumnOptions.ColumnCpcv,
  ColumnOptions.ColumnUniqueReach,
];

export const sortingGroupsColumnOptions: StickedOption[] = [
  {
    label: 'ID',
    value: 'campaignGroupId',
    alwaysEnabled: true,
    draggableGroup: 1,
  },
  {
    label: 'Group name',
    value: 'campaignGroupName',
    alwaysEnabled: true,
    draggableGroup: 1,
  },
  ColumnOptions.ColumnImpressions,
  ColumnOptions.ColumnClicks,
  ColumnOptions.ColumnCtr,
  ColumnOptions.ColumnTotalSpent,
  ColumnOptions.ColumnDataCost,
  ColumnOptions.ColumnMediaSpent,
  ColumnOptions.ColumnWinrate,
  ColumnOptions.ColumnEcpm,
  ColumnOptions.ColumnEcpc,
  ColumnOptions.ColumnVcr,
  ColumnOptions.ColumnStartCount,
  ColumnOptions.ColumnFirstCount,
  ColumnOptions.ColumnMidCount,
  ColumnOptions.ColumnThirdCount,
  ColumnOptions.ColumnCompleteCount,
  ColumnOptions.ColumnCpcv,
];

export const defaultsortingColumnOptions = sortingColumnOptions.slice(0, -15);
export const defaultSortingGroupsColumnOptions = sortingGroupsColumnOptions.slice(0, -12);
