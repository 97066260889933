import React from 'react';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';
import { Icon } from 'factor';
import { CampaignsActions as Actions } from "../../../../../../../models/CampaignsActions";
import { BtnMenu } from '../components/BtnMenu';
import { ReactComponent as ArrowDownIcon } from '../../../../../../../svg/arrow-down.svg';
import { AppState } from '../../../../../../../store';
import { Campaign } from '../../../../../../../models/Campaign';
import { ButtonsDialog } from './ButtonsDialog';

interface Props {
  selectedCampaigns: Campaign[];
}
interface State {
  btnMenuIsOpen: boolean;
  dialogIsOpen: boolean;
  action: string | null;
}

const DISABLE_EDIT_STATUSES = ['expired', 'deleted'];

class ActionsMenuComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      btnMenuIsOpen: false,
      dialogIsOpen: false,
      action: null,
    };
  }

  toggleBtnMenu = () => {
    const { btnMenuIsOpen } = this.state;
    this.setState({
      btnMenuIsOpen: !btnMenuIsOpen,
    });
  };

  openBtnMenu = () => {
    this.setState({
      btnMenuIsOpen: true,
    });
  };

  closeBtnMenu = () => {
    this.setState({
      btnMenuIsOpen: false,
    });
  };

  closeDialog = () => {
    this.setState({
      dialogIsOpen: false,
    })
  };

  actionHandler = (action) => {
    this.setState({
      btnMenuIsOpen: false,
      dialogIsOpen: true,
      action,
    })
  };

  isCampaignsChangeable = (statuses: String[]): Boolean => {
    const { selectedCampaigns } = this.props;
    return selectedCampaigns.filter(camapign => statuses.includes(camapign.status)).length !== selectedCampaigns.length;
  }

  render() {
    const { selectedCampaigns } = this.props;
    const { btnMenuIsOpen } = this.state;

    return (
      <React.Fragment>
        <BtnMenu
          isOpen={btnMenuIsOpen}
          open={this.openBtnMenu}
          close={this.closeBtnMenu}
          className="filter__btn"
          tooltipParams={{
            label: 'Edit, Duplicate or Delete selected Campaigns',
          }}
          content={(
            <div className="btn-select">
              <button
                className="btn-select__title"
                onClick={this.closeBtnMenu}
              >
                Actions
              </button>
              <div className="btn-select__buttons">
                <button
                  className="btn-select__btn"
                  onClick={() => this.actionHandler(Actions.edit)}
                  disabled={selectedCampaigns.length > 1 || !this.isCampaignsChangeable(DISABLE_EDIT_STATUSES)}
                >
                  <i className="btn-select__icon">
                    <Icon name="Edit" />
                  </i>
                  Edit
                </button>
                <button
                  className="btn-select__btn"
                  onClick={() => this.actionHandler(Actions.duplicate)}
                >
                  <i className="btn-select__icon">
                    <Icon name="Copy" />
                  </i>
                  Duplicate
                </button>
                <button
                  className="btn-select__btn"
                  onClick={() => this.actionHandler(Actions.delete)}
                  disabled={!this.isCampaignsChangeable(DISABLE_EDIT_STATUSES)}
                >
                  <i className="btn-select__icon">
                    <Icon name="Delete" />
                  </i>
                  Delete
                </button>
              </div>
            </div>
          )}
        >
          <span className="btn-round__prefix">
            <ArrowDownIcon />
          </span>
          Change
        </BtnMenu>
        {createPortal(
          <ButtonsDialog
            selectedCampaigns={selectedCampaigns}
            dialogIsOpen={this.state.dialogIsOpen}
            action={this.state.action}
            close={this.closeDialog}
          />,
          document.body)}
      </React.Fragment>
    );
  }
}

const actionsMenuMapState = (state: AppState) => ({
  selectedCampaigns: state.table.selectedTableCampaigns,
});

export const ActionsMenu = connect(actionsMenuMapState)(ActionsMenuComponent);
