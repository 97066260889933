import union from 'lodash/union';
import { Dispatch } from 'redux';
import { createAction } from '../../utils/actions';
import { filteredCampaignGroupsConstants } from './constants';
import { API } from '../../api';
import { AppState } from '../index';

export const filteredCampaignGroupsActions = {
  getFilteredGroups: function () {
    return async (dispatch: Dispatch<any>, getState: () => AppState) => {
      dispatch(createAction(filteredCampaignGroupsConstants.FILTERED_CAMPAIGN_GROUP_LOADING))
      try {
        const state = getState();
        let selectedGroupsIds: number[] = [];
        const { selectedTableCampaigns } = state.table;
        const { auth } = state;
        const params = {
          advertiserId: auth.userId as number,
          dspId: auth.dspId as number,
          campaignIds: selectedTableCampaigns.map(i => i.campaignId).join(','),
        }
        const response = await API.FilteredCampaignGroups.getData(params);
        if (Object.keys(response.responseObject).length) {
          for (let key in response.responseObject) {
            selectedGroupsIds = union(selectedGroupsIds, response.responseObject[key]);
          }
        }
        dispatch(createAction<number[]>(filteredCampaignGroupsConstants.FILTERED_CAMPAIGN_GROUP_SUCCESS, selectedGroupsIds));
      } catch (e) {
        console.log('Fetch filtered groups error', e);
        dispatch(createAction<number[]>(filteredCampaignGroupsConstants.FILTERED_CAMPAIGN_GROUP_ERROR));
        return Promise.reject(false);
      }
    }
  },
  updateFilteredGroups: function (campaignGroupId: number[]) {
    return async (dispatch: Dispatch<any>, getState: () => AppState) => {
      try {
        const state = getState();
        const { selectedTableCampaigns } = state.table;
        const { auth } = state;
        if (!auth.dspId || !auth.userId) {
          return;
        }
        const params = {
          advertiserId: auth.userId,
          dspId: auth.dspId,
          campaignId: selectedTableCampaigns.map(i => i.campaignId).join(','),
          campaignGroupId: campaignGroupId.join(','),
        }
        await API.FilteredCampaignGroups.updateGroups(params);
      } catch (e) {
        console.log('Update filtered groups error', e);
        return Promise.reject(false);
      }
    }
  },
  deleteFilteredGroup: function (groupId: number) {
    return async (_dispatch, getState: () => AppState) => {
      try {
        const state = getState();
        const { auth } = state;
        const params = {
          advertiserId: auth.userId,
          dspId: auth.dspId,
          ids: groupId,
        }
        return await API.FilteredCampaignGroups.deleteGroup(params);
      } catch (e) {
        console.log('Delete filtered groups error', e);
        throw e;
      }
    }
  },
};
