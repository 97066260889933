import { GraphsNames } from '../store/graphs/reducers';
import { Option } from './Option';
import { Campaign } from './Campaign';

export interface GrapthParamsShort {
  field: string;
  sort_by?: string;
  graphName: GraphsNames;
}

export interface GraphParams extends GrapthParamsShort {
  frmtime: number;
  totime: number;
}

export interface PairedGraphModel {
  start: number;
  end: number;
  graph1: DataValue;
  graph2: DataValue;
  xAxiesLabel: number;
}

export interface GraphObjectDTO {
  key: string;
  values: Array<[DataValue, DataValue]>;
}

export type GraphDTO = Array<GraphObjectDTO>;

type DataValue = number | string | Date;

export interface GraphModelValue {
  id: string;
  data: Array<[DataValue, DataValue]>
}

export type GraphModel = Array<GraphModelValue>;

export const Graph1DefaultMetricOption = { value: 'impressions', label: "Impressions" };
export const Graph2DefaultMetricOption = { value: 'clicks', label: "Clicks" };

export interface AggregatedOption {
  value: string;
  label: string;
  isGroupHeader?: boolean;
  options?: Option[];
}

const dimensionMap = {
  'Campaigns': ['Campaign%s', 'Campaign Group%s'],
  'Creatives': ['Creative%s', 'Creative Type%s'],
  'Demographics': ['Age Group%s', 'Gender%s', 'Ethnicit%y', 'Language%s', 'Income Range%s'],
  'Technologies': ['Device Type%s', 'OS', 'Manufacturer%s'],
  'Deliveries': ['Traffic Type%s', 'Exchange%s']
}

const metricMap = {
  'Expenditure': ['Total Spent ($)', 'eCPC', 'eCPM', 'CPCV'],
  'Totals': ['Clicks', 'Impressions'],
  'Rates': ['WinRate', 'CTR', 'CVR', 'VCR'],
  'Video': ['Video Start', 'Video 25%', 'Video 50%', 'Video 75%', 'Video 100%'],
}

const makeValueOption = v => v.toLowerCase().replace(/%s/g, '').replace(/%y/g, 'y').split(' ').join('_');
const makeLabelOption = v => v.replace(/%s/g, 's').replace(/%y/g, 'ies');

const aggregeteOption = (data: object): AggregatedOption[] => {
  const options: AggregatedOption[] = [];
  for (const [key, value] of Object.entries(data)) {
    options.push({
      value: makeValueOption(`${key}_group_header`),
      label: key,
      isGroupHeader: true,
      options: value.map(v => ({ value: makeValueOption(v), label: makeLabelOption(v) })),
    });
  };
  return options;
}

export const dimensionOptions = aggregeteOption(dimensionMap);
export const metricOptions = aggregeteOption(metricMap);


export enum ReportingAvialableDimensions {
  exchange = 'exchange_reports',
  manufacturer = 'manufacturer_reports',
  os = 'os_reports',
  inventory_group = 'app_reports',
}

export enum DimensionsHelper {
  campaign = 'campaignName',
  exchange = 'exchange',
  age_group = 'age',
  income_range = 'income_group',
}

export enum ReportingAvialableMetrics {
  bid_impression = 'bidImpressions',
  clicks = 'clicks',
  ecpc = 'eCPC',
  ecpm = 'eCPM',
  cpcv = 'eCPCV',
  ctr = 'CTR',
  cvr = 'CVR',
  vcr = 'VCR',
  impressions = 'impressions',
  winrate = 'winRate',
  video_start = 'startCount',
  'video_25%' = 'firstCount',
  'video_50%' = 'midCount',
  'video_75%' = 'thirdCount',
  'video_100%' = 'completeCount',
  'total_spent_($)' = 'spent',
}

export interface GraphLambdaResponse extends Campaign {
  date: number,
  start_date: string,
  end_date: string,
}

export const integerFormatMetrics = ['Impressions', 'Clicks', 'Video Start', 'Video 25%', 'Video 50%', 'Video 75%', 'Video 100%'];
export const percentFormatMetrics = ['WinRate', 'CTR', 'CVR', 'Total Spent (%)', 'VCR'];
export const currencyFormatMetrics = ['eCPC', 'CPI', 'eCPM', 'CPCV', 'Total Spent ($)'];
