import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { DialogContent, Button } from 'factor';
import { TimezonePicker, TimezoneProvider, EpochSingleDateRangePicker } from 'iqm-framework';
import { pluralize } from '../../../../../../../utils/pluralize';
import { AppState } from '../../../../../../../store';
import { tableActions } from '../../../../../../../store/table/actions';
import { toastActions } from '../../../../../../../store/toast/actions';
import { Campaign } from '../../../../../../../models/Campaign';
import { API } from '../../../../../../../api';
import { TableComponentInstance } from '../../../Table';
import { OptionID } from '../../../../../../../models/Option';
import './duplicateDialogContent/styles.scss';

const MINIMUM_RUNNING_INTERVAL = 15;

interface Props {
  selectedCampaigns: Campaign[];
  timezone: OptionID;
  timezones: OptionID[];
  onClose: () => void;
  duplicate: (start: number, end: number | null) => Promise<any>;
  updateName: (name: string, id: string | number) => void;
  openToast: (name: string) => void;
}
interface State {
  startDate: number;
  endDate: number | null;
}

class DuplicateDialogContentComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      startDate: moment().valueOf(),
      endDate: null,
    }
  }

  get timezone() {
    const { timezone, timezones } = this.props;
    let campaignTz = timezone;
    const selectedCampaignsTimezones = this.selectedCampaignsTimezones();

    if (selectedCampaignsTimezones.length === 1) {
      campaignTz = timezones.find(tz => tz.value === selectedCampaignsTimezones[0]) || timezone;
    }

    return campaignTz;
  }

  selectedCampaignsTimezones = () => {
    const { selectedCampaigns } = this.props;
    return selectedCampaigns.reduce((acc: string[], campaign) => {
      if (!acc.includes(campaign.campaignTimezone)) {
        acc.push(campaign.campaignTimezone);
      }
      return acc;
    }, []);
  }

  getCampaignDetails = (ids: number[]) => {
    const detailsPromises = ids.map(id => API.campaigns.getCampaignDetails(id));
    return Promise.all(detailsPromises);
  };

  duplicateHandler = () => {
    const { duplicate, onClose, openToast } = this.props;
    const { startDate, endDate } = this.state;
    const startValue = Math.round(startDate / 1000);
    let endValue: number | null = null;

    if (endDate) {
      endValue = Math.round(endDate / 1000);
    }

    duplicate(startValue, endValue)
      .then(() => {
        const selectedCampaignsIds = this.props.selectedCampaigns.map(i => i.campaignId);
        const toastMessage = `${pluralize('', selectedCampaignsIds.length, {
          single: `Campaign with id ${selectedCampaignsIds.join(', ')} is`,
          multiple: `Campaigns with id ${selectedCampaignsIds.join(', ')} are`,
        })} duplicated!`;
        openToast(toastMessage);
        TableComponentInstance.setState({
          selected: [],
          loading: true,
        });
        TableComponentInstance.getNewData();
        onClose();
      })
      .catch(e => {
        onClose();
        openToast(e);
      });
  };

  handleChangeDateRange = ({ startDate, endDate }) => {
    this.setState({ startDate, endDate });
  };

  isEndDateADayInFuture = campaign => {
    return moment.tz(campaign.endTime * 1000, 'UTC').diff(moment.tz(moment(), 'UTC'), 'days') >= 1;
  };

  getDefaultEndDate = (tz: string) => {
    const {
      selectedCampaigns
    } = this.props;

    let defaultDate;

    if (selectedCampaigns.length === 1) {
      if (this.isEndDateADayInFuture(selectedCampaigns[0])) {
        defaultDate = moment(selectedCampaigns[0].endTime * 1000).tz(tz);
      } else {
        defaultDate = moment().add(1, 'd').tz(tz);
      }
    } else {
      defaultDate = moment().add(1, 'd').tz(tz);
    }

    return moment.tz(defaultDate.format('YYYY-MM-DD HH:mm:ss'), moment.tz.guess());
  };

  render() {
    const { onClose, selectedCampaigns, openToast, timezone } = this.props;
    const { startDate } = this.state;
    let { endDate } = this.state;

    if (endDate && startDate > endDate) {
      this.setState({ endDate: startDate });
    }

    const moreThenOneTimezoneSelected = this.selectedCampaignsTimezones().length > 1;

    return (
      <DialogContent>
        <h3 className="title-card mb-4">{`Duplicate ${pluralize('Campaign', selectedCampaigns.length)}`}</h3>
        {selectedCampaigns.length === 1
          ? <h5 className={`title-card-subtitle ${moreThenOneTimezoneSelected ? 'mb-2' : 'mb-4'}`}>
            Enter the
            <span className="_black"> Start </span>
            and the
            <span className="_black"> End Date </span>
            for the
            <span className="_black"> {selectedCampaigns[0].campaignName} </span>
            campaign.
          </h5>
          : <h5 className={`title-card-subtitle ${moreThenOneTimezoneSelected ? 'mb-2' : 'mb-4'}`}>
            Enter the
            <span className="_black"> Start </span>
            and the
            <span className="_black"> End Date </span>
            for the
            <span className="_black"> {selectedCampaigns.length} </span>
            campaigns.
          </h5>
        }
        {moreThenOneTimezoneSelected && (
          <h5 className="title-card-subtitle mb-4">Selected campaigns have different timezones, the time displayed in
          <span className="_black"> {timezone && timezone.value}</span> timezone and would be converted to Campaign timezone when saved.</h5>
        )}
        <TimezoneProvider timezone={this.timezone}>
          <div className="d-flex mb-4 align-items-center">
            <EpochSingleDateRangePicker
              startLabel="Start Date and Time"
              endLabel="End Date and Time"
              startDate={startDate}
              endDate={endDate}
              onDateRangeChanged={this.handleChangeDateRange}
              getDefaultDateEndDate={this.getDefaultEndDate}
              onError={e => openToast(e)}
              className="d-flex"
              calendarMaximumDate={null}
              calendarMinimumDate={utcDate => utcDate}
              startDateTooltipParams={{ label: 'Date and Time when the Campaign will start' }}
              endDateTooltipParams={{ label: 'Date and Time when the Campaign will end' }}
            />
          </div>
          <div className="d-flex justify-content-between align-items-center mt-2">
            <div className="d-flex duplicate-dialog__timezone">
              <TimezonePicker onTimezoneChange={null} />
            </div>
            <Button
              className="btn-square _conflower-blue mr-2"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              className="btn-square _conflower-blue _filled"
              onClick={this.duplicateHandler}
            >
              Duplicate
            </Button>
          </div>
        </TimezoneProvider>
      </DialogContent>
    );
  }
}

const mapState = (state: AppState) => ({
  selectedCampaigns: state.table.selectedTableCampaigns,
  timezone: state.filter.timezone,
  timezones: state.filter.timezones,
});

const mapActions = {
  duplicate: tableActions.duplicate,
  updateName: tableActions.updateCampaignName,
  openToast: toastActions.open,
};

export const DuplicateDialogContent = connect(mapState, mapActions)(DuplicateDialogContentComponent);
