import { Action } from '../../models/Action';
import { appConstants } from './constants';

export const appActions = {
  toggleSidebar(value): Action<boolean> {
    return {
      type: appConstants.TOGGLE_SIDEBAR,
      payload: value,
    };
  }
};