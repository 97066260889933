import React from 'react';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';
import { Dialog } from 'factor';
import { BtnMenu } from './components/BtnMenu';
import { ChangeEndDate } from './components/ChangeEndDate';
import { AppState } from '../../../../../../store';
import { Campaign } from '../../../../../../models/Campaign';

interface Props {
  selectedCampaigns: Campaign[];
}
interface State {
  btnMenuIsOpen: boolean;
  dialogIsOpen: boolean;
  action: string | null;
}

enum Actions {
  changeEndDate = 'end_date',
}

class DatesActionsButtonComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props)

    this.state = {
      btnMenuIsOpen: false,
      dialogIsOpen: false,
      action: null,
    };
  }

  toggleBtnMenu = () => {
    const { btnMenuIsOpen } = this.state;
    this.setState({
      btnMenuIsOpen: !btnMenuIsOpen,
    });
  };

  openBtnMenu = () => {
    this.setState({
      btnMenuIsOpen: true,
    });
  };

  closeBtnMenu = () => {
    this.setState({
      btnMenuIsOpen: false,
    });
  };

  closeDialog = () => {
    this.setState({
      dialogIsOpen: false,
    })
  };

  actionHandler = (action) => {
    this.setState({
      btnMenuIsOpen: false,
      dialogIsOpen: true,
      action,
    })
  };

  render() {
    const { selectedCampaigns } = this.props;
    const { dialogIsOpen } = this.state;
    const value = selectedCampaigns.length === 1 ? +selectedCampaigns[0].endTime * 1000 : '';
    const dialogContent = (
      <ChangeEndDate
        label="End Date"
        value={value}
        action={Actions.changeEndDate}
        onClose={this.closeDialog} />
    );

    const dialog = <Dialog
      open={dialogIsOpen}
      onClickOutside={this.closeDialog}>
      {dialogContent}
    </Dialog>

    return (
      <React.Fragment>
        <BtnMenu
          isOpen={false}
          open={() => this.actionHandler(Actions.changeEndDate)}
          close={() => {}}
          className="filter__btn"
          content={null}
          tooltipParams={{
            label: 'Change the End Date and Time for the selected Campaigns',
          }}
        >
          Set End Date
        </BtnMenu>
        {createPortal(dialog, document.body)}
      </React.Fragment>
    );
  }
}

const mapState = (state: AppState) => ({
  selectedCampaigns: state.table.selectedTableCampaigns,
});

export const DatesActionsButton = connect(mapState)(DatesActionsButtonComponent);
