export const BASE_TIMEZONE_NAME = 'timezone';

const { sessionStorage } = window;

export type SessionData<T> = {
  data: T,
}

function save<T>(key: string, data: T): void {
  sessionStorage.setItem(key, JSON.stringify({
    _time: Date.now(),
    data: data,
  }));
}

function get<T>(key: string): SessionData<T> {
  const data = sessionStorage.getItem(key);
  return data ? JSON.parse(data) : {data: {}};
}

function remove(key: string): void {
  sessionStorage.removeItem(key);
}

export const sessionStorageService = {
  save,
  get,
  remove,
}
