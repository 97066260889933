import { getLambdaInstance } from './index';
import { Dashboard } from '../../models/Dashboards';

export function createDashboard(req: Dashboard): Promise<Dashboard> {
  return getLambdaInstance()
    .post('/dashboard', req)
    .then(response => response.data)
    .catch(err => Promise.reject(err));
}

export function getDashboards(): Promise<Dashboard[]> {
  return getLambdaInstance()
    .get('/dashboards')
    .then(response => response.data.items)
    .catch(err => Promise.reject(err));
}

export function updateDashboard(id: string, req: Dashboard): Promise<Dashboard> {
  return getLambdaInstance()
    .put(`/dashboard/${id}`, req)
    .then(response => response.data)
    .catch(err => Promise.reject(err));
}

export function removeDashboard(id: string): Promise<Dashboard> {
  return getLambdaInstance()
    .delete(`/dashboard?id=${id}`)
    .then(response => response.data)
    .catch(err => Promise.reject(err));
}
