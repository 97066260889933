export const campaingGroupsConstants = {
  LOAD_CAMPAIGN_GROUPS__START: 'LOAD_CAMPAIGN_GROUPS__START',
  LOAD_CAMPAIGN_GROUPS__SUCCESS: 'LOAD_CAMPAIGN_GROUPS__SUCCESS',
  LOAD_CAMPAIGN_GROUPS__ERROR: 'LOAD_CAMPAIGN_GROUPS__ERROR',
  CAMPAIGN_GROUPS__SELECT: 'CAMPAIGN_GROUPS__SELECT',
  CAMPAIGN_GROUPS__RESET: 'CAMPAIGN_GROUPS__RESET',
  CAMPAIGN_GROUPS__CHECK: 'CAMPAIGN_GROUPS__CHECK',
  CAMPAIGN_GROUPS__UNCHECK: 'CAMPAIGN_GROUPS__UNCHECK',
  CAMPAIGN_CREATE_GROUP: 'CAMPAIGN_CREATE_GROUP',
};
