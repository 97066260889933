import React from 'react';
import { Select } from 'factor';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import queryString from 'query-string';

import { Option } from '../../../../../models/Option';
import { CampaignStatusType } from '../../../../../models/Campaign';
import { AppState } from '../../../../../store';
import { filterActions } from '../../../../../store/filter/actions';
import { BASE_URL } from '../../../../../config';

interface Props extends RouteComponentProps {
  selected: Option<CampaignStatusType>;
  options: Option<CampaignStatusType>[];
  updateStatus: (value: Option<CampaignStatusType>) => void;
}

class SelectStatusWrapperComponent extends React.Component<Props> {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { options } = this.props;
    if (options.length) {
      this.getStatusFromUrl();
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { options } = this.props;
    if (options.length !== prevProps.options.length) {
      this.getStatusFromUrl();
    }
  } 

  handleChange = (value) => {
    this.props.updateStatus(value);
    this.props.history.push(BASE_URL);
  };

  getStatusFromUrl = () => {
    const { options } = this.props;
    const { search } = this.props.location;
    if (search) {
      const queryParams = queryString.parse(search);
      if (queryParams.action === 'changeStatus') {
        const status = JSON.parse(atob(queryParams.payload as string)).status;
        const findedStatus = options.find(o => o.value === status);
        findedStatus && this.handleChange(findedStatus);
      }
      
    }
  };

  render() {
    const {
      options,
      selected,
    } = this.props;

    const optionsWithoutBudgetPausedStatus = options.filter(o => o.value !== 'budget_paused');

    return (
      <div className="_with-legend">
        <Select
          className="mt-4"
          label="Status"
          options={optionsWithoutBudgetPausedStatus}
          showControlLabel={true}
          value={selected}
          onChange={this.handleChange}
          tooltipParams={{
            label: 'Filter data by Campaign Status',
          }}
        />
        <div className="select-legend">
          <div className={selected.value}></div>
        </div>
      </div>
    );
  }
}

const mapState = (state: AppState) => {
  return {
    selected: state.filter.status,
    options: state.filter.statusOptions,
  }
};

const mapActions = {
  updateStatus: filterActions.updateStatus,
};
export const SelectStatusWrapper = withRouter(connect(mapState, mapActions)(SelectStatusWrapperComponent));
