import React from 'react';
import { connect } from 'react-redux';
import { Tooltip } from 'factor';
import { StatItem } from '../../../components/stats/StatItem';
import { AppState } from '../../../store';
import { StatFormat, StatisticsTotalResult } from '../../../models/Statistics';
import './indicatorsContainer/styles.scss';

interface Props {
  className?: string;
  total: StatisticsTotalResult;
  prev: StatisticsTotalResult;
  loading: boolean;
}
class IndicatorsContainerComponent extends React.Component<Props> {
  render() {
    const { total, prev, loading, className = '' } = this.props;
    return (
      <Tooltip
        className={`dashboard-indicators ${className}`}
        label="Summary information compared to previous period of the same size"
      >
        <div className="dashboard-indicators__wrapper">
          <StatItem title={'Impressions'} loading={loading} value={total.impressions} prevValue={prev.impressions}/>
          <StatItem title={'eCPM'} loading={loading} format={StatFormat.currency} value={total.eCPM} prevValue={prev.eCPM}/>
          <StatItem title={'eCPC'} loading={loading} format={StatFormat.currency} value={total.eCPC} prevValue={prev.eCPC}/>
          <StatItem title={'Clicks'} loading={loading} value={total.clicks} prevValue={prev.clicks}/>
          <StatItem title={'Spent'} loading={loading} format={StatFormat.currency} value={total.mediaSpent} prevValue={prev.mediaSpent}/>
          <StatItem title={'CTR'} loading={loading} format={StatFormat.percent} value={total.eCTR} prevValue={prev.eCTR}/>
          <StatItem title={'WinRate'} loading={loading} format={StatFormat.percent} value={total.winRate} prevValue={prev.winRate}/>
          <StatItem title={'Total Spent'} loading={loading} format={StatFormat.currency} value={total.spent} prevValue={prev.spent}/>
        </div>
      </Tooltip>
    );
  }
}

const mapState = (state: AppState) => {
  return {
    total: state.statistics.total || ({} as StatisticsTotalResult),
    prev: state.statistics.totalPrev || ({} as StatisticsTotalResult),
    loading: state.statistics.loading,
  };
};
export const IndicatorsContainer = connect(mapState)(IndicatorsContainerComponent);
