import React from 'react';
import { Tooltip, Icon } from 'factor';
import { connect } from 'react-redux';

import { AppState } from '../../store';
import { SelectContainer } from './dashboardContent/SelectContainer';
import { CreateTemplateContainer } from './dashboardContent/CreateTemplateContainer';
import { DatesContainer } from './dashboardContent/DatesContainer';
import { TableWrapper } from './dashboardContent/TableWrapper';
import { dashboardsActions, GetDashboards } from '../../store/dashboards/actions';
import { ResetButton } from './dashboardContent/ResetButton';

import './dashboardContent/styles.scss';

interface Props extends GetDashboards {
  isAuth: boolean;
  loading: boolean;
  dashboardsLength: number;
}

export class DashboardContentComponent extends React.PureComponent<Props> {
  constructor(props) {
    super(props);

    this.state = {
      dialogIsOpen: false,
    };
  }

  componentDidMount() {
    const { getDashboards, isAuth } = this.props;
    if (isAuth) {
      getDashboards();
    }
  };

  componentDidUpdate(prevProps) {
    const { getDashboards, isAuth } = this.props;
    if (isAuth && (isAuth !== prevProps.isAuth)) {
      getDashboards();
    }
  }

  render() {
    const { dashboardsLength, loading } = this.props;
    const hasDashboards = dashboardsLength > 0;

    return (
      <div className="dashboard">
        <header className="dashboard-header dashboard__header">
          {!loading &&
            <React.Fragment>
              {hasDashboards &&
                <SelectContainer className={'dashboard-header__select'} />
              }
              <CreateTemplateContainer isNew={!hasDashboards} className={'dashboard-header__create'} />
              <ResetButton />
            </React.Fragment>
          }
          <DatesContainer className={'dashboard-header__datespicker'} />
        </header>
        <TableWrapper className={'dashboard__table-wrapper'} />
      </div>
    )
  }
}

const mapState = (state: AppState) => ({
  isAuth: state.auth.authorized,
  loading: state.dashboards.loading,
  dashboardsLength: state.dashboards.dashboards.length,
});

const mapActions = {
  getDashboards: dashboardsActions.getDashboards,
};

export const DashboardContent = connect(mapState, mapActions)(DashboardContentComponent);
