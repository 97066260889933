export const dashboardConstants = {
  CREATE_DASHBOARD: 'CREATE_DASHBOARD',
  GET_DASHBOARDS: 'GET_DASHBOARDS',
  SELECT_DASHBOARD: 'SELECT_DASHBOARD',
  CLEAR_SELECTED_DASHBOARD: 'CLEAR_SELECTED_DASHBOARD',
  UPDATE_DASHBOARD: 'UPDATE_DASHBOARD',
  DELETE_DASHBOARD: 'DELETE_DASHBOARD',
  DASHBOARD_LOADING_START: 'DASHBOARD_LOADING_START',
  DASHBOARD_LOADING_END: 'DASHBOARD_LOADING_END',
};
