import React from 'react';
import { Dialog, Button } from 'factor';
import { CampaignsActions as Actions } from "../../../../../../../models/CampaignsActions";
import { DuplicateDialogContent } from '../components/DuplicateDialogContent';
import { PauseDialogContent } from '../components/PauseDialogContent';
import { DeleteDialogContent } from '../components/DeleteDialogContent';
import { EditDialogContent } from '../components/EditDialogContent';
import { RunningDialogContent } from '../components/RunningDialogContent';
import { Campaign } from '../../../../../../../models/Campaign';
import { pluralize } from '../../../../../../../utils/pluralize';

interface ButtonsDialogProps {
  selectedCampaigns: Campaign[];
  dialogIsOpen: boolean;
  action: string | null;
  close: () => void;
}

export class ButtonsDialog extends React.Component<ButtonsDialogProps> {
  closeDialog = () => this.props.close();

  render() {
    const { selectedCampaigns, dialogIsOpen, action } = this.props;
    let dialogContent;

    switch (action) {
      case Actions.edit: {
        dialogContent = <EditDialogContent onClose={this.closeDialog} />;
        break;
      }
      case Actions.duplicate: {
        dialogContent = <DuplicateDialogContent onClose={this.closeDialog} />;
        break;
      }
      case Actions.pause: {
        dialogContent = <PauseDialogContent onClose={this.closeDialog} />;
        break;
      }
      case Actions.delete: {
        dialogContent = <DeleteDialogContent onClose={this.closeDialog} />;
        break;
      }
      case Actions.run: {
        dialogContent = <RunningDialogContent
          label={`Run ${pluralize('Campaign', selectedCampaigns.length)}`}
          action={Actions.run}
          onClose={this.closeDialog} />;
        break;
      }
      default: {
        dialogContent = null;
      }
    }

    return (
      <Dialog
        open={dialogIsOpen}
        onClickOutside={this.closeDialog}>
        {dialogContent}
      </Dialog>
    );
  }
}
