import React from 'react';
import { createPortal } from 'react-dom';
import { withClickOutside, Icon, TextFieldWithIcon, TextField, Button, Dialog, DialogContent, Tooltip } from 'factor';
import moment from 'moment';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import { Dashboard } from '../../../../models/Dashboards';
import { DeleteDashboardDialogContent } from './selectDashboard/components/DeleteDashboardDialogContent';
import { FilterState } from '../../../../store/filter/reducers';
import { convertFilterToDashboardData } from '../../../../models/Dashboards';
import { Option } from '../../../../models/Option';
import { UpdateDashboard } from '../../../../store/dashboards/actions';
import { Open } from '../../../../store/toast/actions';
import './selectDashboard/style.scss';
import {dashboards} from "../../../../store/dashboards/reducers";

const TOOLTIP_MESSAGE = 'Save frequently used combinations of parameters, filters and widgets as Dashboards and quickly switch between them.';

interface Props extends UpdateDashboard, Open {
  selectedDashboard: Dashboard | null;
  clearDashboard: () => void;
  dashboards: Dashboard[];
  selected: (dashboard: Dashboard | null) => void;
  deleted: (dashboard: Dashboard) => void;
  onCreated: (name: string) => void;
  filterState: FilterState;
  metric: Option;
  dimension: Option | null;
  filteredCampaignsIds: number[];
}

interface State {
  isOpen: boolean;
  creatingStarted: boolean;
  btnSelectSearch: string;
  newDashboardName: string;
  dialogIsOpen: boolean;
  deletingDashboard: Dashboard | null;
  isScrollable: boolean;
  dashboardSaveUpdatesModal: {
    isOpen: boolean;
    dashboard: Dashboard | null;
  };
}

class SelectDashboardsComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      creatingStarted: false,
      btnSelectSearch: '',
      newDashboardName: '',
      dialogIsOpen: false,
      deletingDashboard: null,
      isScrollable: false,
      dashboardSaveUpdatesModal: {
        isOpen: false,
        dashboard: null,
      },
    };
  }

  _inputRef: null | HTMLInputElement = null;
  _selectListRef: null | HTMLElement = null;

  componentDidUpdate(prevProps, prevState) {
    if ((this.state.isOpen && (this.state.isOpen !== prevState.isOpen))
      || (prevProps.dashboards.length !== this.props.dashboards.length)) {
      if (this._selectListRef && (this._selectListRef.clientHeight < this._selectListRef.scrollHeight)) {
        this.setState({ isScrollable: true });
      } else {
        this.setState({ isScrollable: false });
      }
    }
  }

  toggleSelect = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  handleClickOutside = () => {
    if (this.state.isOpen) {
      this.toggleSelect();
    }
  };

  handleSelect = (dashboard: Dashboard) => {
    if (this.isDashboardUpdated) {
      this.setState({
        dashboardSaveUpdatesModal: {
          isOpen: true,
          dashboard: dashboard,
        }
      });
    } else {
      this.props.selected(dashboard);
      this.setState({isOpen: false});
    }
  };

  openDialog = (event, dashboard: Dashboard) => {
    event.stopPropagation();
    this.setState({
      isOpen: false,
      dialogIsOpen: true,
      deletingDashboard: dashboard,
    });
  };

  handleCreate = () => {
    const { creatingStarted, btnSelectSearch, newDashboardName } = this.state;
    if (!creatingStarted) {
      this.setState({
        creatingStarted: true,
        newDashboardName: btnSelectSearch,
      }, () => this._inputRef && this._inputRef.select());
      return;
    }

    this.props.onCreated(newDashboardName.trim());
    this.setState({
      creatingStarted: false,
      btnSelectSearch: '',
      isOpen: false,
    });
  };

  handleDelete = () => {
    const { deleted } = this.props;
    const { deletingDashboard } = this.state;
    deletingDashboard && deleted(deletingDashboard);
    this.closeDialog();
  };

  handleChangeSearch = ({ target }) => {
    const { value } = target;

    this.setState({
      btnSelectSearch: value,
    });
  };

  renderList = () => {
    let { dashboards, selectedDashboard } = this.props;
    const { btnSelectSearch, isScrollable } = this.state;

    dashboards = btnSelectSearch
      ? dashboards.filter(d =>
        d.label.toLowerCase().includes(btnSelectSearch.toLowerCase()))
      : dashboards;

    return (
      <div className={`select__list-container ${isScrollable ? '_scrollable' : ''}`}>
        <div className="select__list" ref={el => {
          this._selectListRef = el;
        }}>
          {dashboards.map(dashboard => (
            <div className={`select__option ${selectedDashboard && selectedDashboard.id === dashboard.id ? '_active' : ''}`} key={dashboard.id} onClick={() => this.handleSelect(dashboard)}>
              <div className="select__option-label">
                {dashboard.label}
              </div>
              <Icon name="Delete" onClick={event => this.openDialog(event, dashboard)} />
            </div>
          ))}
        </div>
      </div>
    );
  };

  onCancel = () => {
    this.setState({
      creatingStarted: false,
      newDashboardName: '',
    });
  };

  closeDialog = () => {
    this.setState({
      dialogIsOpen: false,
    })
  };

  clearSelected = event => {
    event.stopPropagation();
    this.props.clearDashboard();
  };

  get isDashboardUpdated() {
    const { selectedDashboard, filterState, metric, dimension, filteredCampaignsIds } = this.props;
    let dashboardWasUpdated = false;

    if (selectedDashboard && filterState.dateRange) {
      const formatedFilterState = {
        ...convertFilterToDashboardData({...filterState, metric, dimension, filteredCampaignsIds}),
        dateRange: filterState.dateRange ? {
          start: moment(filterState.dateRange.start).unix(),
          end: moment(filterState.dateRange.end).unix(),
        } : null,
      }
      const formatedSelectedDashboardData = {
        ...selectedDashboard.data,
        dateRange: selectedDashboard.data.dateRange ? {
          start: moment(selectedDashboard.data.dateRange.start).unix(),
          end: moment(selectedDashboard.data.dateRange.end).unix(),
        } : null,
      };

      dashboardWasUpdated = !isEqual(omitBy(formatedFilterState, isNil), omitBy(formatedSelectedDashboardData, isNil));

      return dashboardWasUpdated;
    }
  }

  saveDashboard = (callback) => {
    const { open, updateDashboard, selectedDashboard } = this.props;
    if (selectedDashboard) {
      updateDashboard()
        .then(response => {
          open(`${response.label} has been saved!`);
          if (callback) {
            callback();
          }
        })
        .catch(() => open(`${selectedDashboard.label} hasn't been saved!`));
    }
  };

  handleSaveDashboardButtonsClick = (isSaveChanges?) => () => {
    const { dashboardSaveUpdatesModal } = this.state;

    if (isSaveChanges === true) {
      this.saveDashboard(() => this.props.selected(dashboardSaveUpdatesModal.dashboard));
    }
    if (isSaveChanges === false) {
      this.props.selected(dashboardSaveUpdatesModal.dashboard);
    }

    this.setState({
      isOpen: false,
      dashboardSaveUpdatesModal: {
        isOpen: false,
        dashboard: null,
      },
    });
  }

  render() {
    const { selectedDashboard } = this.props;
    const { isOpen, btnSelectSearch, creatingStarted, newDashboardName, dialogIsOpen, deletingDashboard, dashboardSaveUpdatesModal } = this.state;
    const dialog = deletingDashboard && (<Dialog
      open={dialogIsOpen}
      onClickOutside={this.closeDialog}>
      <DeleteDashboardDialogContent
        dashboard={deletingDashboard}
        onClose={this.closeDialog}
        onDelete={this.handleDelete}
      />
    </Dialog>);
    let selectLabelTitle = selectedDashboard ? 'Dashboard' : 'Select Dashboard';
    if (isOpen && creatingStarted && !selectedDashboard) {
      selectLabelTitle = 'Create Dashboard';
    }

    const saveChangesDialog = (
      <Dialog
        open={dashboardSaveUpdatesModal.isOpen}
        handleEnterKeyEvent={this.handleSaveDashboardButtonsClick(true)}
      >
        <DialogContent>
          <h3 className="title-card mb-4">
            Do you want to save changes?
          </h3>
          <div className="d-flex justify-content-end">
            <Button className="btn-square _conflower-blue mr-3" onClick={this.handleSaveDashboardButtonsClick()}>
              Cancel
            </Button>
            <Button className="btn-square _persimmon mr-3" onClick={this.handleSaveDashboardButtonsClick(false)}>
              No
            </Button>
            <Button className="btn-square _conflower-blue _filled" onClick={this.handleSaveDashboardButtonsClick(true)}>
              Yes
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    );

    return (<>
      <div className={`select js-select ${isOpen && '_opened'}`}>
        <div className="select__outer-wrapper">
          <div className="select__wrapper">
            <Tooltip
              className="select__btn"
              handleClick={this.toggleSelect}
              label={TOOLTIP_MESSAGE}
              auto={false}
              position="right"
            >
              <div className={`select__label ${selectedDashboard ? '_up' : ''}`}>
                {selectLabelTitle}
              </div>
              <div className={`select__value ${selectedDashboard ? '_selected' : ''}`}>{selectedDashboard ? selectedDashboard.label : ''}</div>
              {selectedDashboard &&
                <div className="select__clear" onClick={this.clearSelected}>
                  <Icon name="Close" />
                </div>
              }
              <div className="select__indicator"></div>
            </Tooltip>
            <div className="select__list-wrapper">
              {!creatingStarted ?
                <div className="select__search btn-select__search">
                  <TextFieldWithIcon
                    iconName="Search"
                    onChange={this.handleChangeSearch}
                    value={btnSelectSearch}
                    placeholder="Search"
                  />
                </div> :
                <div className="select__input">
                  <TextField
                    name="newDashboardName"
                    label="Dashboard Name"
                    onChange={v => {
                      this.setState({
                        newDashboardName: v,
                      });
                    }}
                    value={newDashboardName}
                    inputRef={e => this._inputRef = e}
                    autofocus={true}
                  />
                </div>
              }
              <div className="select__buttons d-flex mt-3 mb-3">
                {creatingStarted &&
                  <Button
                    className="create-group-container__button btn-square _conflower-blue _sm mr-2"
                    onClick={this.onCancel}
                  >
                    Cancel
                  </Button>
                }
                <Button
                  className={`create-group-container__button ${creatingStarted ? 'btn-square _filled' : 'btn-round'} _conflower-blue _sm`}
                  disabled={creatingStarted && !newDashboardName.trim().length}
                  onClick={this.handleCreate}
                >
                  Create New Dashboard
                </Button>
              </div>
              {this.renderList()}
            </div>
          </div>
        </div>
        {createPortal(dialog, document.body)}
        {createPortal(saveChangesDialog, document.body)}
      </div>
    </>);
  }
}

export const SelectDashboards = withClickOutside(SelectDashboardsComponent);
