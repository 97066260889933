import React from 'react';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogContent,
  DialogButtons,
  TextField,
} from 'factor';
import { filterActions } from '../../../store/filter/actions';
import { dashboardsActions, CreateDashboard } from '../../../store/dashboards/actions';
import { toastActions } from '../../../store/toast/actions';

interface Props extends CreateDashboard {
  open: boolean,
  onClose: () => void;
  openToast: (name: string) => void,
}

interface State {
  name: string,
}

const emptyValidation = {
  func: (value, input, rule) => {
    return !!(value && value.trim());
  },
  error: (name, rule) => {
    return `${name} must be filled!`;
  },
};

class TemplateNameModalComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      name: '',
    };
  }

  handleClose = () => {
    this.props.onClose();
  };

  handleSubmit = () => {
    const { createDashboard, onClose, openToast } = this.props;
    const { name } = this.state;

    if (name && name.trim()) {
      createDashboard(this.state.name)
        .then(() => {
          onClose();
          openToast(`${name} has been created!`);
        })
        .catch(e => {
          onClose();
          openToast(`${name} hasn't been created!`);
        });
    }
  };

  handleValueChange = (value: string) => {
    this.setState({
      name: value,
    });
  };

  render() {
    const {
      open,
      onClose
    } = this.props;

    const {
      name,
    } = this.state;

    return (
      <Dialog open={open} onClickOutside={onClose} className="save-template">
        <DialogContent>
          <h5 className="title-card mb-3">Create Dashboard</h5>
          <div className="mb-3">
            <TextField
              name="templateName"
              label="Dashboard Name"
              validationRules={[
                emptyValidation,
              ]}
              value={name}
              onChange={this.handleValueChange}
            />
          </div>
          <DialogButtons
            buttons={[{
              title: 'Cancel',
              handler: this.handleClose,
            }, {
              title: 'Apply',
              handler: this.handleSubmit,
              className: 'btn-square _conflower-blue _filled',
            }]}
          />
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = () => ({});
const mapActionsToProp = {
  ...filterActions,
  createDashboard: dashboardsActions.createDashboard,
  openToast: toastActions.open,
};

export const TemplateNameModal = connect(mapStateToProps, mapActionsToProp)(TemplateNameModalComponent);
