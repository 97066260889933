import React from 'react';
import {
  percentFormatMetrics,
  integerFormatMetrics,
  currencyFormatMetrics
} from '../../../../../../models/Graph';
import {
  CurrencyFormat,
  formatNumberWithSuffix
} from "../../../../../../utils/format";

import './customBarChartBarItem/styles.scss';

export default function CustomBarChartBarItem(props) {
  const {
    data,
    y,
    height,
    color,
    theme
  } = props.barItem;

  let { width } = props.barItem;

  const {
    onMouseEnter,
    onMouseMove,
    onMouseLeave
  } = props;

  const customHeight = 18;
  const { selectedMetric } = theme;
  let value: string | number = data.value - 100;
  if (!value) {
    width = 1;
  }

  if (percentFormatMetrics.includes(selectedMetric.label)) {
    value = `${(+value).toFixed(2)}%`;
  } else if (integerFormatMetrics.includes(selectedMetric.label)) {
    value = `${formatNumberWithSuffix(value)}`;
  } else if (currencyFormatMetrics.includes(selectedMetric.label)) {
    value = `${CurrencyFormat.format(value)}`;
  }

  return <g className="custom-chart-bar-item" key={value} transform={`translate(0, ${y + (height - customHeight) / 2})`}>
    <rect
      strokeWidth="0"
      height={customHeight}
      width={width}
      rx={3}
      ry={3}
      fill={color}
      onMouseEnter={onMouseEnter}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
    />
    <text x={width + 35} y={customHeight / 2} textAnchor="middle" dominantBaseline="central" style={{
      fontFamily: "Lato Regular, sans-serif",
      fontSize: "12px",
      lineHeight: "15px",
      fill: "#898989",
    }}>{value}</text>
  </g>
};
