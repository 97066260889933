import { reducerFromMap } from '../../utils/actions';
import { Action } from '../../models/Action';
import { filteredCampaignGroupsConstants } from './constants';
import { CampaignGroup } from '../../models/CampaignGroup';

export interface FilteredCampaignGroupsState {
  loading: boolean;
  selectedGroups: number[],
}

export const defaultFilteredCampaignGroupsState: FilteredCampaignGroupsState = {
  loading: false,
  selectedGroups: [],
};

function loadingStart(state): FilteredCampaignGroupsState {
  return {
    ...state,
    loading: true,
  };
}

function loadingSuccess(state, action: Action<CampaignGroup[]>): FilteredCampaignGroupsState {
  return {
    ...state,
    loading: false,
    selectedGroups: action.payload,
  };
}

function loadingError(state): FilteredCampaignGroupsState {
  return {
    ...state,
    loading: false,
    selectedGroups: [],
  };
}

const reducer = reducerFromMap(
  defaultFilteredCampaignGroupsState,
  {
    [filteredCampaignGroupsConstants.FILTERED_CAMPAIGN_GROUP_LOADING]: loadingStart,
    [filteredCampaignGroupsConstants.FILTERED_CAMPAIGN_GROUP_SUCCESS]: loadingSuccess,
    [filteredCampaignGroupsConstants.FILTERED_CAMPAIGN_GROUP_ERROR]: loadingError,
  }
);

export const filteredCampaignGroups = (state: FilteredCampaignGroupsState = defaultFilteredCampaignGroupsState, action: Action<any>) => reducer(state, action);
