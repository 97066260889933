import React from 'react';
import { CurrencyFormat } from '../../../../../utils/format';

export interface DialogContentData {
  prev: number;
  value: number;
}

interface Props extends DialogContentData {
  onChange: (newValue: string) => void;
}

const DialogAmountContentWrapperComponent = (props: Props) => {
  const { onChange, prev, value } = props;

  return (
    <React.Fragment>
      <div className="changing-value dialog__input mt-2 mb-2">
        <div className="changing-value__old">
          {CurrencyFormat.format(prev)}
        </div>
        <div className={`changing-value__difference ${prev > value ? '_minus' : ''}`}>
          {CurrencyFormat.format(Math.abs(prev - value))}
        </div>
        =
        <input
          type="text"
          className="changing-value__new"
          value={CurrencyFormat.format(value)}
          onChange={({ target }) => onChange(target.value)}
        />
      </div>
    </React.Fragment>
  );
};

export const DialogAmountContentWrapper = DialogAmountContentWrapperComponent; 
