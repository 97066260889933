import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { AppState } from '../../../../../store';
import { Campaign } from '../../../../../models/Campaign';
import { pluralize } from '../../../../../utils/pluralize';
import { ActionsMenu } from './selectedCampaigns/campaignsActionsButtons/ActionsMenu';
import { PauseButton } from './selectedCampaigns/campaignsActionsButtons/PauseButton';
import { RunButton } from './selectedCampaigns/campaignsActionsButtons/RunButton';
import { BudgetActionsButton } from './selectedCampaigns/BudgetActionsButton';
import { DatesActionsButton } from './selectedCampaigns/DatesActionsButton';
import { GroupsActionsButton } from './selectedCampaigns/GroupsActionsButton';
import { OptimizeButton } from './selectedCampaigns/OptimizeActionButton';
import { RestoreButton } from './selectedCampaigns/RestoreActionButton';
import { TableComponentInstance } from '../Table';
import { OptionID, Option } from '../../../../../models/Option';
import { DateRange } from '../../../../../store/filter/reducers';

interface Props {
  selected: Campaign[];
  domainURL: string;
  token: string;
  timezone: OptionID | null;
  dateRange: DateRange | null;
  status: Option;
}

class SelectedCampaignsComponent extends React.PureComponent<Props> {
  handleClear = () => {
    if (TableComponentInstance) {
      TableComponentInstance.clearSelected();
    }
  };

  hanleOptimizeClicked = () => {
    const { selected, timezone, token, dateRange } = this.props;
    if ((selected.length === 1) && dateRange && timezone) {
      const selectedCampaign = selected[0];
      const tzId = timezone.id.toString();
      const startDate = moment(dateRange.start).valueOf().toString();
      const endDate = moment(dateRange.end).valueOf().toString();
      let params = {
        token,
        page: 'advance',
        campaign_id: selectedCampaign.campaignId,
        status: selectedCampaign.status,
        startDate,
        endDate,
        timezone: tzId,
      }
      const paramsString = new URLSearchParams(params).toString();
      const link = `https://${this.props.domainURL}/#/signin?${paramsString}`;
      window.open(link, '_blank');
    }
  };

  render() {
    const { selected, status } = this.props;
    const selectedEditable = !selected.every(campaign => campaign.status === 'deleted');
    const shouldAllowEndDateChange = 
      !selected.every(campaign => ['deleted', 'expired'].includes(campaign.status));
    const disableOptimizeButton = (selected.length !== 1) || selected[0].status === 'draft';

    const isStatusAll = status.value === 'all';
    const isOnlyDeletedSelected = selected.every(item => item.status === 'deleted');

    return (
      selected.length > 0 ?
        <React.Fragment>
          <div className="filter__selected">
            <button className="btn-close filter__remove" onClick={this.handleClear} />
            {`${selected.length} ${pluralize('Campaign', selected.length)} selected`}
          </div>
          {status.value !== 'deleted' ? (<>
            {(isStatusAll && isOnlyDeletedSelected) ? (
              <RestoreButton />
            ) : (<>
              <RunButton />
              <GroupsActionsButton />
              <ActionsMenu />
              <PauseButton />
              {selectedEditable && <BudgetActionsButton />}
              {shouldAllowEndDateChange && <DatesActionsButton />}
              <OptimizeButton disabled={disableOptimizeButton} clicked={this.hanleOptimizeClicked} />
            </>)}
          </>) : (
            <RestoreButton />
          )}
        </React.Fragment> :
        null
    );
  }
}

const mapState = (state: AppState) => {
  return {
    selected: state.table.selectedTableCampaigns,
    domainURL: state.auth.domainURL,
    token: state.auth.apiToken,
    timezone: state.filter.timezone,
    dateRange: state.filter.dateRange,
    status: state.filter.status,
  };
};

export const SelectedCampaigns = connect(mapState)(SelectedCampaignsComponent);
