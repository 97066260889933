import { Dispatch } from 'redux';
import { DateRange, GraphSelectChangeValue, Template, Freeze } from './reducers';
import { createAction } from '../../utils/actions';
import { filterConstants } from './constants';
import { AppState } from '../index';
import { Option, StickedOption, OptionID } from '../../models/Option';
import { CampaignStatusType, statusMap } from '../../models/Campaign';
import { tableConstants } from '../table/constants';
import { GraphsNames } from '../graphs/reducers';
import { API } from '../../api';

export const TEMPLATES = 'user_templates';

export interface SelectTimezone {
  selectTimezone: (data: OptionID) => void;
}

export interface SelectTemplate {
  selectTemplate: (template: Template) => void;
  clearTemplate: () => void;
}

export interface ChangeSortingColumns {
  changeSortingColumns: (columns: StickedOption[]) => void;
  changeSortingGroupsColumns: (columns: StickedOption[]) => void;
}

export interface SaveTemplate {
  saveTemplate: (name: string) => void;
};

export interface UpdateFreeze {
  updateFreeze: (data: Freeze) => void;
};

export interface GetCampaignTypes {
  getCampaignTypes: () => void;
};

export interface SelectCampaignType {
  selectCampaignType: (data: Option[]) => void;
};

export interface DeselectCampaignType {
  deselectCampaignType: () => void;
};

export interface ChangeSelectingCampaignsMode {
  changeSelectingCampaignsMode: (isGroup: boolean) => void;
};

export const filterActions = {
  setInitialDateRange: (dateRange: DateRange) => {
    return (dispatch: Dispatch<any>) => {
      dispatch(createAction<DateRange>(filterConstants.SET_INITIAL_DATE_RANGE, dateRange));
    };
  },
  setDateRange: (dateRange: DateRange) => {
    return (dispatch: Dispatch<any>) => {
      dispatch(createAction<DateRange>(filterConstants.SET_DATE_RANGE, dateRange));
    };
  },
  selectTimezone: (data: OptionID) => {
    return (dispatch: Dispatch<any>) => {
      dispatch(createAction<OptionID>(filterConstants.SELECT_TIMEZONE, data));
    }
  },
  getTimezones: () => {
    return async (dispatch: Dispatch<any>) => {
      try {
        const response = await API.timezone.getTimezones();
        dispatch(createAction<OptionID[]>(filterConstants.GET_TIMEZONES, response));
      } catch (e) {
        console.log('[can\'t fetching timezones]: ', e);
      }
    }
  },
  changeTableLevel: (value: Option) => {
    return createAction<Option>(filterConstants.CHANGE_TABLE_LEVEL, value);
  },
  updateSearch: (value: string) => {
    return createAction<string>(filterConstants.UPDATE_SEARCH, value);
  },
  getStatuses: () => {
    return async (dispatch: Dispatch, getState: () => AppState) => {
      try {
        const state = getState();
        const { selectedId } = state.campaignGroups;
        const { auth } = state;
        if (!auth.dspId || !auth.userId) {
          return;
        }
        const params = {
          advertiserId: auth.userId,
          dspId: auth.dspId,
        }
        const response = selectedId
          ? await API.campaignGroups.getStatuses(params, selectedId)
          : await API.campaigns.campaignsCount();
        const options: Option<CampaignStatusType>[] = Object.keys(statusMap)
          .map((key): Option => {
            if (response[key]) {
              return {
                label: `${statusMap[key]} (${response[key]})`,
                value: key,
              };
            }
            return {
              label: `${statusMap[key]} (0)`,
              value: key,
            };
          });
        dispatch(createAction<Option<CampaignStatusType>[]>(filterConstants.SET_STATUS, options));
      } catch (e) {
        console.log('[can\'t fetching statuses from server]: ', e);
      }
    }
  },
  updateStatus: (value: Option<CampaignStatusType>) => {
    return (dispatch: Dispatch) => {
      dispatch(createAction<Option<CampaignStatusType>>(filterConstants.UPDATE_STATUS, value));
      dispatch(createAction<void>(tableConstants.TABLE_CLEAR_SELECTED_CAMPAIGN));
    };
  },
  changeGraphSelectValue: (value: Option, key: GraphsNames) => {
    return (dispatch: Dispatch<any>) => {
      dispatch(createAction<GraphSelectChangeValue>(filterConstants.UPDATE_GRAPH_SELECT, { key, value }));
    };
  },
  changeSortingColumns: (columns: StickedOption[]) => {
    return (dispatch: Dispatch<any>) => {
      dispatch(createAction<StickedOption[]>(filterConstants.CHANGE_SORTING_COLUMNS, columns));
    };
  },
  changeSortingGroupsColumns: (columns: StickedOption[]) => {
    return (dispatch: Dispatch<any>) => {
      dispatch(createAction<StickedOption[]>(filterConstants.CHANGE_SORTING_GROUPS_COLUMNS, columns));
    };
  },
  updateFreeze: (data: Freeze) => {
    return (dispatch: Dispatch<any>) => {
      dispatch(createAction<Freeze>(filterConstants.UPDATE_FREEZE_TABLE_PART, data));
    };
  },
  updateFilters: (data) => {
    return (dispatch: Dispatch<any>) => {
      dispatch(createAction<any>(filterConstants.UPDATE_FILTERS, data));
    };
  },
  getCampaignTypes: () => {
    return async (dispatch: Dispatch<any>, getState: () => AppState) => {
      try {
        const response = await API.campaignTypes
          .getCampaignTypes();

        dispatch(createAction<Option[]>(filterConstants.SET_CAMPAIGN_TYPES, response.map(d => ({
          value: d.id,
          label: d.name,
        }))));
      } catch (e) {
        console.log('Error while get reports-types data', e);
        return Promise.reject(false);
      }
    };
  },
  selectCampaignType: (data: Option[]) => {
    return (dispatch: Dispatch<any>) => {
      dispatch(createAction<Option[]>(filterConstants.SELECT_CAMPAIGN_TYPE, data));
    };
  },
  deselectCampaignType: () => {
    return (dispatch: Dispatch<any>) => {
      dispatch(createAction<Option>(filterConstants.DESELECT_CAMPAIGN_TYPE));
    };
  },
  changeSelectingCampaignsMode: (isGroup: boolean) => {
    return (dispatch: Dispatch<any>) => {
      dispatch(createAction<boolean>(filterConstants.CHANGE_SELECTING_CAMPAIGNS_MODE, isGroup));
    };
  },
};
