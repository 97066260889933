import { Dispatch } from 'redux';
import { statisticsActions } from '../statistics/actions';
import { graphActions } from '../graphs/actions';
import { filterActions } from '../filter/actions';
import { reportsActions } from '../reports/actions';

export const commonActions = {
  getRelatedData() {
    return async (dispatch: Dispatch<any>) => {
      dispatch(filterActions.getStatuses());
      dispatch(filterActions.getTimezones());
    }
  },
  getRelatedLambdaData() {
    return async (dispatch: Dispatch<any>) => {
      dispatch(statisticsActions.getPrevTotal());
      dispatch(graphActions.getLambdaGraphData());
      dispatch(reportsActions.getLambdaReports());
    }
  },
};
