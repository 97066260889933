import React from 'react';
import { connect } from 'react-redux';
import { Select } from 'factor';
import { AppState } from '../../../../../store';
import { Option } from '../../../../../models/Option';
import { filterActions, SelectCampaignType, GetCampaignTypes } from '../../../../../store/filter/actions';

interface Props extends SelectCampaignType, GetCampaignTypes {
  isAuth: boolean;
  campaignTypes: Option[];
  selectedTypes: Option[];
}

class SelectCreativeTypeComponent extends React.Component<Props> {
  componentDidMount() {
    const { isAuth, getCampaignTypes } = this.props;
    if (isAuth) {
      getCampaignTypes();
    }
  };

  componentDidUpdate(prevProps) {
    const { isAuth, getCampaignTypes } = this.props;
    if (isAuth && (isAuth !== prevProps.isAuth)) {
      getCampaignTypes();
    }
  };

  render() {
    const { campaignTypes, selectedTypes, selectCampaignType } = this.props;
    return (
      <Select
        className="mt-4"
        onChange={selectCampaignType}
        label="Creative Type"
        placeholder="Creative Type"
        options={campaignTypes}
        closeMenuOnSelect={true}
        value={selectedTypes}
        showControlLabel={true}
        isClearable={true}
        isMulti={true}
        allSelectable={true}
        tooltipParams={{
          label: 'Filter data by the Type of the Creative used by the Campaign',
        }}
      />
    )
  }
}

const mapState = (state: AppState) => ({
  isAuth: state.auth.authorized,
  campaignTypes: state.filter.campaignTypes,
  selectedTypes: state.filter.selectedCampaignTypes,
});

const mapActions = {
  selectCampaignType: filterActions.selectCampaignType,
  getCampaignTypes: filterActions.getCampaignTypes,
};

export const SelectCreativeType = connect(mapState, mapActions)(SelectCreativeTypeComponent);
