import React from 'react';
import { connect } from 'react-redux';
import { Tooltip, ButtonCircle } from 'factor';
import './filters/styles.scss';
import { ButtonsGroupWrapper } from './filters/ButtonsGroupWrapper';
import { SelectCampaignsWrapper } from './filters/SelectCampaignsWrapper';
import { SelectStatusWrapper } from './filters/SelectStatusWrapper';
import { SelectCreativeType } from './filters/SelectCreativeTypeWrapper';
import { SearchWrapper } from './filters/SearchWrapper';
import { TableDownloadCsvButton as CSVButton, TableDownloadXlsxButton as XLSXButton } from 'iqm-framework';
import { FilterColumnsWrapper } from './filters/FilterColumnsWrapper';
import { AppState } from '../../../../store';
import { Option } from '../../../../models/Option';

interface Props {
  tableLevel: Option;
  className?: string;
}

const columnsMapper = {
  budget: { value: 'budget_total' },
};

class FiltersComponent extends React.Component<Props> {
  handleDownloadXLS = () => console.log('[clicked on download xls]')

  render() {
    const { tableLevel, className = '' } = this.props;
    const groupLevel = tableLevel.value === 'groups';
    
    return (
      <div className={`filter ${className}`}>
        <div className="filter__row">
          <div className="filter__left">
            <ButtonsGroupWrapper className={'filter__btn-group _sm'} />
            <div className="filter__selects">
              <div className="filter__select _statuses">
                <SelectStatusWrapper />
              </div>
              <div className="filter__select _types">
                <SelectCreativeType />
              </div>
              <div className="filter__select _campaigns">
                <SelectCampaignsWrapper/>
              </div>
              <div className="filter__search">
                <SearchWrapper/>
              </div>
            </div>
          </div>
          <div className="filter__right">
            <Tooltip label="Download CSV" position="bottom" auto={false}>
              <CSVButton
                columnsMapper={columnsMapper}
                fileName={groupLevel ? "Campaign Groups" : "Campaigns"}
              />
            </Tooltip>
            <Tooltip label="Download XLS" position="bottom" auto={false}>
              <XLSXButton
                columnsMapper={columnsMapper}
                fileName={groupLevel ? "Campaign Groups" : "Campaigns"}
              />
            </Tooltip>
            <div className="filter__select _columns">
              <FilterColumnsWrapper />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapState = (state: AppState) => ({
  tableLevel: state.filter.tableLevel
});
export const Filters = connect(mapState)(FiltersComponent);
