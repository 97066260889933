import { getInstance } from './index';

export function getCampaignTypes(): Promise<any> {
  return getInstance()
    .get('/creative_types/')
    .then(response => response.data)
    .catch(err => {
      console.log('err', err);
      return Promise.reject(err)
    });
}
