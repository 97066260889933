import { AdvertiserResponse } from '../models/Advertisers';
import { getInstance } from './index';
import { ParamsDTO } from '../models/Params';

export function getAdvertisers(params: ParamsDTO): Promise<AdvertiserResponse> {
  return getInstance()
    .get('/advertisers', {
      params,
    })
    .then(response => response.data)
    .catch(err => {
      console.log('err', err);
      return Promise.reject(err)
    });
}