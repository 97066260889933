import React from 'react';
import { appActions } from '../store/app/actions';
import { ReactComponent as ArrowLeftIcon } from './sidebar/arrow-left.svg';
import './sidebar/style.scss';
import { connect } from 'react-redux';
import { AppState } from '../store';

interface Props {
  sidebarOpened: boolean;
  toggleSidebar: (value: boolean) => void;
}

class SidebarComponent extends React.PureComponent<Props> {
  handleToggle = () => {
    this.props.toggleSidebar(!this.props.sidebarOpened);
  };

  calcScrollBarWidth = () => {
    const { clientWidth } = document.documentElement;
    const { innerWidth } = window;
    return innerWidth - clientWidth;
  };

  onMouseEnterHandler = () => {
		const bodyEl = document.body;
		const navBarEl = document.querySelector('.nav-bar') as HTMLElement;
		const scrollBarWidth = this.calcScrollBarWidth();
		bodyEl.style.paddingRight = `${scrollBarWidth}px`;
		bodyEl.style.overflow = 'hidden';
		if (navBarEl) {
      const navBarPaddingRight = getComputedStyle(navBarEl).paddingRight;
      if (navBarPaddingRight) {
        const navBarPaddingRightValue = +(navBarPaddingRight.replace('px', '')) || 0;
        navBarEl.style.paddingRight = navBarPaddingRightValue + scrollBarWidth + 'px';
      }
		}
	}

	onMouseLeaveHandler = () => {
		const bodyEl = document.body;
		const navBarEl = document.querySelector('.nav-bar') as HTMLElement;
		bodyEl.style.paddingRight = '';
		bodyEl.style.overflow = '';
		if (navBarEl) {
			navBarEl.style.paddingRight = '';
		}
	}

  render() {
    const {
      sidebarOpened,
      children,
    } = this.props;
    return (
      <div 
        className={`manage ${sidebarOpened ? '' : '_hidden'}`}
        onMouseEnter={this.onMouseEnterHandler}
				onMouseLeave={this.onMouseLeaveHandler}>
        <button className="manage__arrow" onClick={this.handleToggle}>
          <ArrowLeftIcon/>
        </button>
        <div className="manage__container">
          <div className="manage__main">
            {children}
          </div>
        </div>
      </div>
    )
  }
}

const mapState = (state: AppState) => {
  return {
    sidebarOpened: state.app.sidebarOpened,
  }
};
const mapActions = {
  toggleSidebar: appActions.toggleSidebar,
};

export const Sidebar = connect(mapState, mapActions)(SidebarComponent);
