import React from 'react';
import { connect } from 'react-redux';
import { IQMRouter } from 'iqm-framework';

import './scss/index.scss';
import { User } from './models/User';
import { authActions, Authorized, Logout } from './store/auth/actions';
import { BASE_URL } from './config';
import { Dashboard } from './pages/Dashboard';

type Props = Authorized & Logout;

const AppComponent = (props: Props) => {
  const { authorized, logout } = props;

  const onSuccessLogin = (data: User) => {
    authorized(data);
  };

  const onLogout = () => logout();

  return (
    <IQMRouter
      documentTitle="Dashboard | IQM Corporation"
      successLoginRedirectPath={BASE_URL}
      onSuccessLogin={onSuccessLogin}
      onLogout={onLogout}
    >
      <Dashboard />
    </IQMRouter>
  );
};

const mapAction = {
  authorized: authActions.authorized,
  logout: authActions.logout,
};

export const App = connect(null, mapAction)(AppComponent);
