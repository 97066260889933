export enum CampaignStatusType {
  all = 'all',
  budgetPaused = 'budget_paused',
  deleted = 'deleted',
  draft = 'draft',
  expired = 'expired',
  pending = 'pending',
  running = 'running',
  paused = 'paused',
}

export const statusMap = {
  [CampaignStatusType.running]: 'Running',
  [CampaignStatusType.paused]: 'Paused',
  [CampaignStatusType.budgetPaused]: 'Budget Paused',
  [CampaignStatusType.pending]: 'Pending',
  [CampaignStatusType.draft]: 'Draft',
  [CampaignStatusType.expired]: 'Expired',
  [CampaignStatusType.deleted]: 'Deleted',
  [CampaignStatusType.all]: 'All',
};

export type CampaignStatus = {
  [key in CampaignStatusType]: number;
}

export interface Campaign {
  bid: number;
  bidImpressions: number;
  budget: number;
  campaignType: string;
  click: number;
  conversion: number;
  creativeType: number;
  budgetDay: number;
  eCTR: number;
  eCVR: number;
  endTime: number;
  campaignId;
  impression: number;
  campaignName: string;
  startTime: number;
  status: CampaignStatusType;
  timezone: string;
  winRate: number;
  rowClassName?: string;
  total?: boolean;
  campaignTimezone: string;
  budgetPacing?: string;
}


export interface CampaignDTO {
  campaign_name: string;
  campaign_url: string;
  creative_type: number;
  max_bid: number;
  start_date: number;
  timezone: object;
  total_budget: number;
  bid_pacing: 0 | 1;
  total_budget_pacing: 0 | 1;
}

export interface UpdateCampaignsEndDate {
  campaignIds: string;
  endDate: number;
  advertiserId: number;
  dspId: number;
}

export function convertCampaignToDTO(campaign: Campaign): CampaignDTO {
  return {
    campaign_name: campaign.campaignName,
    campaign_url: 'https://abc.com',
    creative_type: campaign.creativeType,
    max_bid: campaign.bid,
    start_date: campaign.startTime,
    timezone: {name: 'UTC', id: 11, label: 'UTC'},
    total_budget: campaign.budget,
    total_budget_pacing: 0,
    bid_pacing: 1,
  }
}
