import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createPortal } from 'react-dom';
import { Dialog } from 'factor';
import { BtnMenu } from './components/BtnMenu';
import { RunningDialogContent } from './components/RunningDialogContent';
import { pluralize } from '../../../../../../utils/pluralize';
import { AppState } from '../../../../../../store';
import { Campaign } from '../../../../../../models/Campaign';

interface Props {
  selectedCampaigns: Campaign[],
}

export const RestoreButtonComponent = (props: Props) => {
  const [dialogIsOpen, setDialogState] = useState(false);

  const dialog = (
    <Dialog
      open={dialogIsOpen}
      onClickOutside={() => setDialogState(false)}>
      <RunningDialogContent
        label={`Restore ${pluralize('Campaign', props.selectedCampaigns.length)}`}
        action='restore'
        buttonLabel='Restore'
        onClose={() => setDialogState(false)} />
    </Dialog>
  );

  return (
    <React.Fragment>
      <BtnMenu
        isOpen={false}
        open={() => setDialogState(true)}
        close={() => { }}
        className="filter__btn"
        content={null}
      >
        Restore
      </BtnMenu>
      {createPortal(dialog, document.body)}
    </React.Fragment>
  );
}

const mapState = (state: AppState) => ({
  selectedCampaigns: state.table.selectedTableCampaigns,
});

export const RestoreButton = connect(mapState)(RestoreButtonComponent);
