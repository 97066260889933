import React from 'react';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import isEqual from 'lodash/isEqual';
import { Button, Dialog, Tooltip, Icon } from 'factor';

import { AppState } from '../../../store';
import { Option } from '../../../models/Option';
import { FilterState, defaultFilterState } from '../../../store/filter/reducers';
import { filterActions } from '../../../store/filter/actions';
import { tableActions, SetFilteredCampaignsIds } from '../../../store/table/actions';
import { reportsActions } from '../../../store/reports/actions';
import { defaultReportState } from '../../../store/reports/reducers';
import { ResetDialogContent } from './resetButton/ResetDialogContent';

import './resetButton/styles.scss';

interface Props extends SetFilteredCampaignsIds {
  filters: FilterState;
  userTimezone: string;
  selectedDimension: Option | null;
  selectedMetric: Option;
  filteredCampaignsIds: number[];
  updateFilters: (data: FilterState) => void;
  changeMetric: (metric: Option) => void;
  changeDimension: (dimension: Option) => void;
}

interface State {
  dialogIsOpen: boolean;
}

class ResetButtonComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      dialogIsOpen: false,
    };
  }

  getStartDate = tz => {
    return moment.tz(moment(), tz).startOf('day').unix();
  };

  getEndDate = tz => {
    return moment.tz(moment(), tz).endOf('day').unix();
  };

  get defaultFilters() {
    const { filters, userTimezone } = this.props;

    return {
      timezone: filters.timezones.find(tz => tz.value === userTimezone) || filters.timezone,
      dateRange: filters.initialDateRange ? {
        start: moment(filters.initialDateRange.start).unix(),
        end: moment(filters.initialDateRange.end).unix(),
      } : null,
      tableLevel: defaultFilterState.tableLevel,
      status: defaultFilterState.status.value,
      sortingColumns: defaultFilterState.sortingColumns,
      sortingGroupsColumns: defaultFilterState.sortingGroupsColumns,
      selectedCampaignTypes: defaultFilterState.selectedCampaignTypes,
      search: defaultFilterState.search,
      graph1: defaultFilterState.graph1,
      graph2: defaultFilterState.graph2,
    };
  };

  get updatedFilters() {
    const { filters } = this.props;
    return {
      timezone: filters.timezone,
      dateRange: filters.dateRange ? {
        start: moment(filters.dateRange.start).unix(),
        end: moment(filters.dateRange.end).unix(),
      } : null,
      tableLevel: filters.tableLevel,
      status: filters.status.value,
      sortingColumns: filters.sortingColumns,
      sortingGroupsColumns: filters.sortingGroupsColumns,
      selectedCampaignTypes: filters.selectedCampaignTypes,
      search: filters.search,
      graph1: filters.graph1,
      graph2: filters.graph2,
    }
  };

  openDialog = () => {
    this.setState({ dialogIsOpen: true });
  };

  closeDialog = () => {
    this.setState({ dialogIsOpen: false });
  };

  handleReset = () => {
    this.props.updateFilters({
      ...this.props.filters,
      ...this.defaultFilters,
      dateRange: null,
      status: this.props.filters.statusOptions.find(s => s.value === defaultFilterState.status.value) || defaultFilterState.status,
    });
    this.props.changeMetric(defaultReportState.metric);
    this.props.changeDimension(defaultReportState.dimension);
    this.props.setFilteredCampaignsIds([]);
    this.closeDialog();
  };

  render() {
    const { filteredCampaignsIds, selectedDimension, selectedMetric } = this.props;
    const { dialogIsOpen } = this.state;
    const showResetButton = !!filteredCampaignsIds.length
      || !isEqual(selectedDimension, defaultReportState.dimension)
      || !isEqual(selectedMetric, defaultReportState.metric)
      || !isEqual(this.defaultFilters, this.updatedFilters);

    const dialog = <Dialog
      open={dialogIsOpen}
      onClickOutside={this.closeDialog}>
      <ResetDialogContent
        onReset={this.handleReset}
        onClose={this.closeDialog}
      />
    </Dialog>;

    return showResetButton ? (
      <React.Fragment>
        <Tooltip label="Reset all the Dashboard parameters to default values">
          <Button
            className="btn btn-square btn-reset _gray"
            onClick={this.openDialog}>
            <Icon name="Refresh" />
            Reset Dashboard
          </Button>
        </Tooltip>
        {createPortal(dialog, document.body)}
      </React.Fragment>
    ) : null;
  }
}

const mapState = (state: AppState) => ({
  loading: state.dashboards.loading,
  dashboardsLength: state.dashboards.dashboards.length,
  selectedDimension: state.reports.dimension,
  selectedMetric: state.reports.metric,
  filters: state.filter,
  filteredCampaignsIds: state.table.filteredCampaignsIds,
  userTimezone: state.auth.timezoneName,
});

const mapActions = {
  updateFilters: filterActions.updateFilters,
  changeMetric: reportsActions.changeReportMetric,
  changeDimension: reportsActions.changeReportDimension,
  setFilteredCampaignsIds: tableActions.setFilteredCampaignsIds,
};

export const ResetButton = connect(mapState, mapActions)(ResetButtonComponent);
