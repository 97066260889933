import { Dispatch } from 'redux';
import moment from 'moment';
import uniqueId from 'lodash/uniqueId';
import get from 'lodash/get';
import { GraphLambdaResponse } from '../../models/Graph';
import { createAction } from '../../utils/actions';
import { graphConstants } from './constants';
import { AppState } from '..';
import { lambdaAPI } from '../../api/lambdaAPI';
import { sortingColumnOptions } from '../../models/Option';
import { LambdaResponse } from '../../models/Response';
import { toastConstants } from '../toast/constants';

const SESSION_ID = uniqueId();
const MIN_DATA_ITEMS = 12;
const MAX_DATA_ITEMS = 24;

export const graphActions = {
  getLambdaGraphData() {
    return async (dispatch: Dispatch, getState: () => AppState) => {
      try {
        dispatch(createAction<GraphLambdaResponse[]>(graphConstants.LOADING_GRAPH__START))
        const state = getState();
        const { auth } = state;
        const { dateRange, timezone, selectedCampaignTypes, status, search } = state.filter;
        const { filteredCampaignsIds } = state.table;
        
        if (dateRange) {
          let { start, end } = dateRange;
          start = moment(start);
          end = moment(end);

          const diff = end.diff(start, 'days');

          const query = {
            columns: sortingColumnOptions,
            total_agg: true,
            dateRange: dateRange ? {
              startDate: `${start.valueOf()}`,
              endDate: `${end.valueOf()}`,
            } : null,
            timezone: {
              label: timezone ? timezone.label : null,
              value: timezone ? timezone.value : null,
              id: timezone ? timezone.id : null,
            },
            dimension: {
              filter: {  
                value: 'campaign',
                label: 'Campaign',
              },
              value: filteredCampaignsIds.map(id => ({ value: id })),
            },
            user_session_id: `${auth.apiToken}_${SESSION_ID}`,
            sort_by: 'date',
            sort_type: 'asc',
            filters: {
              campaign_status: status.value,
              creative_type_ids: selectedCampaignTypes.map(c => c.value).join(','),
            },
            search_field: search,
            result_type: 'data',
            is_graph: true,
          };
          const response = await lambdaAPI.graph.get(query);
          dispatch(createAction<LambdaResponse[]>(graphConstants.LAMBDA_LOADING_GRAPH__SUCCESS, response.data));
        }
      } catch (e) {
        const errorStatus = get(e, 'response.status', null);
        dispatch(createAction(graphConstants.LOADING_GRAPH__ERROR, e));
        if (errorStatus === 500) {
          dispatch(createAction(toastConstants.TOAST_OPEN, 'Something went wrong. Please try again over a few minutes.'));
        }
        return Promise.reject(e);
      }
    }
  }
};
