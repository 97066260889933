import React from 'react';
import { BtnMenu } from './components/BtnMenu';
import { ReactComponent as ArrowDownIcon } from '../../../../../../svg/arrow-down.svg';
interface Props {
  disabled: boolean;
  clicked: () => void;
}

export const OptimizeButton = (props: Props) => (
  <BtnMenu
    isOpen={false}
    open={props.clicked}
    close={() => {}}
    className="filter__btn"
    content={null}
    disabled={props.disabled}
    tooltipParams={{
      label: 'Optimize the selected Campaign (opens in new tab)',
    }}
  >
    {/* <span className="btn-round__prefix">
      <ArrowDownIcon />
    </span> */}
    Optimize
  </BtnMenu>
);
