import { reducerFromMap } from '../../utils/actions';
import { Action } from '../../models/Action';
import { tableConstants } from './constants';
import { TableNameUpdateParams, TableResponse } from '../../models/Table';
import { Campaign } from '../../models/Campaign';
import { TableSortingParams } from '../../models/Table';
import { LambdaResponse } from '../../models/Response';


export interface TableState {
  loading: boolean;
  error: string;
  response: TableResponse;
  selectedTableCampaigns: ({ campaign_id: string } & any)[];
  filteredCampaigns: LambdaResponse[];
  filteredCampaignsIds: number[];
  filteredGroupsIds: number[];
  sorting?: TableSortingParams;
  data: LambdaResponse[];
  campaignsList: LambdaResponse[];
}

const defaultTableState: TableState = {
  loading: false,
  error: '',
  // TODO: Can we remove it?
  response: {
    data: [],
  },
  selectedTableCampaigns: [],
  filteredCampaigns: [],
  filteredCampaignsIds: [],
  filteredGroupsIds: [],
  data: [],
  campaignsList: [],
};

function loadingStart(state: TableState): TableState {
  return {
    ...state,
    loading: true,
  };
}

function loadingSuccess(state: TableState, action: Action<TableResponse>): TableState {
  return {
    ...state,
    loading: false,
    error: '',
    response: {
      ...state.response,
      ...action.payload,
    }
  };
}

function loadingError(state: TableState, action: Action<string>): TableState {
  return {
    ...state,
    loading: false,
    error: action.payload,
    response: {
      ...state.response,
      data: [],
    }
  };
}

function updateCampaignName(state: TableState, action: Action<TableNameUpdateParams>): TableState {
  const { data, selectedTableCampaigns } = state;
  const updatedData = data.map(el => {
    if (+el.campaignId === action.payload.id) {
      el.campaignName = action.payload.name;
    }
    return el;
  });
  const updatedSelectedCampaigns = selectedTableCampaigns.map(el => {
    if (+el.campaignId === action.payload.id) {
      el.campaignName = action.payload.name;
    }
    return el;
  });
  return {
    ...state,
    loading: false,
    data: updatedData,
    selectedTableCampaigns: updatedSelectedCampaigns,
  };
}

function addSelectedCampaign(state: TableState, action: Action<Campaign[]>): TableState {
  return {
    ...state,
    selectedTableCampaigns: [...action.payload],
  };
}

function removeSelectedCampaign(state: TableState, action: Action<Campaign>): TableState {
  const { selectedTableCampaigns } = state;
  const index = selectedTableCampaigns.findIndex(c => c.campaignId === action.payload.campaignId);
  if (index !== -1) {
    selectedTableCampaigns.splice(index, 1);
  }
  return {
    ...state,
    selectedTableCampaigns: [
      ...selectedTableCampaigns,
    ],
    response: {
      ...state.response,
      data: state.response.data.map(d => ((action.payload.campaignId === d.campaignId)
        ? { ...d, rowClassName: '' }
        : { ...d }
      ))
    }
  };
}

function clearSelectedCampaigns(state: TableState): TableState {
  return {
    ...state,
    selectedTableCampaigns: [],
    response: {
      ...state.response,
      data: state.response.data.map(({ rowClassName, ...rest }) => ({
        ...rest,
      })),
    },
  };
}

function selectAllCampaigns(state: TableState): TableState {
  const selectedTableCampaigns = [...state.response.data];

  return {
    ...state,
    selectedTableCampaigns,
    response: {
      ...state.response,
      data: state.response.data.map(d => ({
        ...d,
        rowClassName: '_selected'
      })),
    },
  };
}

function updateSortingParams(state: TableState, action: Action<TableSortingParams>): TableState {
  return {
    ...state,
    sorting: action.payload,
  };
}

function clearCampaignsData(state: TableState): TableState {
  return {
    ...state,
    selectedTableCampaigns: [],
    response: {
      data: [],
    },
  };
}

function setFilteredCampaigns(state: TableState, action: Action<LambdaResponse[]>): TableState {
  return {
    ...state,
    filteredCampaigns: action.payload,
  };
}

function setFilteredCampignsIds(state: TableState, action: Action<number[]>): TableState {
  const idsSet = new Set(action.payload);
  const filteredCampaignsIds = [...idsSet];
  return {
    ...state,
    filteredCampaignsIds,
    filteredCampaigns: state.data.filter(campaign => campaign.campaignId && filteredCampaignsIds.includes(+campaign.campaignId)),
  };
}

function setFilteredGroupsIds(state: TableState, action: Action<number[]>): TableState {
  const idsSet = new Set(action.payload);
  const filteredGroupsIds = [...idsSet];
  return {
    ...state,
    filteredGroupsIds,
  };
}

function setCampaigns(state: TableState, action: Action<LambdaResponse[]>): TableState {
  return {
    ...state,
    data: action.payload,
  };
}

function updateCampignsList(state: TableState, action: Action<LambdaResponse[]>): TableState {
  return {
    ...state,
    campaignsList: action.payload,
  };
}

const reducer = reducerFromMap(
  defaultTableState,
  {
    [tableConstants.TABLE_LOADING__START]: loadingStart,
    [tableConstants.TABLE_LOADING__SUCCESS]: loadingSuccess,
    [tableConstants.TABLE_LOADING__ERROR]: loadingError,
    [tableConstants.TABLE_UPDATE_CAMPAIGN_NAME]: updateCampaignName,
    [tableConstants.TABLE_ADD_SELECTED_CAMPAIGN]: addSelectedCampaign,
    [tableConstants.TABLE_REMOVE_SELECTED_CAMPAIGN]: removeSelectedCampaign,
    [tableConstants.TABLE_CLEAR_SELECTED_CAMPAIGN]: clearSelectedCampaigns,
    [tableConstants.TABLE_UPDATE_SORTING_PARAMS]: updateSortingParams,
    [tableConstants.TABLE_SELECT_ALL_CAMPAIGNS]: selectAllCampaigns,
    [tableConstants.TABLE_CLEAR_CAMPAIGNS_DATA]: clearCampaignsData,
    [tableConstants.TABLE_SET_FILTERED_CAMPAIGNS]: setFilteredCampaigns,
    [tableConstants.TABLE_SET_FILTERED_CAMPAIGNS_IDS]: setFilteredCampignsIds,
    [tableConstants.TABLE_SET_FILTERED_GROUPS_IDS]: setFilteredGroupsIds,
    [tableConstants.TABLE_SET_CAMPAIGNS]: setCampaigns,
    [tableConstants.UPDATE_CAMPAIGNS_LIST]: updateCampignsList,
  }
);

export const table = (state: TableState = defaultTableState, action: Action<any>) => reducer(state, action);
