import { Dispatch } from 'redux';
import { createAction } from '../../utils/actions';
import { campaingGroupsConstants } from './constants';
import { API } from '../../api';
import { AppState } from '../index';
import { AdvertiserIdParams, GetState } from '../../models/Common';
import { CampaignCreateParams, convertCampaignGroupDTOtoCampaignGroup, convertSingleCampaignGroupDTOtoCampaignGroup, GroupsList } from '../../models/CampaignGroup';
import { CampaignGroup } from '../../models/CampaignGroup';

export const campaignGroupsActions = {
  getCampaignGroupsList: function () {
    return async (dispatch: Dispatch<any>, getState: () => AppState) => {
      dispatch(createAction<void>(campaingGroupsConstants.LOAD_CAMPAIGN_GROUPS__START));
      const state = getState();
      const { auth } = state;

      try {
        const response = await API.FilteredCampaignGroups
          .getGroupsWithCampaigns({
            advertiserId: auth.userId as number,
            dspId: auth.dspId as number,
            includeChild: 1,
          });

        const data = convertCampaignGroupDTOtoCampaignGroup(response.responseObject);

        dispatch(createAction<GroupsList[]>(campaingGroupsConstants.LOAD_CAMPAIGN_GROUPS__SUCCESS, data));
      } catch (e) {
        dispatch(createAction<void>(campaingGroupsConstants.LOAD_CAMPAIGN_GROUPS__ERROR));
      }
    };
  },
  selectGroup: function (params: AdvertiserIdParams) {
    return (dispatch: Dispatch<any>) => {
      dispatch(createAction<number>(campaingGroupsConstants.CAMPAIGN_GROUPS__SELECT, params.advertiserId));
    };
  },
  resetGroup: function () {
    return (dispatch: Dispatch<any>) => {
      dispatch(createAction<number>(campaingGroupsConstants.CAMPAIGN_GROUPS__RESET));
    };
  },
  checkGroup: function (data: CampaignGroup) {
    return (dispatch: Dispatch<any>) => {
      dispatch(createAction<CampaignGroup>(campaingGroupsConstants.CAMPAIGN_GROUPS__CHECK, data));
    };
  },
  uncheckGroup: function (data: CampaignGroup) {
    return (dispatch: Dispatch<any>) => {
      dispatch(createAction<CampaignGroup>(campaingGroupsConstants.CAMPAIGN_GROUPS__UNCHECK, data));
    };
  },
  createCampaignGroup: function (name: string) {
    return async (dispatch: Dispatch<any>, getState: GetState) => {
      const { auth } = getState();
      
      if (!auth.userId || !auth.dspId) {
        return;
      }

      const data: CampaignCreateParams = {
        name,
        dspId: auth.dspId,
        advertiserId: auth.userId,
        parentId: '0',
      };

      try {
        const response = await API.campaignGroups.createCampaignGroup(data);
        const group = convertSingleCampaignGroupDTOtoCampaignGroup(response.responseObject); 
        dispatch(createAction<GroupsList>(campaingGroupsConstants.CAMPAIGN_CREATE_GROUP, group));
        return group;
      } catch (e) {
        console.log(e);
      }
    };
  },
};
