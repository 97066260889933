import React from 'react';
import { connect } from 'react-redux';
import uniqueId from 'lodash/uniqueId';
import isEqual from 'lodash/isEqual';
import moment from 'moment';

import { Filters } from './tableWrapper/Filters';
import './tableWrapper/styles.scss';
import { Table, TableComponentInstance } from './tableWrapper/Table';
import { AppState } from '../../../store';
import { Option, sortingColumnOptions, sortingGroupsColumnOptions, StickedOption } from '../../../models/Option';
import { TableBaseRoot } from 'iqm-framework';
import { ChangeSortingColumns, filterActions } from '../../../store/filter/actions';
import { TableLevel } from '../../../store/filter/reducers';
import { IndicatorsContainer } from './IndicatorsContainer';
import { SelectedCampaigns } from './tableWrapper/filters/SelectedCampaigns';
import { LAMBDA_API_URL } from '../../../config';
import { statisticsActions } from '../../../store/statistics/actions';
import { commonActions } from '../../../store/common/actions';
import { tableActions } from '../../../store/table/actions';

const SESSION_ID = uniqueId();

interface Props extends ChangeSortingColumns {
  isAuth: boolean;
  className?: string;
  tableLevel: Option;
  value: StickedOption[];
  options: StickedOption[];
  dataRequest: any;
  transformCSVDownloadRequest: () => {};
  selectedTableCampaigns: ({ campaignId: string } & any)[];
  getPrevTotal: () => void;
  getRelatedLambdaData: () => void;
  clearSelected: () => void;
}

class TableWrapperComponent extends React.Component<Props> {
  handleChange = (value) => {
    (this.props.tableLevel.value === TableLevel.Campaigns)
      ? this.props.changeSortingColumns(value)
      : this.props.changeSortingGroupsColumns(value);
  };

  componentDidUpdate(prevProps) {
    if (TableComponentInstance && !isEqual(prevProps.dataRequest, this.props.dataRequest)) {
      this.props.clearSelected();
      TableComponentInstance.cancelDataRequest();
      TableComponentInstance.setState({
        loadingData: false,
      }, () => {
        TableComponentInstance.getNewData();
        if ((prevProps.tableLevel.value === this.props.tableLevel.value)) {
          this.props.getRelatedLambdaData();
        }
      });
    }
  };

  componentDidMount() {
    const { isAuth } = this.props;

    if (isAuth) {
      this.props.getRelatedLambdaData();
    }
  };

  render() {
    const { className, value, options, dataRequest, transformCSVDownloadRequest, selectedTableCampaigns } = this.props;

    return (
      <div className={className}>
        <TableBaseRoot
          allColumns={options}
          selectedColumns={value}
          onColumnsChanged={this.handleChange}
          dataRequest={{
            ...dataRequest,
            data: {
              ...dataRequest.data,
            }
          }}
          transformCSVDownloadRequest={transformCSVDownloadRequest}
        >
          <Filters className={'dashboard__filter'} />
          <IndicatorsContainer className={'dashboard__indicators'} />
          <div className={`filter__row filter__row_table pt-3 pb-3 ${selectedTableCampaigns.length ? '_visible' : ''}`}>
            <SelectedCampaigns />
          </div>
          <Table />
        </TableBaseRoot>
      </div>
    );
  }
}

const mapState = (state: AppState) => {
  const {
    filter: {
      tableLevel,
      status,
      dateRange,
      search,
      sortingColumns,
      sortingGroupsColumns,
      selectedCampaignTypes,
      timezone,
    },
    auth,
    table: {
      filteredCampaignsIds,
      filteredGroupsIds,
      selectedTableCampaigns,
    },
  } = state;

  const groupsLevel = tableLevel.value === TableLevel.Groups;

  let query = {
    columns: groupsLevel ? sortingGroupsColumnOptions : sortingColumnOptions,
    total_agg: false,
    dateRange: dateRange ? {
      startDate: `${moment(dateRange.start).valueOf()}`,
      endDate: `${moment(dateRange.end).valueOf()}`,
    } : null,
    timezone: {
      label: timezone ? timezone.label : null,
      value: timezone ? timezone.value : null,
      id: timezone ? timezone.id : null,
    },
    dimension: {
      filter: {
        value: groupsLevel ? 'campaign_group' : 'campaign',
        label: groupsLevel ? 'Campaign group' : 'Campaign',
      },
      value: groupsLevel ? filteredGroupsIds.map(id => ({ value: id })) : filteredCampaignsIds.map(id => ({ value: id })),
    },
    user_session_id: `${auth.apiToken}_${SESSION_ID}`,
    search_field: search,
    filters: {
      campaign_status: status.value,
      creative_type_ids: selectedCampaignTypes.map(c => c.value).join(','),
    },
    no_of_entries: 50,
  };

  const request = {
    url: `${LAMBDA_API_URL}/resultDashboard`,
    method: 'post',
    data: query,
  };

  const visibleValue = (tableLevel.value === TableLevel.Campaigns) ? sortingColumns : sortingGroupsColumns;
  const visibleSortingColumnOptions = (tableLevel.value === TableLevel.Campaigns)
    ? sortingColumnOptions
    : sortingGroupsColumnOptions;

  return {
    tableLevel: state.filter.tableLevel,
    dataRequest: request,
    transformCSVDownloadRequest: () => ({
      ...request,   
      data: {
        ...query,
        status: status.value,
        total_count: TableComponentInstance ? TableComponentInstance.state.totalItems : 99999,
        draw: 1,
        pgno: 1,
        dataPath: 'data',
      },
      dateRange: dateRange ? {
        start: moment(dateRange.start).unix(),
        end: moment(dateRange.end).unix(),
      } : null,
      name: `${auth.firstName || ''} ${auth.lastName || ''}`,
      headers: {
        'Base-Url': state.auth.domainURL,
      },
      fileName: groupsLevel ? 'Campaign Groups' : 'Campaigns',
    }),
    value: visibleValue,
    options: visibleSortingColumnOptions,
    columns: [],
    selectedTableCampaigns,
    isAuth: auth.authorized,
  };
};

const mapAction = {
  changeSortingColumns: filterActions.changeSortingColumns,
  changeSortingGroupsColumns: filterActions.changeSortingGroupsColumns,
  getPrevTotal: statisticsActions.getPrevTotal,
  getRelatedLambdaData: commonActions.getRelatedLambdaData,
  clearSelected: tableActions.clearSelectedCampaigns,
};
export const TableWrapper = connect(mapState, mapAction)(TableWrapperComponent);
