import React from 'react';
import { TableSortedOptions } from 'iqm-framework';
import { StickedOption, Option } from '../../../../../models/Option';
import { connect } from 'react-redux';
import { AppState } from '../../../../../store';
import { ChangeSortingColumns, filterActions } from '../../../../../store/filter/actions';
import { pluralize } from '../../../../../utils/pluralize';

interface Props extends ChangeSortingColumns {
  value: StickedOption[];
  groupsValue: StickedOption[];
  tableLevel: Option;
}

class FilterColumnsWrapperComponent extends React.PureComponent<Props> {
  handleOnChange = (options) => {
    console.log(options);
  };
  render() {
    return (
      <TableSortedOptions
        onChange={this.handleOnChange}
        selectProps={{
          showControlLabel: false,
        }}
      />
    );
  }
}

const mapState = (state: AppState) => {
  return {
    value: state.filter.sortingColumns,
    groupsValue: state.filter.sortingGroupsColumns,
    tableLevel: state.filter.tableLevel,
  };
};

const mapActions = {
  changeSortingColumns: filterActions.changeSortingColumns,
  changeSortingGroupsColumns: filterActions.changeSortingGroupsColumns,
};
export const FilterColumnsWrapper = connect(mapState, mapActions)(FilterColumnsWrapperComponent);
