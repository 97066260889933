import React from 'react';
import { Select } from 'factor';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { AppState } from '../../../../../store';
import { OptionID } from '../../../../../models/Option';
import { tableActions, SetFilteredCampaignsIds, SetFilteredGroupsIds } from '../../../../../store/table/actions';
import { LambdaResponse } from '../../../../../models/Response';
import { reportsActions } from '../../../../../store/reports/actions';
import { campaignGroupsActions } from '../../../../../store/campaignGroups/actions';
import { DateRange } from '../../../../../store/filter/reducers';
import { API } from '../../../../../api/index';
import { Dashboard } from '../../../../../models/Dashboards';
import { GroupsList } from '../../../../../models/CampaignGroup';
import { filterActions, ChangeSelectingCampaignsMode } from '../../../../../store/filter/actions';

interface SelectedIdsCollection {
  groups: number[];
  campaigns: number[];
}

interface Props extends SetFilteredCampaignsIds,
  ChangeSelectingCampaignsMode,
  SetFilteredGroupsIds {
  groupsList: GroupsList[];
  advertiserId: number;
  dspId: number;
  filteredCampaignsIds: number[];
  dateRange: DateRange;
  timezone: OptionID | null;
  dashboard: Dashboard | null;
  showGroups: boolean;
  select: (campaign: LambdaResponse) => void;
  removeSelectAll: () => void;
  getLambdaReports: () => void;
  getCampaignGroupsList: () => void;
  selectedGroupId: number,
}

interface State {
  campaigns: OptionID[];
  selectedOptions: OptionID[];
}

class SelectCampaignsWrapperComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      campaigns: [],
      selectedOptions: [],
    };
  }

  componentDidMount() {
    if (this.props.dspId && this.props.advertiserId) {
      this.fetchCampaignsList();
      this.fetchGroupsList();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.advertiserId !== this.props.advertiserId) {
      this.fetchCampaignsList();
      this.fetchGroupsList();
    }

    if (!isEqual(prevProps.filteredCampaignsIds, this.props.filteredCampaignsIds)) {
      this.props.getLambdaReports();
      if (!this.props.filteredCampaignsIds.length) {
        this.setState({
          selectedOptions: [],
        });
      }
    }

    if (!isEqual(prevProps.dashboard, this.props.dashboard)) {
      const { showGroups, filteredCampaignsIds } = this.props;
      const { campaigns } = this.state;
      const filteredCampaignsFromDashboard = showGroups
        ? this.getCampaignsFromGroupsList(filteredCampaignsIds)
        : campaigns.filter(campaign => filteredCampaignsIds.includes(campaign.id));
      this.setState({
        selectedOptions: filteredCampaignsFromDashboard,
      });
    }

    // filter table by selected campaigns group
    if (!isEqual(prevProps.selectedGroupId, this.props.selectedGroupId)) {
      const { selectedGroupId, groupsList, showGroups } = this.props;
      let selectedOptions: OptionID[] = [];
      if (selectedGroupId) {
        // get selected group by id
        const selectedGroup = groupsList.find(i => i.value === +selectedGroupId);
        if (selectedGroup) {
          if (showGroups) {
            // add id to selected group and push it to selected options
            selectedOptions = [{ ...selectedGroup, id: +selectedGroupId }];
          }
          if (selectedGroup.options) {
            // add selected group options to selected options, setting each option value to its id if showGroups does not turn on
            selectedOptions = [
              ...selectedOptions,
              ...selectedGroup.options.map(i => ({ ...i, value: showGroups ? i.value : i.id })),
            ];
          }
        }
      }
      this.onChangeHandler(selectedOptions);
    }
  }

  getCampaignsFromGroupsList = (ids: number[]): OptionID[] => {
    const { groupsList } = this.props;
    let filteredCampaigns: OptionID[] = [];

    for (let group of groupsList) {
      group.options && group.options.forEach(opt => {
        if (ids.includes(opt.id)) {
          filteredCampaigns.push(opt);
        };
      });

      ids.includes(group.value) && filteredCampaigns.push({
        ...group,
        id: group.value,
      });
    }

    return filteredCampaigns;
  }

  fetchGroupsList = () => {
    const { getCampaignGroupsList } = this.props;
    getCampaignGroupsList();
  };

  fetchCampaignsList = () => {
    const { advertiserId, dspId } = this.props;

    API.campaignsList.getCampaignsList({ advertiserId, dspId })
      .then(res => {
        this.setState({
          campaigns: this.convertCampaignsToOptions(res.data),
        });
      })
      .catch(err => console.log('getting campaigns list was failed ', err))
  }

  convertCampaignsToOptions = (campaigns: { name: string, id: number }[]): OptionID[] => {
    return campaigns.map(campaign => ({
      label: campaign.name || '',
      value: campaign.id,
      id: campaign.id,
    }));
  };

  onChangeHandler = (options: OptionID[]) => {
    const { showGroups } = this.props;

    const selectedIds = options.reduce((acc: SelectedIdsCollection, opt: OptionID) => {
      opt.id ? acc.campaigns.push(opt.id) : acc.groups.push(opt.value);
      return acc;
    }, { groups: [], campaigns: [] });

    const transformedOptions = showGroups ? this.getCampaignsFromGroupsList([
      ...selectedIds.groups,
      ...selectedIds.campaigns,
    ]) : options;
    this.props.setFilteredCampaignsIds(selectedIds.campaigns);
    this.props.setFilteredGroupsIds(selectedIds.groups);
    this.setState({
      selectedOptions: transformedOptions,
    });
  };

  onTumblerChangeHandler = () => {
    const { setFilteredCampaignsIds, setFilteredGroupsIds, showGroups, changeSelectingCampaignsMode } = this.props;
    setFilteredCampaignsIds([]);
    setFilteredGroupsIds([]);
    this.setState({
      selectedOptions: [],
    });
    changeSelectingCampaignsMode(!showGroups);
  };

  render() {
    const { groupsList, showGroups } = this.props;
    const { campaigns, selectedOptions } = this.state;

    return (
      <Select
        className="mt-4"
        value={selectedOptions}
        options={showGroups ? groupsList : campaigns}
        onChange={this.onChangeHandler}
        label="Campaigns"
        placeholder="Campaigns"
        showControlLabel={true}
        withCheckbox={true}
        isMulti={true}
        isSearchable={true}
        tumbler={true}
        allSelectable={true}
        isClearable={true}
        tumblerIsActive={showGroups}
        onTumblerChange={this.onTumblerChangeHandler}
        searchByValue={true}
        tooltipParams={{
          label: 'Search and filter data by the specific Campaigns or Campaign Groups',
        }}
      />
    );
  }
}

const mapState = (state: AppState) => {
  return {
    groupsList: state.campaignGroups.data,
    selectedGroupId: state.campaignGroups.selectedId,
    advertiserId: state.auth.userId,
    dspId: state.auth.dspId,
    filteredCampaignsIds: state.table.filteredCampaignsIds,
    dateRange: state.filter.dateRange,
    timezone: state.filter.timezone,
    showGroups: state.filter.isSelectingCampaignsByGroup,
    dashboard: state.dashboards.selectedDashboard,
  }
};

const mapActions = {
  select: tableActions.addSelectedCampaign,
  removeSelectAll: tableActions.clearSelectedCampaigns,
  setFilteredCampaignsIds: tableActions.setFilteredCampaignsIds,
  setFilteredGroupsIds: tableActions.setFilteredGroupsIds,
  getLambdaReports: reportsActions.getLambdaReports,
  getCampaignGroupsList: campaignGroupsActions.getCampaignGroupsList,
  changeSelectingCampaignsMode: filterActions.changeSelectingCampaignsMode,
};
export const SelectCampaignsWrapper = connect(mapState, mapActions)(SelectCampaignsWrapperComponent);
