import moment from 'moment';

export const DATE_FORMAT = 'MM/DD/YYYY';
export const TIME_FORMAT = 'hh:mm';

export const getMomentDate = (date: Date | number) => {
  return moment(date);
};

export const convertDateToMoment = (date): moment.Moment => {
  if (date instanceof moment) {
    return date as moment.Moment;
  }
  return moment(date);
};
