import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { DialogContent, Button } from 'factor';
import { TimezoneProvider, TimezonePicker, EpochDatePicker } from 'iqm-framework';
import get from 'lodash/get';
import { AppState } from '../../../../../../../store';
import { Campaign } from '../../../../../../../models/Campaign';
import { API } from '../../../../../../../api';
import { TableComponentInstance } from '../../../Table'
import { toastActions } from '../../../../../../../store/toast/actions';
import { OptionID } from '../../../../../../../models/Option';
import { LambdaResponse } from '../../../../../../../models/Response';
import { tableActions, SetCampaigns } from '../../../../../../../store/table/actions';
import { pluralize } from '../../../../../../../utils/pluralize';
import { ToastContent } from '../../../../../../../components/toastContent/ToastContent';

interface Props extends SetCampaigns {
  selectedCampaigns: Campaign[];
  action: string;
  label: string;
  value?: number | string | null;
  onClose: () => void;
  openToast: (message: string | JSX.Element) => void;
  dspId: number | null;
  advertiserId: number | null;
  timezone: OptionID | null;
  timezones: OptionID[];
  tableData: LambdaResponse[];
}

interface State {
  value: number | null;
}

class ChangeEndDateComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
    };
  }

  get timezone() {
    const { timezone, timezones } = this.props;
    let campaignTz = timezone;
    const selectedCampaignsTimezones = this.selectedCampaignsTimezones();

    if (selectedCampaignsTimezones.length === 1) {
      campaignTz = timezones.find(tz => tz.value === selectedCampaignsTimezones[0]) || timezone;
    }

    return campaignTz;
  }

  selectedCampaignsTimezones = () => {
    const { selectedCampaigns } = this.props;
    return selectedCampaigns.reduce((acc: string[], campaign) => {
      if (!acc.includes(campaign.campaignTimezone)) {
        acc.push(campaign.campaignTimezone);
      }
      return acc;
    }, []);
  }

  editHandler = () => {
    const { 
      selectedCampaigns, 
      onClose, 
      label, 
      advertiserId, 
      dspId, 
      tableData, 
      openToast,
      setCampaigns,
    } = this.props;
    const { value } = this.state;

    if (!advertiserId || !dspId) {
      return;
    }

    if (value && TableComponentInstance) {
      const time = moment(value).unix();
      const ids = selectedCampaigns.map(c => c.campaignId);

      API.campaigns.updateEndTime({
        advertiserId,
        dspId,
        campaignIds: ids.join(','),
        endDate: time,
      })
        .then(res => {
          const toastMessage = `${label.charAt(0).toUpperCase() + label.slice(1)} has been changed`;

          if (get(res, 'responseObject.modified_data', null)) {
            const modifiedData = get(res, 'responseObject.modified_data', [])
              .reduce((acc, campaign) => {
                acc[campaign.campaignId] = {};
                for (let key in campaign) {
                  acc[campaign.campaignId.toString()][key] = campaign[key].toString();
                }
                return acc;
              }, {});
            const modifiedDataIds: string[] = Object.keys(modifiedData);
            tableData.forEach(campaignData => {
              if (modifiedDataIds.includes(campaignData.campaignId)) {
                campaignData = Object.assign(campaignData, modifiedData[campaignData.campaignId]);
              }
              return campaignData;
            });
            setCampaigns(tableData);
            openToast(toastMessage);
            TableComponentInstance.setState({ data: tableData });
            onClose();
          }

          if (!get(res, 'responseObject.status', null)) {
            const errorsMapper = res.responseObject.reason.reduce((acc, i) => {
              const findedIndex = acc.findIndex(a => a.errorMessage === i.errorMessage);
              findedIndex > -1 ? acc[findedIndex].id.push(i.id) : acc.push({
                errorMessage: i.errorMessage,
                id: [i.id],
              })
              return acc;
            }, []);
    
            const toastMessages = errorsMapper.map(e => {
              const { errorMessage, id } = e;
              return `${pluralize('Campaing', id.length, {
                single: `Campaign with id ${id.join(', ')} is`,
                multiple: `Campaigns with id ${id.join(', ')} are`,
              })} not updated! ${errorMessage ? 'Reason: ' + errorMessage : ''}`;
            });
    
            openToast(<ToastContent messages={toastMessages} />);
          } else {
            openToast(toastMessage);
          }
        })
        .catch(e => {
          const toastMessage = get(e, 'response.data.responseObject.errorMsg');
          this.props.openToast(toastMessage || `Error while update ${label}`);
          onClose();
        });
    }
  };

  getDefaultDate = tz => {
    const startDate = moment.tz(moment().utc().add(15, 'm'), 'UTC').tz(tz);
    return moment.tz(startDate.format('YYYY-MM-DD HH:mm:ss'), moment.tz.guess());
  };

  onDateChanged = value => {
    this.setState({
      value,
    });
  };

  datePickerErrorHandler = message => {
    this.props.openToast(message);
  };

  render() {
    const { onClose, selectedCampaigns, label, timezone } = this.props;
    const { value } = this.state;

    const moreThenOneTimezoneSelected = this.selectedCampaignsTimezones().length > 1;

    return (
      <DialogContent>
        <TimezoneProvider timezone={this.timezone}>
          <h3 className="title-card mb-4">Change {label}</h3>
          {(selectedCampaigns.length === 1)
            ? <h5 className={`title-card-subtitle ${moreThenOneTimezoneSelected ? 'mb-2' : 'mb-4'}`}>Enter the
              <span className="_black"> {label} </span>
              for the
              <span className="_black"> {selectedCampaigns[0].campaignName} </span>
              campaign.</h5>
            : <h5 className={`title-card-subtitle ${moreThenOneTimezoneSelected ? 'mb-2' : 'mb-4'}`}>Enter the
              <span className="_black"> {label} </span>
              for the <span className="_black">{selectedCampaigns.length}</span> campaigns.</h5>
          }
          {moreThenOneTimezoneSelected && (
            <h5 className="title-card-subtitle mb-4">Selected campaigns have different timezones, the time displayed in
            <span className="_black"> {timezone && timezone.value}</span> timezone and would be converted to Campaign timezone when saved.</h5>
          )}
          <div className="mb-3">
            <EpochDatePicker
              dateFormat="MM/DD/YYYY hh:mm A"
              datePickerProps={{
                numberOfCalendars: 1,
              }}
              label="End Date"
              withTimePicker={true}
              singleDateMode={true}
              singleDate={value}
              onDateChanged={this.onDateChanged}
              defaultDate={this.getDefaultDate}
              calendarMinimumDate={moment().add(15, 'm').valueOf()}
              onError={this.datePickerErrorHandler}
            />
          </div>
          <div className="d-flex justify-content-end align-items-center mt-2">
            <div className="d-flex duplicate-dialog__timezone">
              <TimezonePicker onTimezoneChange={null} />
            </div>
            <Button
              className="btn-square _conflower-blue mr-2"
              onClick={onClose}> Cancel
            </Button>
            <Button
              className="btn-square _conflower-blue _filled"
              onClick={() => this.editHandler()}
              disabled={!value}> Change
            </Button>
          </div>
        </TimezoneProvider>
      </DialogContent>
    );
  }
}

const mapState = (state: AppState) => ({
  selectedCampaigns: state.table.selectedTableCampaigns,
  tableData: state.table.data,
  dspId: state.auth.dspId,
  advertiserId: state.auth.userId,
  timezone: state.filter.timezone,
  timezones: state.filter.timezones,
});

const mapActions = {
  openToast: toastActions.open,
  setCampaigns: tableActions.setCampaigns,
};

export const ChangeEndDate = connect(mapState, mapActions)(ChangeEndDateComponent);
