import { reducerFromMap } from '../../utils/actions';
import { appConstants } from './constants';
import { Action } from '../../models/Action';

export interface AppState {
  sidebarOpened: boolean;
}

export const defaultAppState: AppState = {
  sidebarOpened: true,
};

const toggle = (state: AppState, action: Action<boolean>): AppState => {
  return {
    ...state,
    sidebarOpened: action.payload,
  };
};

const reducer = reducerFromMap<AppState>(defaultAppState, {
  [appConstants.TOGGLE_SIDEBAR]: toggle,
});

export const app = (state: AppState = defaultAppState, action: Action<any>) => reducer(state, action);