import { getInstance } from './index';
import { GraphDTO, GraphParams } from '../models/Graph';
import { config } from '../constants/config';

export function getGraphData(params: GraphParams): Promise<GraphDTO> {
  return getInstance()
    .post('/campaigns/graph', params)
    .then(response => response.data)
    .catch(err => Promise.reject(err.response.data));
}
