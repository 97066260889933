import React from 'react';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';
import { Button } from 'factor';
import { CampaignsActions as Actions } from "../../../../../../../models/CampaignsActions";
import { AppState } from '../../../../../../../store';
import { Campaign } from '../../../../../../../models/Campaign';
import { ButtonsDialog } from './ButtonsDialog';

import './styles.scss';

interface RunButtonProps {
  selectedCampaigns: Campaign[];
}
interface RunButtonState {
  dialogIsOpen: boolean;
  action: string | null;
}

class RunButtonComponent extends React.Component<RunButtonProps, RunButtonState> {
  constructor(props) {
    super(props)

    this.state = {
      dialogIsOpen: false,
      action: null,
    };
  }

  closeDialog = () => {
    this.setState({
      dialogIsOpen: false,
    })
  };

  actionHandler = (action) => {
    this.setState({
      dialogIsOpen: true,
      action,
    })
  };

  render() {
    const { selectedCampaigns } = this.props;

    return selectedCampaigns.every(item => ['expired', 'paused'].includes(item.status)) ? (<>
      <Button
        className="btn-round _filled _conflower-blue campaign-actions-button__btn"
        onClick={() => this.actionHandler(Actions.run)}
      >
        Run
      </Button>
      {createPortal(
        <ButtonsDialog
          selectedCampaigns={selectedCampaigns}
          dialogIsOpen={this.state.dialogIsOpen}
          action={this.state.action}
          close={this.closeDialog}
        />,
        document.body
      )}
    </>) : null;
  }
}

const runButtonMapState = (state: AppState) => ({
  status: state.filter.status,
  selectedCampaigns: state.table.selectedTableCampaigns,
});

export const RunButton = connect(runButtonMapState)(RunButtonComponent);
