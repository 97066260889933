import React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../store';
import { Dashboard } from '../../../models/Dashboards';
import {
  dashboardsActions,
  GetDashboards,
  SelectDashboard,
  CreateDashboard,
  DeleteDashboard,
  ClearSelectedDashboard,
  UpdateDashboard,
} from '../../../store/dashboards/actions';
import { SelectDashboards } from './selectContainer/SelectDashboard';
import { toastActions, Open } from '../../../store/toast/actions';
import { FilterState } from '../../../store/filter/reducers';
import { Option } from '../../../models/Option';

interface Props extends GetDashboards, SelectDashboard, DeleteDashboard, Open, CreateDashboard, ClearSelectedDashboard, UpdateDashboard {
  isAuth: boolean;
  className?: string;
  selectedDashboard: Dashboard | null;
  dashboards: Dashboard[];
  filterState: FilterState;
  metric: Option;
  dimension: Option | null;
  filteredCampaignsIds: number[];
}

class SelectContainerComponent extends React.Component<Props> {
  constructor(props) {
    super(props);
  }

  handleCreate = name => {
    const { open, createDashboard } = this.props;
    if (name) {
      createDashboard(name)
        .then(() => {
          open(`${name} has been created!`);
        })
        .catch(() => {
          open(`${name} hasn't been created!`);
        });
    }
  }

  handleChange = dashboard => {
    this.props.selectDashboard(dashboard);
  };

  handleDelete = dashboard => {
    const { deleteDashboard, open } = this.props;
    deleteDashboard(dashboard)
      .then((res) => {
        open(`${res.label} has been deleted.`);
      })
      .catch(e => {
        open('Dashboard hasn\'t been deleted.');
      });
  };

  componentDidMount() {
    const { isAuth } = this.props;
    if (isAuth) {
      this.props.getDashboards();
    }
  };

  componentDidUpdate(prevProps: Props) {
    const { isAuth } = this.props;
    if (isAuth && (isAuth !== prevProps.isAuth)) {
      this.props.getDashboards();
    }
  };

  render() {
    const { className, dashboards, selectedDashboard, clearSelectedDashboard, filterState, metric, dimension, filteredCampaignsIds, updateDashboard, open } = this.props;

    return (
      <React.Fragment>
        <div className={className}>
          <SelectDashboards
            dashboards={dashboards}
            selectedDashboard={selectedDashboard}
            clearDashboard={clearSelectedDashboard}
            selected={this.handleChange}
            deleted={this.handleDelete}
            onCreated={this.handleCreate}
            filterState={filterState}
            metric={metric}
            dimension={dimension}
            filteredCampaignsIds={filteredCampaignsIds}
            updateDashboard={updateDashboard}
            open={open}
          />
        </div>
      </React.Fragment>
    )
  };
}

const mapState = (state: AppState) => {
  return {
    isAuth: state.auth.authorized,
    dashboards: state.dashboards.dashboards,
    selectedDashboard: state.dashboards.selectedDashboard,
    filterState: state.filter,
    metric: state.reports.metric,
    dimension: state.reports.dimension,
    filteredCampaignsIds: state.table.filteredCampaignsIds,
  };
};

const mapAction = {
  getDashboards: dashboardsActions.getDashboards,
  selectDashboard: dashboardsActions.selectDashboard,
  clearSelectedDashboard: dashboardsActions.clearSelectedDashboard,
  deleteDashboard: dashboardsActions.deleteDashboard,
  createDashboard: dashboardsActions.createDashboard,
  updateDashboard: dashboardsActions.updateDashboard,
  open: toastActions.open,
};

export const SelectContainer = connect(mapState, mapAction)(SelectContainerComponent);
