import React from 'react';
import { TextFieldWithIcon } from 'factor';
import { AppState } from '../../../../../store';
import { filterActions } from '../../../../../store/filter/actions';
import { connect } from 'react-redux';

interface Props {
  search: string;
  updateSearch: (value: string) => void;
}

class SearchWrapperComponent extends React.Component<Props> {
  constructor(props) {
    super(props);
  }

  handleChange = (event) => {
    this.props.updateSearch(event.target.value);
  };

  render() {
    const { search } = this.props;
    return (
      <TextFieldWithIcon
        iconName="Search"
        onChange={this.handleChange}
        value={search}
        placeholder="Search"
      />
    );
  }
}

const mapState = (state: AppState) => {
  return {
    search: state.filter.search,
  };
};

const mapActions = {
  updateSearch: filterActions.updateSearch,
};

export const SearchWrapper = connect(mapState, mapActions)(SearchWrapperComponent);
