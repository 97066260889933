import React from 'react';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';
import { AxiosResponse } from 'axios';
import { TextFieldWithIcon, Icon, Button, Dialog } from 'factor';
import get from 'lodash/get';
import { BtnMenu } from './components/BtnMenu';
import { ReactComponent as ArrowDownIcon } from '../../../../../../svg/arrow-down.svg';
import { AppState } from '../../../../../../store';
import { GroupsList } from '../../../../../../models/CampaignGroup';
import { TableLevel } from '../../../../../../store/filter/reducers';
import { tableActions } from '../../../../../../store/table/actions';
import { Campaign } from '../../../../../../models/Campaign';
import { toastActions } from '../../../../../../store/toast/actions';
import { filteredCampaignGroupsActions } from '../../../../../../store/filteredCampaignGroups/actions';
import { campaignGroupsActions } from '../../../../../../store/campaignGroups/actions';
import { CreateGroup } from './components/CreateGroup';
import { DeleteGroupDialogContent } from './groupsActionsButton/components/DeleteGroupDialogContent';

interface Props {
  groups: GroupsList[];
  loading: boolean;
  isGroupLevel: boolean;
  selectedCampaigns: Campaign[];
  selectedGroupsIds: number[];
  clearSelectedCampaigns: () => void;
  openToast: (message: string) => void;
  getFilteredGroups: () => void;
  deleteFilteredGroup: (id: number) => Promise<AxiosResponse>;
  updateFilteredGroups: (ids: number[]) => void;
  getCampaignGroupsList: () => void;
  createCampaignGroup: (name) => Promise<GroupsList>;
}

interface State {
  btnMenuIsOpen: boolean;
  btnSelectSearch: string;
  manageGroups: number[];
  filteredGroups: GroupsList[];
  creatingNewGroupStatus: boolean;
  deletingGroup: GroupsList | null;
  dialogIsOpen: boolean;
  isScrollable: boolean;
}

class GroupsActionsButtonComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      btnMenuIsOpen: false,
      btnSelectSearch: '',
      manageGroups: [],
      filteredGroups: props.groups,
      creatingNewGroupStatus: false,
      dialogIsOpen: false,
      deletingGroup: null,
      isScrollable: false,
    };
  }

  _selectListEl: null | HTMLElement = null;

  componentDidMount() {
    this.props.getFilteredGroups();
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedCampaigns, getFilteredGroups } = this.props;
    
    if (prevProps.selectedCampaigns.length !== selectedCampaigns.length) {
      getFilteredGroups();
    }
    
    if (this.state.btnMenuIsOpen && (this.state.btnMenuIsOpen !== prevState.btnMenuIsOpen)) {
      if (this._selectListEl && (this._selectListEl.clientHeight < this._selectListEl.scrollHeight)) {
        this.setState({ isScrollable: true });
      } else {
        this.setState({ isScrollable: false });
      }
    }
  }

  toggleBtnMenu = () => {
    const { btnMenuIsOpen } = this.state;
    this.setState({
      btnMenuIsOpen: !btnMenuIsOpen,
    });
  };

  openBtnMenu = () => {
    this.setState({
      btnMenuIsOpen: true,
    });

    const selectedIds = this.props.selectedGroupsIds;

    this.setState({
      manageGroups: selectedIds,
    });
  };

  closeBtnMenu = () => {
    this.setState({
      btnMenuIsOpen: false,
    });
  };

  selectAll = () => {
    this.setState({
      manageGroups: this.props.groups.map(g => g.value),
    });
  };

  deselectAll = () => {
    this.setState({
      manageGroups: [],
    });
  };

  updateGroups = async () => {
    const { updateFilteredGroups, openToast } = this.props;
    const { manageGroups } = this.state;
    try {
      await updateFilteredGroups(manageGroups);
      openToast('Groups have been changed!');
      this.props.getCampaignGroupsList();
      this.closeBtnMenu();
    } catch (e) {
      openToast(e);
      this.closeBtnMenu();
    }
  };

  handleChangeSearch = ({ target }) => {
    const { value } = target;

    this.setState({
      btnSelectSearch: value,
    });
  };

  creatingGroupHandler = value => {
    this.props.createCampaignGroup(value)
      .then(group => {
        const { manageGroups } = this.state;
        this.setState({
          manageGroups: [
            ...manageGroups,
            group.value
          ],
        })
      })
      .catch(e => console.log(e))
  };

  createStatusChangeHandler = value => {
    this.setState({ creatingNewGroupStatus: value });
  };

  openDialog = (event, group: GroupsList) => {
    event.stopPropagation();
    this.setState({
      btnMenuIsOpen: false,
      dialogIsOpen: true,
      deletingGroup: group,
    });
  };

  closeDialog = () => {
    this.setState({
      dialogIsOpen: false,
    })
  };

  handleDelete = () => {
    const { deleteFilteredGroup, getCampaignGroupsList, openToast: open } = this.props;
    const { deletingGroup } = this.state;
    deletingGroup && deleteFilteredGroup(deletingGroup.value)
      .then(res => {
        open(get(res, 'data.responseObject.message', ''));
        getCampaignGroupsList();
      })
      .catch(e => {
        open('Groups hasn\'t been deleted.');
      });
    this.closeDialog();
  };

  render() {
    const {
      loading,
      groups,
    } = this.props;

    const {
      btnMenuIsOpen,
      btnSelectSearch,
      manageGroups,
      creatingNewGroupStatus,
      dialogIsOpen,
      deletingGroup
    } = this.state;

    const filteredGroups = btnSelectSearch
      ? groups.filter(group =>
        group.value > 0 && group.label.toLowerCase().includes(btnSelectSearch.toLowerCase()))
      : groups.filter(groups => groups.value > 0);

    const buttons = !creatingNewGroupStatus &&
      !loading &&
      <div className="d-flex align-items-center justify-content-end mt-2">
        <Button
          className="btn-square _conflower-blue mr-2"
          onClick={this.toggleBtnMenu}>
          Cancel
        </Button>
        <Button
          className="btn-square _filled _conflower-blue"
          disabled={!filteredGroups.length}
          onClick={this.updateGroups}>
          Apply
        </Button>
      </div>

    const dialog = deletingGroup && (<Dialog
      open={dialogIsOpen}
      onClickOutside={this.closeDialog}>
      <DeleteGroupDialogContent
        group={deletingGroup}
        onClose={this.closeDialog}
        onDelete={this.handleDelete}
      />
    </Dialog>)

    return (
      <React.Fragment>
        <BtnMenu
          isOpen={btnMenuIsOpen}
          open={this.openBtnMenu}
          close={this.closeBtnMenu}
          className="filter__btn _groups-actions"
          tooltipParams={{
            label: 'Manage Campaign Groups for the selected Campaigns',
          }}
          content={(
            <div className="btn-select">
              <div className="btn-select__header">
                <div className="select__value ">Add to Group</div>
                <div className="select__btn-secondary"
                  onClick={(manageGroups.length < filteredGroups.length)
                    ? this.selectAll
                    : this.deselectAll}>
                  {(manageGroups.length < filteredGroups.length)
                    ? 'Select All'
                    : 'Deselect All'}
                </div>
                <div className="select__indicator"
                  onClick={this.closeBtnMenu}></div>
              </div>
              {!creatingNewGroupStatus &&
                <div className="btn-select__search">
                  <TextFieldWithIcon
                    iconName="Search"
                    onChange={this.handleChangeSearch}
                    value={btnSelectSearch}
                    placeholder="Search"
                  />
                </div>
              }
              <CreateGroup
                valueFromSearch={btnSelectSearch.trim()}
                onCreateGroup={this.creatingGroupHandler}
                creatingStatusChanged={this.createStatusChangeHandler} />
              {!loading &&
                <div className={`btn-select__list-wrapper ${this.state.isScrollable ? '_scrollable' : ''}`}>
                  <div className="btn-select__list" ref={el => {
                    this._selectListEl = el;
                  }}>
                    {filteredGroups.map((item) => {
                      const checked = manageGroups.indexOf(item.value) !== -1;
                      return (
                        <div
                          className={`btn-select__item ${checked ? '_active' : ''}`}
                          key={item.value}
                          onClick={() => {
                            let index = manageGroups.indexOf(item.value);
                            if (index === -1) {
                              manageGroups.push(item.value);
                            } else {
                              manageGroups.splice(index, 1);
                            }
                            this.setState({ manageGroups });
                          }}
                        >
                          {checked &&
                            <Icon name="Done" />
                          }
                          {item.label}
                          <Icon name="Delete" onClick={e => this.openDialog(e, item)} />
                        </div>
                      )
                    })}
                  </div>
                </div>
              }
              {buttons}
            </div>
          )}
        >
          <span className="btn-round__prefix">
            <ArrowDownIcon />
          </span>
          Manage Groups
        </BtnMenu>
        {createPortal(dialog, document.body)}
      </React.Fragment>
    );
  }
}

const mapState = (state: AppState) => ({
  selectedCampaigns: state.table.selectedTableCampaigns,
  groups: state.campaignGroups.data,
  isGroupLevel: state.filter.tableLevel.value === TableLevel.Groups,
  selectedGroupsIds: state.filteredCampaignGroups.selectedGroups,
  loading: state.filteredCampaignGroups.loading,
});

const mapActions = {
  clearSelectedCampaigns: tableActions.clearSelectedCampaigns,
  openToast: toastActions.open,
  getFilteredGroups: filteredCampaignGroupsActions.getFilteredGroups,
  deleteFilteredGroup: filteredCampaignGroupsActions.deleteFilteredGroup,
  updateFilteredGroups: filteredCampaignGroupsActions.updateFilteredGroups,
  getCampaignGroupsList: campaignGroupsActions.getCampaignGroupsList,
  createCampaignGroup: campaignGroupsActions.createCampaignGroup,
};

export const GroupsActionsButton = connect(mapState, mapActions)(GroupsActionsButtonComponent);
