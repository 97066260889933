import { Dispatch } from 'redux';
import moment from 'moment';
import uniqueId from 'lodash/uniqueId';
import get from 'lodash/get';
import { lambdaAPI } from '../../api/lambdaAPI';
import { createAction } from '../../utils/actions';
import { statisticsConstants } from './constants';
import { StatisticsTotalResult } from '../../models/Statistics';
import { AppState } from '..';
import { sortingColumnOptions } from '../../models/Option';
import { LambdaResponse } from '../../models/Response';
import { toastConstants } from '../toast/constants';

const SESSION_ID = uniqueId();
interface Params {
  frmtime: number;
  totime: number;
}
export const statisticsActions = {
  setNewTotal(total) {
    return function (dispatch: Dispatch) {
      if (total) {
        const totalStats: StatisticsTotalResult = {
          impressions: +total.impressions,
          eCPM: +total.eCPM,
          eCPC: +total.eCPC,
          clicks: +total.clicks,
          spent: +total.spent,
          eCTR: +total.CTR,
          winRate: +total.winRate,
          mediaSpent: +total.mediaSpent,
        };
        dispatch(createAction<StatisticsTotalResult>(statisticsConstants.GET_TOTAL__SUCCESS, totalStats));
      }
    }
  },
  getPrevTotal() {
    return async (dispatch: Dispatch, getState: () => AppState) => {
      dispatch(createAction<StatisticsTotalResult>(statisticsConstants.GET_TOTAL__START));
      dispatch(createAction(statisticsConstants.CLEAR_TOTAL));
      try {
        const state = getState();
        const { auth } = state;
        const { dateRange, timezone, selectedCampaignTypes, status, search } = state.filter;
        const { filteredCampaignsIds } = state.table;

        if (dateRange) {
          let { start, end } = dateRange;
          start = moment(start);
          end = moment(end);
          const diff = end.unix() - start.unix();

          const query = {
            columns: sortingColumnOptions,
            total_agg: true,
            dateRange: {
              startDate: `${start.clone().subtract(diff, 'seconds').valueOf()}`,
              endDate: `${start.valueOf()}`,
            },
            timezone: {
              label: timezone ? timezone.label : null,
              value: timezone ? timezone.value : null,
              id: timezone ? timezone.id : null,
            },
            dimension: {
              filter: {
                value: 'campaign',
                label: 'Campaign',
              },
              value: filteredCampaignsIds.map(id => ({ value: id })),
            },
            filters: {
              campaign_status: status.value,
              creative_type_ids: selectedCampaignTypes.map(c => c.value).join(',') || null,
            },
            user_session_id: `${auth.apiToken}_${SESSION_ID}`,
            search_field: search,
            result_type: 'total',
            total_count: filteredCampaignsIds.length || 50,
          };
          const response = await lambdaAPI.common.get(query);
          if (response.total) {
            const totalStats: StatisticsTotalResult = {
              impressions: +response.total.impressions,
              eCPM: +response.total.eCPM,
              eCPC: +response.total.eCPC,
              clicks: +response.total.clicks,
              spent: +response.total.spent,
              eCTR: +response.total.CTR,
              winRate: +response.total.winRate,
              mediaSpent: +response.total.mediaSpent,
            };
            dispatch(createAction<StatisticsTotalResult>(statisticsConstants.GET_TOTAL_PREV__SUCCESS, totalStats));
          }
        }
      } catch (e) {
        const errorStatus = get(e, 'response.status', null);
        dispatch(createAction(statisticsConstants.GET_TOTAL__ERROR, e));
        if (errorStatus === 500) {
          dispatch(createAction(toastConstants.TOAST_OPEN, 'Something went wrong. Please try again over a few minutes.'));
        }
        return Promise.reject(e);
      }
    }
  },
  getTotal() {
    return async (dispatch: Dispatch, getState: () => AppState) => {
      try {
        const state = getState();
        const { auth } = state;
        const { dateRange, timezone, selectedCampaignTypes, status, search } = state.filter;
        const { filteredCampaignsIds } = state.table;

        if (dateRange) {
          let { start, end } = dateRange;
          
          const query = {
            columns: sortingColumnOptions,
            total_agg: false,
            dateRange: {
              startDate: start.unix().toString(),
              endDate: end.unix().toString(),
            },
            timezone: {
              label: timezone ? timezone.label : null,
              value: timezone ? timezone.value : null,
              id: timezone ? timezone.id : null,
            },
            dimension: {
              filter: {
                value: 'campaign',
                label: 'Campaign',
              },
              value: filteredCampaignsIds.map(id => ({ value: id })),
            },
            filters: {
              campaign_status: status.value,
              creative_type_ids: selectedCampaignTypes.map(c => c.value).join(',') || null,
            },
            user_session_id: `${auth.apiToken}_${SESSION_ID}`,
            search_field: search,
            result_type: 'total',
            total_count: filteredCampaignsIds.length || 50,
          };
          const response = await lambdaAPI.common.get(query);
          return response.total;
        }
      } catch (e) {
        const errorStatus = get(e, 'response.status', null);
        if (errorStatus === 500) {
          dispatch(createAction(toastConstants.TOAST_OPEN, 'Something went wrong. Please try again over a few minutes.'));
        }
      }
    }
  },
};

export interface SetNewTotal {
  setNewTotal: (total: LambdaResponse) => void;
};

export interface GetTotal {
  getTotal: () => Promise<LambdaResponse>;
};
