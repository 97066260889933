export const filterConstants = {
  SET_DATE_RANGE: 'SET_DATE_RANGE',
  UPDATE_SELECTS: 'UPDATE_SELECTS',
  SELECT_TIMEZONE: 'SELECT_TIMEZONE',
  GET_TIMEZONES: 'GET_TIMEZONES',
  CHANGE_TABLE_LEVEL: 'CHANGE_TABLE_LEVEL',
  UPDATE_SEARCH: 'UPDATE_SEARCH',
  SET_STATUS: 'SET_STATUS',
  UPDATE_STATUS: 'UPDATE_STATUS',
  UPDATE_GRAPH_SELECT: 'UPDATE_GRAPH_SELECT',
  CHANGE_SORTING_COLUMNS: 'CHANGE_SORTING_COLUMNS',
  CHANGE_SORTING_GROUPS_COLUMNS: 'CHANGE_SORTING_GROUPS_COLUMNS',
  UPDATE_FREEZE_TABLE_PART: 'UPDATE_FREEZE_TABLE_PART',
  UPDATE_FILTERS: 'UPDATE_FILTERS',
  SET_CAMPAIGN_TYPES: 'SET_CAMPAIGN_TYPES',
  SELECT_CAMPAIGN_TYPE: 'SELECT_CAMPAIGN_TYPE',
  DESELECT_CAMPAIGN_TYPE: 'DESELECT_CAMPAIGN_TYPE',
  SET_INITIAL_DATE_RANGE: 'SET_INITIAL_DATE_RANGE',
  CHANGE_SELECTING_CAMPAIGNS_MODE: 'CHANGE_SELECTING_CAMPAIGNS_MODE',
};
