import { Dispatch } from 'redux';
import get from 'lodash/get';
import { TableNameUpdateParams } from '../../models/Table';
import { createAction } from '../../utils/actions';
import { pluralize } from '../../utils/pluralize';
import { tableConstants } from './constants';
import { API } from '../../api';
import { Campaign } from '../../models/Campaign';
import { TableSortingParams } from '../../models/Table';
import { UpdateBudgetResponse, LambdaResponse } from '../../models/Response';
import { AppState } from '../index';
import { toastConstants } from '../toast/constants';

export const tableActions = {
  updateCampaignName: function (name: string, id: string | number) {
    return async (dispatch: Dispatch<any>) => {
      try {
        const response = await API.campaigns.updateCampaign(id, {
          campaign_name: name,
        });
        if (get(response, 'modified_data', []).length) {
          const modifiedCampaign: TableNameUpdateParams = {
            id: response.modified_data[0].campaign_id,
            name: response.modified_data[0].name,
          }
          const message = response.message;
          dispatch(createAction<TableNameUpdateParams>(tableConstants.TABLE_UPDATE_CAMPAIGN_NAME, modifiedCampaign));
          dispatch(createAction<String>(toastConstants.TOAST_OPEN, message));
        }
      } catch (e) {
        console.log('Error while update campaign name', e);
      }
    }
  },
  addSelectedCampaign: function (data) {
    return (dispatch: Dispatch<any>) => {
      dispatch(createAction<Campaign>(tableConstants.TABLE_ADD_SELECTED_CAMPAIGN, data));
    };
  },
  removeSelectedCampaign: function (data) {
    return (dispatch: Dispatch<any>) => {
      dispatch(createAction<Campaign>(tableConstants.TABLE_REMOVE_SELECTED_CAMPAIGN, data));
    };
  },
  clearSelectedCampaigns: function () {
    return (dispatch: Dispatch<any>) => {
      dispatch(createAction<void>(tableConstants.TABLE_CLEAR_SELECTED_CAMPAIGN));
    };
  },
  updateSorting(data) {
    return (dispatch: Dispatch<any>) => {
      dispatch(createAction<TableSortingParams>(tableConstants.TABLE_UPDATE_SORTING_PARAMS, data));
    };
  },
  addSelectedAllCampaign: function () {
    return (dispatch: Dispatch<any>) => {
      dispatch(createAction<void>(tableConstants.TABLE_SELECT_ALL_CAMPAIGNS));
    }
  },
  clearCampaignsData: function () {
    return (dispatch: Dispatch<any>) => {
      dispatch(createAction<void>(tableConstants.TABLE_CLEAR_CAMPAIGNS_DATA));
    };
  },
  duplicate: function (start: number, end: number) {
    return async (dispatch: Dispatch, getState: () => AppState) => {
      try {
        const state = getState();
        const { selectedTableCampaigns } = state.table;
        const { auth } = state;
        const query = {
          existingCampaignIds: selectedTableCampaigns.map(i => i.campaignId).join(','),
          dspId: auth.dspId,
          advertiserId: auth.userId,
          startDate: start,
          endDate: end,
        }
        const response = await API.campaigns.duplicateCampaigns(query);
        const errors = get(response, 'responseObject.reason', []);
        if (errors.length) {
          const ids = errors.map(e => e.id);
          const errorMessage = `Campaigns with id ${ids.join(', ')} ${ids.length > 1 ? 'are' : 'is'} not duplicated. ${errors[0].errorMessage}`;
          return Promise.reject(errorMessage);
        }
        dispatch(createAction<void>(tableConstants.TABLE_CLEAR_SELECTED_CAMPAIGN));
        return Promise.resolve();
      } catch (e) {
        console.log('[can\'t duplicate campaigns from server]: ', e);
        const msg = get(e, 'response.data.responseObject.errorMsg');
        return Promise.reject(msg || 'Campaign duplication failed');
      }
    }
  },
  pauseCampaigns: function () {
    return async (dispatch: Dispatch, getState: () => AppState) => {
      try {
        const state = getState();
        const { selectedTableCampaigns } = state.table;
        const { auth } = state;
        const query = {
          campaignIds: selectedTableCampaigns.map(i => i.campaignId).join(','),
          dspId: auth.dspId,
          advertiserId: auth.userId,
        };
        const response = await API.campaigns.pauseCampaign(query);
        const errors = get(response, 'data.responseObject.reason', []);
        if (errors.length) {
          const ids = errors.map(e => e.id);
          const errorMessage = `Campaigns with id ${ids.join(', ')} ${ids.length > 1 ? 'are' : 'is'} not paused. ${errors[0].errorMessage}`;
          return Promise.reject(errorMessage);
        }
        dispatch(createAction<void>(tableConstants.TABLE_CLEAR_SELECTED_CAMPAIGN));
        return Promise.resolve();
      } catch (e) {
        console.log('[can\'t update campaign status from server]: ', e);
        return Promise.reject(e);
      }
    }
  },
  deleteCampaigns: () => {
    return async (dispatch: Dispatch, getState: () => AppState) => {
      try {
        const state = getState();
        const { selectedTableCampaigns } = state.table;
        const { auth } = state;
        const params = {
          advertiserId: auth.userId,
          dspId: auth.dspId,
          status: 'delete',
          campaignIds: selectedTableCampaigns.map(i => i.campaignId).join(','),
        }
        const response = await API.campaigns.deleteCampaign(params);
        const errors = get(response, 'data.responseObject.reason', null);
        if (errors) {
          const errorsMapper = errors.reduce((acc, i) => {
            const findedIndex = acc.findIndex(a => a.errorMessage === i.errorMessage);
            findedIndex > -1 ? acc[findedIndex].id.push(i.id) : acc.push({
              errorMessage: i.errorMessage,
              id: [i.id],
            })
            return acc;
          }, []);
          
          const toastMessages = errorsMapper.map(e => {
            const { errorMessage, id } = e;
            return `${pluralize('Campaing', id.length, {
              single: `Campaign with id ${id.join(', ')} is`,
              multiple: `Campaigns with id ${id.join(', ')} are`,
            })} not updated! ${errorMessage ? 'Reason: ' + errorMessage : ''}`;
          });

          return Promise.reject(toastMessages);
        }
        dispatch(createAction<void>(tableConstants.TABLE_CLEAR_SELECTED_CAMPAIGN));
      } catch (e) {
        console.log('[can\'t fetching statuses from server]: ', e);
        return Promise.reject(e);
      }
    }
  },
  updateCampaignsBudget: (updateBudgetParams: UpdateBudgetParams) => {
    return async (dispatch: Dispatch, getState: () => AppState) => {
      try {
        const state = getState();
        const { selectedTableCampaigns } = state.table;
        const { auth } = state;
        const { budgetType, value, id, distributionMethod } = updateBudgetParams;
        const params = {
          advertiserId: auth.userId,
          dspId: auth.dspId,
          totalBudgetUpdateType: distributionMethod || 'change',
          campaignIds: id || selectedTableCampaigns.map(i => i.campaignId).join(','),
          [budgetType]: value,
        }
        const response = await API.campaigns.updateCampaignBudget(params);
        return Promise.resolve(response);
      } catch (e) {
        console.log('[can\'t fetching statuses from server]: ', e);
        return Promise.reject(e);
      }
    }
  },
  setFilteredCampaignsIds: function(ids: number[]) {
    return createAction(tableConstants.TABLE_SET_FILTERED_CAMPAIGNS_IDS, ids);
  },
  setFilteredGroupsIds: function(ids: number[]) {
    return createAction(tableConstants.TABLE_SET_FILTERED_GROUPS_IDS, ids);
  },
  setCampaigns: function (campaigns: LambdaResponse[]) {
    return createAction<LambdaResponse[]>(tableConstants.TABLE_SET_CAMPAIGNS, campaigns);
  },
  updateCampaignList: function(campaigns: LambdaResponse[]) {
    return createAction<LambdaResponse[]>(tableConstants.UPDATE_CAMPAIGNS_LIST, campaigns);
  }
};

export interface SetCampaigns {
  setCampaigns: (campaigns: LambdaResponse[]) => void;
}

export interface SetFilteredCampaigns {
  setFilteredCampaigns: (campaigns: LambdaResponse[]) => void;
}

export interface SetFilteredCampaignsIds {
  setFilteredCampaignsIds: (ids: number[]) => void;
}

export interface SetFilteredGroupsIds {
  setFilteredGroupsIds: (ids: number[]) => void;
}

export interface DeleteCampaigns {
  deleteCampaigns: () => void;
}

interface UpdateBudgetParams {
  budgetType: string;
  value: number;
  id?: number;
  distributionMethod?: 'change' | 'addition' | 'distribution';
}

export interface UpdateCampaignsBudget {
  updateCampaignsBudget: (params: UpdateBudgetParams) => Promise<UpdateBudgetResponse<any>>;
}

export interface UpdateCampaignName {
  updateCampaignName: (name: string, id: string | number) => Promise<any>;
}

export interface UpdateCampaignsList {
  updateCampaignsList: (campaigns: LambdaResponse[]) => void;
}
