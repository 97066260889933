import React from 'react';
import { Select } from 'factor';
import { metricOptions, ReportingAvialableMetrics } from '../../../../models/Graph';
import { connect } from 'react-redux';
import { AppState } from '../../../../store';
import { Option } from '../../../../models/Option';
import { GraphsNames } from '../../../../store/graphs/reducers';
import { filterActions } from '../../../../store/filter/actions';

interface OuterProps {
  stateKey: GraphsNames;
  excludingOption?: Option;
  className?: string;
  legendColor?: string;
  tooltipParams?: {
    position?: string;
  };
}
interface Props extends OuterProps {
  value: Option;
  changeGraphSelectValue: (value: Option, key: GraphsNames) => void;
}
class GraphSelectWrapperComponent extends React.Component<Props> {
  handleChange = (value) => {
    this.props.changeGraphSelectValue({
      ...value,
      value: ReportingAvialableMetrics[value.value] || value.value,
    }, this.props.stateKey);
  };
  render() {
    const {
      value,
      excludingOption,
      className = '',
      legendColor = '',
      tooltipParams = {},
    } = this.props;

    const options = excludingOption ? 
      metricOptions.map(metric => ({
        ...metric,
        options: metric.options ? metric.options.filter(option => option.value !== excludingOption.value) : [],
      })) :
      metricOptions;

    return (
      <div className={`${className}`}>
        <Select
          onChange={this.handleChange}
          value={value}
          options={options}
          placeholder="Metric"
          label="Metric"
          tooltipParams={{
            label: 'Metric or Key Performance Indicator (KPI) is the measured value that you can use to understand the effectiveness of your campaigns',
            position: tooltipParams.position || 'bottom',
            auto: false,
            labelMaxWidth: 304,
          }}
        />
        <div className="select-legend" style={{ backgroundColor: legendColor }}></div>
      </div>
    );
  }
}

const mapState = (state: AppState, props: OuterProps) => {
  return {
    value: state.filter[props.stateKey],
  }
};

const mapActions = {
  changeGraphSelectValue: filterActions.changeGraphSelectValue,
};

export const GraphSelectWrapper = connect(mapState, mapActions)(GraphSelectWrapperComponent);
