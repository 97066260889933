import { FilterState, DateRange, Freeze } from "../store/filter/reducers";
import { Option, StickedOption, OptionID } from "./Option";
import { CampaignStatusType } from "./Campaign";
import { GraphsNames } from "../store/graphs/reducers";

export interface Dashboard {
  value: number;
  label: string;
  id: string;
  data: DashboardData;
}

export interface DashboardData {
  dateRange: DateRange | null;
  timezone: OptionID | null;
  tableLevel: Option;
  search: string;
  status: Option<CampaignStatusType>;
  [GraphsNames.graph1]: Option;
  [GraphsNames.graph2]: Option;
  sortingColumns: StickedOption[];
  sortingGroupsColumns: StickedOption[];
  freeze: Freeze;
  selectedCampaignTypes: Option[];
  dimension: Option | null;
  metric: Option;
  filteredCampaignsIds: number[];
  isSelectingCampaignsByGroup: boolean;
}

interface DashboardDTO extends FilterState {
  dimension: Option | null;
  metric: Option;
  filteredCampaignsIds: number[];
}

export function convertFilterToDashboardData (filter: DashboardDTO): DashboardData {
  const {
    dateRange,
    timezone,
    tableLevel,
    search,
    status,
    sortingColumns,
    sortingGroupsColumns,
    freeze,
    selectedCampaignTypes,
    dimension,
    metric,
    filteredCampaignsIds,
    isSelectingCampaignsByGroup,
  } = filter;

  return {
    dateRange,
    selectedCampaignTypes,
    timezone,
    tableLevel,
    search,
    status,
    sortingColumns,
    sortingGroupsColumns,
    freeze,
    [GraphsNames.graph1]: filter[GraphsNames.graph1],
    [GraphsNames.graph2]: filter[GraphsNames.graph2],
    dimension,
    metric,
    filteredCampaignsIds,
    isSelectingCampaignsByGroup,
  };
}
