export type Response<T> = {
  data: Array<T>;
  draw?: number;
  recordsFiltered?: number;
  recordsTotal?: number;
  total?: any;
  pgno?: number;
}

export type UpdateBudgetResponse<T> = {
  responseObject: T;
  statusCode: number;
}

export type LambdaResponse = {
  CTR: string | null;
  VCR: string | null;
  bidImpressions: string | null;
  campaignId: string;
  campaignName: string | null;
  campaignType: string | null;
  creativeType: string | null;
  clicks: string | null;
  completeCount: string | null;
  dataCost: string | null;
  date: string | null;
  eCPC: string | null;
  eCPCV: string | null;
  eCPM: string | null;
  endTime: string | null;
  firstCount: string | null;
  impressions: string | null;
  maxBid: string | null;
  midCount: string | null;
  spent: string | null;
  startCount: string | null;
  startTime: string | null;
  status: string | null;
  thirdCount: string | null;
  totalCount: string | null;
  winRate: string | null;
}

export const emptyLambdaResponse: LambdaResponse = {
  CTR: null,
  VCR: null,
  bidImpressions: null,
  campaignId: '',
  campaignName: null,
  campaignType: null,
  creativeType: null,
  clicks: null,
  completeCount: null,
  dataCost: null,
  date: null,
  eCPC: null,
  eCPCV: null,
  eCPM: null,
  endTime: null,
  firstCount: null,
  impressions: null,
  maxBid: null,
  midCount: null,
  spent: null,
  startCount: null,
  startTime: null,
  status: null,
  thirdCount: null,
  totalCount: null,
  winRate: null,
}

export type CancellarError<T> = {
  response: T;
}
