import * as React from 'react';
import { Stat, StatFormat } from '../models/Statistics';
import { CurrencyFormat, formatNumberWithSuffix, NumberFormat, } from '../utils/format';
import { ReactComponent as PercentArrowIcon } from './statValue/percent-arrow.svg';

export const StatValue = ({ title, format, value = 0, prevValue }: Stat) => {
  let fixedValue, diff, positive, equal;
  if (format === StatFormat.currency) {
    fixedValue = CurrencyFormat.format(+value);
  } else if (format === StatFormat.percent) {
    fixedValue = `${((+value) || 0).toFixed(2)}%`;
  } else {
    fixedValue = NumberFormat.format(+value);
  }

  if (prevValue !== undefined) {
    diff = +prevValue === 0 ? 100 : ((+value - +prevValue) / +prevValue) * 100;
    positive = value > prevValue;
    equal = value === prevValue;
  }

  return (
    <React.Fragment>
      <div className="dashboard-indicators__name">{title}</div>
      <div className="dashboard-indicators__numbers">
        <div className="dashboard-indicators__value">{fixedValue}</div>
        {!equal && prevValue !== undefined &&
        <div className={`dashboard-indicators__percent ${positive ? '_up' : '_down'}`}>
          <PercentArrowIcon/>
          <span>{`${formatNumberWithSuffix(diff || 0, true)}%`}</span>
        </div>
        }
      </div>
    </React.Fragment>
  )
};
