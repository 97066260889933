import uniqueId from 'lodash/uniqueId';
import moment from 'moment';
import { Dispatch } from 'redux';
import get from 'lodash/get';
import { lambdaAPI } from '../../api/lambdaAPI';
import { AppState } from '../index';
import { reportsConstants } from './constants';
import { createAction } from '../../utils/actions';
import { ReportingAvialableMetrics, DimensionsHelper } from '../../models/Graph';
import { Option, sortingColumnOptions } from '../../models/Option';
import { LambdaResponse } from '../../models/Response';
import { toastConstants } from '../toast/constants';

const SESSION_ID = uniqueId();

export const reportsActions = {
  getLambdaReports: function () {
    return async (dispatch: Dispatch<any>, getState: () => AppState) => {
      try {
        const state = getState();
        const { dimension } = state.reports;
        const { auth, reports, table } = state;
        const { dateRange, timezone, selectedCampaignTypes, status, search } = state.filter;
        const query = {
          columns: sortingColumnOptions,
          total_agg: true,
          dateRange: dateRange ? {
            startDate: `${moment(dateRange.start).valueOf()}`,
            endDate: `${moment(dateRange.end).valueOf()}`,
          } : null,
          timezone: {
            label: timezone ? timezone.label : null,
            value: timezone ? timezone.value : null,
            id: timezone ? timezone.id : null,
          },
          dimension: {
            filter: {
              value: 'campaign',
              label: 'Campaign',
            },
            value: table.filteredCampaignsIds.map(id => ({
              value: id,
            })),
          },
          secondDimension: {
            filter: {
              value: dimension && (DimensionsHelper[dimension.value] || dimension.value),
              label: dimension && dimension.label,
            },
            value: [],
          },
          filters: {
            campaign_status: status.value,
            creative_type_ids: selectedCampaignTypes.map(c => c.value).join(','),
          },
          user_session_id: `${auth.apiToken}_${SESSION_ID}`,
          search_field: search,
          result_type: 'data',
          total_count: 5,
          sort_by: ReportingAvialableMetrics[reports.metric.value],
          sort_type: 'desc',
        };

        if (dimension && (dimension.value === 'campaign')) {
          delete (query.secondDimension);
          query.total_agg = false;
        }

        if (dimension) {
          dispatch(createAction<void>(reportsConstants.LOADING_REPORTS__START));
          const response = await lambdaAPI.reportsGraph.get(query);
          dispatch(createAction<LambdaResponse[]>(reportsConstants.LOADING_REPORTS__SUCCESS, response.data));
        }

      } catch (e) {
        const errorStatus = get(e, 'response.status', null);
        dispatch(createAction(reportsConstants.LOADING_REPOSTS__ERROR, e));
        if (errorStatus === 500) {
          dispatch(createAction(toastConstants.TOAST_OPEN, 'Something went wrong. Please try again over a few minutes.'));
        }
        return Promise.reject(false);
      }
    };
  },

  changeReportDimension: function (dimension: Option) {
    return (dispatch: Dispatch<any>) => {
      dispatch(createAction<Option>(reportsConstants.CHANGE_DIMENSION, dimension));
    };
  },

  changeReportMetric: function (metric: Option) {
    return (dispatch: Dispatch<any>) => {
      if (ReportingAvialableMetrics[metric.value]) {
        dispatch(createAction<Option>(reportsConstants.CHANGE_METRIC, metric));
      }
    };
  },
};

export interface GetLambdaReports {
  getLambdaReports: () => void;
}
