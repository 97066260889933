import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { AppState } from '../../../../store';
import { GraphsNames } from '../../../../store/graphs/reducers';
import { PairedGraphModel } from '../../../../models/Graph';
import { BarWithLineChart } from './components/BarWithLineChart';
import { Option } from '../../../../models/Option';
import { SkeletonChart } from './components/SkeletonChart';

interface Props {
  data: Array<PairedGraphModel>;
  [GraphsNames.graph1]: Option;
  [GraphsNames.graph2]: Option;
  loading: boolean;
}

class GraphsComponent extends React.Component<Props> {
  render() {
    const { data = [], graph1, graph2, loading } = this.props;

    if (loading) {
      return <SkeletonChart/>;
    }

    return (
      <BarWithLineChart data={data} graph1={graph1} graph2={graph2}/>
    );
  }
}

// TODO: add memoize
const mapState = (state: AppState) => {
  const d1Key = state.filter[GraphsNames.graph1].value;
  const d2Key = state.filter[GraphsNames.graph2].value;
  const graphData = state.graph.data || [];

  const dataArr = graphData.map((data, i) => {
    const start = moment(+data.start_date || data.start_date).valueOf();
    const end = moment(+data.end_date || data.end_date).valueOf();
    return {
      start,
      end,
      graph1: +data[d1Key] || 0,
      graph2: +data[d2Key] || 0,
      xAxiesLabel: (i === (graphData.length - 1)) ? end : start,
    };
  });

  return {
    data: dataArr,
    [GraphsNames.graph1]: state.filter[GraphsNames.graph1],
    [GraphsNames.graph2]: state.filter[GraphsNames.graph2],
    loading: state.graph.loading,
  };
};
export const Graphs = connect(mapState)(GraphsComponent);
